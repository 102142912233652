import { DataGridWrapperProps, default as DataGrid } from "../datatable/DataGridWrapper";
import { useEffect, useState } from "react";
import { GridActionsColDef, GridColumns, GridRowParams, GridFooter, GridFooterContainer } from "@mui/x-data-grid";
import DisplayOrderButton, { moveBottom, moveDown, moveTop, moveUp, sortAlphabetical } from "../DisplayOrderButton";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Spacer from "../Spacer";
import { Action } from "history";
import { getDefaultGridFilterModel } from "@mui/x-data-grid";
import React, { Ref, useImperativeHandle } from "react";
import { useDialog } from "./DetailsDialog";

export interface DetailsDisplayOrderGridProps extends DataGridWrapperProps {
    columns: GridColumns<any>;
    rows: any[];
    onSaveDisplayOrder: (ids: any[], alphabetical?: boolean) => void;
    sortAlphabeticalField?: string;    
}

export interface DetailsDisplayOrderGridRefObject {
    isDirty: () => boolean;
}

const DetailsDisplayOrderGrid = React.forwardRef((props: DetailsDisplayOrderGridProps, ref?: Ref<DetailsDisplayOrderGridRefObject>) => {
    const [innerColumns, setInnerColumns] = useState<GridColumns<any>>();
    const [innerRows, setInnerRows] = useState<any[]>([]);
    const [inDisplayOrderMode, setInDisplayOrderMode] = useState(false);
    const [innerDirty, setInnerDirty] = useState(false);    
    const [saveDirty, setSaveDirty] = useState(false);
    const alertDialog = useAlertDialog(); // TODO: React router v6 does not support blocking yet. Once they add it we will need to show an alert if they navigate while inDisplayOrderMode=true
    const strings = useLocalizedStrings();
    const { checkAccess } = useUser();
    const [alphabetical, setAlphabetical] = useState(false);
    const {dirty, setDirty } = useDialog();
    
    // NOTE: This is pretty hackish. The actions is a callback to the parent page, but we want to inject the display order button here, so this useEffect will do that.           
    useEffect(() => {              
        // Look for an actions column
        const actionsFieldIndex = props.columns.findIndex(c => c.type === 'actions');
        if (actionsFieldIndex !== -1) {
            // Append a display order action to the existing ones
            var actionsField = props.columns[actionsFieldIndex] as GridActionsColDef;
            const getActions = actionsField.getActions; // Capture this property

            // We're going to reassign the getActions to call this one 1st
            actionsField.getActions = (params: GridRowParams) => {
                // Get the current actions (from the parent page)               
                var actions = [...getActions(params)];

                // Check to see if we've already added the display order action 
                const index = actions.findIndex(a => a.type === DisplayOrderButton);
                if (index !== -1) {
                    // If so remove it or we'll keep adding buttons on every redraw
                    actions.splice(index, 1);
                }

                // Now add it
                actions.push(getDisplayOrderAction(params));
                return actions;           
            }

            // Update the actions column
            var temp = [...props.columns];
            temp[actionsFieldIndex] = actionsField;            
            setInnerColumns(temp); 
        }
        else {
            // Add a display order action and column
            setInnerColumns([...props.columns, getActionsField()]);
        }
    }, [props.columns, inDisplayOrderMode, innerRows]);

    useEffect(() => {        
        resetInnerRows();
        setInDisplayOrderMode(false);         
    }, [props.rows]);    

    useImperativeHandle(ref, () => ({isDirty}))

    function isDirty(): boolean {
        if (innerDirty) {
            cancelDisplayOrder();
        }
        return innerDirty;
    }

    function resetInnerRows() {
        setInnerRows(JSON.parse(JSON.stringify(props.rows))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables
    }

    function getActionsField() {
        return {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [getDisplayOrderAction(params)],
        }        
    }

    function getDisplayOrderAction(params: GridRowParams) {
        return (
            <DisplayOrderButton
                permissionKey={props.permissionKey}
                current={getDisplayOrder(params.id.toString())} 
                count={innerRows.length} 
                disabled={!inDisplayOrderMode}
                onMoveTopClick={() => handleMoveTopClick(params.id.toString())}
                onMoveUpClick={() => handleMoveUpClick(params.id.toString())}
                onMoveDownClick={() => handleMoveDownClick(params.id.toString())}
                onMoveBottomClick={() => handleMoveBottomClick(params.id.toString())}
                onSortAlphabetical={handleSortAlphabetical}
            />
        )
    }

    function enterDisplayOrderMode() {       
        if (!props.canPerformAction || props.canPerformAction?.()) {
            setSaveDirty(dirty);
            setInDisplayOrderMode(true);
        }
    }

    function cancelDisplayOrder() {
        if (innerDirty) {
            alertDialog({
                title: strings.discardChangesAlertTitle,
                message: strings.discardChangesAlertMessage,
                destructive: true,
                okButtonTitle: strings.discardButtonTitle,
                cancelButtonTitle: strings.cancelButtonTitle,
            }).then(() => {
                resetInnerRows();                
                setInDisplayOrderMode(false); 
                setInnerDirty(false);
                setDirty(saveDirty);
            });
        } else {
            resetInnerRows();            
            setInDisplayOrderMode(false); 
            setInnerDirty(false);
            setDirty(saveDirty);
        }               
    }

    function saveDisplayOrder() {
        props.onSaveDisplayOrder(innerRows.map(r => r.id), alphabetical);         
        setInDisplayOrderMode(false);
        setInnerDirty(false);
        setDirty(true);
    }

    function handleMoveUpClick(id: string) {   
        setInnerDirty(true);
        setDirty(true);
        setInnerRows(moveUp(innerRows, id, 1));
        setAlphabetical(false);
    }

    function handleMoveDownClick(id: string) {
        setInnerDirty(true);
        setDirty(true);
        setInnerRows(moveDown(innerRows, id, 1));
        setAlphabetical(false);
    }

    function handleMoveTopClick(id: string) {
        setInnerDirty(true);
        setDirty(true);
        setInnerRows(moveTop(innerRows, id));
        setAlphabetical(false);
    }

    function handleMoveBottomClick(id: string) {
        setInnerDirty(true);
        setDirty(true);
        setInnerRows(moveBottom(innerRows, id));
        setAlphabetical(false);
    }

    function handleSortAlphabetical() {
        if (props.sortAlphabeticalField === null) {
            throw new Error("If using sort alphabetical you must provide the field");
        }
        
        setInnerDirty(true);
        setDirty(true);
        setInnerRows(sortAlphabetical(innerRows, props.sortAlphabeticalField!));   
        setAlphabetical(true);
    }

    function getDisplayOrder(id: string) {
        let index = innerRows?.findIndex(r => r.id === id);
        return index;
    }    

    function handleAddButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (innerDirty) {
            cancelDisplayOrder();
        }
        else {
            props.onAddButtonClick?.(event);
        }
    }

    return (
        <DataGrid
            permissionKey={props.permissionKey}
            rows={innerRows}
            selectionModel={props.selectionModel}
            canPerformAction={props.canPerformAction}
            onSelectionModelChange={props.onSelectionModelChange} 
            columns={innerColumns ?? props.columns}
            height={props.height}
            pageSize={props.pageSize}
            showAddButton={props.showAddButton}
            addButtonSize={props.addButtonSize}
            showAddButtonEditOnly={props.showAddButtonEditOnly}
            onAddButtonClick={handleAddButtonClick}
            addButtonText={props.addButtonText}
            addButtonDisabled={props.addButtonDisabled}            
            footerLeft={
                <>
                    { (props.permissionKey && checkAccess(props.permissionKey, Access.Update)) &&
                        <>
                            { !inDisplayOrderMode ? 
                                <Button variant="contained"
                                    size="small"    
                                    disabled={innerRows.length === 0}
                                    onClick={enterDisplayOrderMode}
                                    sx={{ ml: 1 }}
                                >
                                    {strings.displayOrderMode}
                                </Button> :
                                <Stack direction="row" sx={{ ml: 1 }}>
                                    <Button variant="outlined"
                                        size="small"                                                        
                                        onClick={cancelDisplayOrder}
                                    >
                                        {strings.cancelButtonTitle}
                                    </Button>
                                    <Spacer />
                                    <Button variant="contained"
                                        size="small"   
                                        disabled={!innerDirty}
                                        onClick={saveDisplayOrder}
                                    >
                                        {strings.saveButtonTitle}
                                    </Button>
                                </Stack>
                            }
                        </>
                    }
                </>     
            }
        />
    )
});

export default DetailsDisplayOrderGrid;