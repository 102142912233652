import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
    DataGrid, DataGridProps, enUS, esES, getDefaultGridFilterModel, gridClasses, GridSelectionModel, GridFilterModel, GridFooter, GridFooterContainer, GridLocaleText, GridRowIdGetter, GridSortModel, GridCallbackDetails
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import Spacer from "../Spacer";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";


/*
  mui DataGrid style overrides:
  1. override the filter icon's visibility and width so it remains visble when
      a filter is applied (default behavior is to hide it when not hovering)

  2. remove outlines on column headers and cells when focused.
*/
const StyledDataGrid = styled(DataGrid)(({ theme, ...props }) => {
  return {
    [`& .${gridClasses.columnHeaderTitleContainer} .${gridClasses.iconButtonContainer}`]:
    {
      visibility: "visible",
      width: "auto",
    },
    [`&.MuiDataGrid-root .${gridClasses.columnHeader}:focus, 
      &.MuiDataGrid-root .${gridClasses.columnHeader}:focus-within, 
      &.MuiDataGrid-root .${gridClasses.cell}:focus, 
      &.MuiDataGrid-root .${gridClasses.cell}:focus-within `
    ]:
    {
      outline: "none"
    }
  };
});

// Exclude certain props that wrapper controls
export interface DataGridWrapperProps
    extends Omit<
    DataGridProps,
    | "filterModel"
    | "onFilterModelChange"
    | "sortModel"
    | "onSortModelChange"
    | "localeText"
    > {
    permissionKey?: string;
    showAddButton?: boolean;
    showAddButtonEditOnly?: boolean;
    addButtonDisabled?: boolean;
    onAddButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;    
    addButtonText?: string;
    addButtonSize?: "small" | "medium" | "large"
    height?: number;
    getRowId?: GridRowIdGetter; 
    footerLeft?: JSX.Element | undefined;   
    canPerformAction?: () => boolean;
}

function DataGridWrapper({
    permissionKey,
    showAddButton = true,
    showAddButtonEditOnly = false,
    addButtonDisabled = false,
    onAddButtonClick,    
    addButtonText,
    addButtonSize = "large",    
    pageSize = 10,
    height: containerHeight = 700,
    rows,
    getRowId,
    footerLeft,   
    onSelectionModelChange,
    canPerformAction,
    ...rest
}: DataGridWrapperProps) {

    const [gridFilterModel, setGridFilterModel] = useState<GridFilterModel>(getDefaultGridFilterModel());
    const [gridSortModel, setGridSortModel] = useState<GridSortModel>([]);
    const [gridLocaleText, setGridLocaleText] = useState<Partial<GridLocaleText | undefined>>()    
    const strings = useLocalizedStrings();
    const currentLanguageKey = strings.getLanguage();
    const { checkAccess } = useUser();
    const alertDialog = useAlertDialog(); // TODO: React router v6 does not support blocking yet. Once they add it we will need to show an alert if they navigate while inDisplayOrderMode=true

    useEffect(() => {
        // Use mui's translations for the grid (imported above) based on the 
        // browser language. Override any missing or incorrect ones with ours.

        if (currentLanguageKey.trim() === "es") {
            setGridLocaleText({
                ...esES.components.MuiDataGrid.defaultProps.localeText,
                booleanCellFalseLabel: strings.gridBooleanCellFalseLabel,
                booleanCellTrueLabel: strings.gridBooleanCellTrueLabel
            });
        } else {
            setGridLocaleText({
                ...enUS.components.MuiDataGrid.defaultProps.localeText,
                booleanCellFalseLabel: strings.gridBooleanCellFalseLabel,
                booleanCellTrueLabel: strings.gridBooleanCellTrueLabel
            });
        }

    }, [currentLanguageKey])

    useEffect(() => {
        // reset any filters and sorting when data changes
        setGridFilterModel(getDefaultGridFilterModel());
        setGridSortModel([]);
    }, [rows])

    function handleSelectionModelChange(model: GridSelectionModel, details: GridCallbackDetails<any>) {
        if (!canPerformAction || canPerformAction?.()) {
            onSelectionModelChange?.(model, details);
        }
    }

    function handleAddButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!canPerformAction || canPerformAction?.()) {            
            onAddButtonClick?.(event);
        }
    }

    function handleFilterModelChange(newModel: GridFilterModel) {
        setGridFilterModel((currentModel: GridFilterModel) => ({
            ...currentModel,
            ...newModel
        }));
    }

    function handleSortModelChange(newModel: GridSortModel) {
        setGridSortModel(newModel);
    }    

    const Footer = () => {
        if (footerLeft) {
            return (
                <GridFooterContainer>
                    {footerLeft}
                    <div></div>
                    <GridFooter sx={{ border: 'none' }} />
                </GridFooterContainer>
            )
        }
        else {
            return (
                <GridFooterContainer>
                    <div></div>
                    <GridFooter sx={{ border: 'none' }} />
                </GridFooterContainer>
            );
        }
    }

    return (
        <>
            {((showAddButton === true) && (permissionKey && checkAccess(permissionKey, Access.Create)) || 
                (showAddButtonEditOnly === true) && (permissionKey && checkAccess(permissionKey, Access.Update))) && (
                <div style={{ textAlign: "right" }}>
                    <Tooltip title={strings.gridAddRowTooltipText}>
                        <Button variant="contained"
                            size={addButtonSize}
                            startIcon={<AddIcon />}
                            disabled={addButtonDisabled}
                            onClick={handleAddButtonClick}
                        >
                            {addButtonText ?? "Add"}
                        </Button>
                    </Tooltip>
                    <Spacer y={2} />
                </div>           
            )}
            <div style={{ height: containerHeight, width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                    <div style={{ flexGrow: 1 }}>
                        <StyledDataGrid
                            localeText={gridLocaleText}
                            pageSize={pageSize}
                            rows={rows}
                            getRowId={getRowId}
                            filterModel={gridFilterModel}
                            onSelectionModelChange={handleSelectionModelChange}
                            onFilterModelChange={handleFilterModelChange}
                            sortModel={gridSortModel}
                            onSortModelChange={handleSortModelChange}
                            hideFooterSelectedRowCount={true}     
                            components={{ Footer: Footer, ...rest.components }}                                                   
                            {...rest}
                        />
                    </div>
                </div>
            </div>


        </>
    );
}

export default DataGridWrapper;