import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, useRef, useState } from "react";
import { default as DialogSelect } from "../../common/details/Select";
import { deleteGraphics } from "../../../api/graphicsApi";
import { createProperty, deleteProperty, getBrandDropdownData, getPropertyById, getPropertyGridData, listTimeZones, updateProperty } from "../../../api/accounts/propertyApi";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { BrandItemModel, mapBrandsToKeyValue } from "../../../models/brandModels";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { PropertyItemDetailModel, initialPropertyState, PropertyItemModel } from "../../../models/propertyModels";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import axios from "axios";
import Typography from "@mui/material/Typography";
import { GridColumns, GridRowParams } from "@mui/x-data-grid";
import DataGrid from "../../common/datatable/DataGridWrapper";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import Stack from '@mui/material/Stack';
import { getAxiosError } from "../../../utilities/AxiosApiHelper";
import TransferList from "../../common/details/TransferList";
import useFeatures from "../../../hooks/useFeatures";
import { Feature } from "../../../models/accounts/Feature";
import HotelIcon from '@mui/icons-material/Hotel';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Button, Grid } from "@mui/material";
import { uploadRooms } from "../../../api/roomApi";
import { RoomsUploadResult } from "../../../models/administration/RoomsUploadResult";
import { TimeZoneModel } from "../../../models/common/TimeZoneModel";
import { time } from "console";
import { Theme, useTheme } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import ValidateTab from "../../common/details/ValidateTab";

interface PropertyValidationErrors {
    id: string;
    name: string;
    latitude: string;
    longitude: string;
    baseImageUrl: string;
    baseVideoUrl: string;
}

const initialErrorState: PropertyValidationErrors = {
    id: "",
    name: "",
    latitude: "",
    longitude: "",
    baseImageUrl: "",
    baseVideoUrl: ""
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function PropertyList() {
    const [propertyData, setPropertyData] = useState<PropertyItemModel[]>();
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedPropertyId, setSelectedPropertyId] = useState("");
    const [selectedProperty, setSelectedProperty] = useState<PropertyItemDetailModel>(initialPropertyState);
    const [isLoading, setIsLoading] = useState(true);
    const [brandData, setBrandData] = useState<BrandItemModel[]>();
    const [timeZones, setTimeZones] = useState<TimeZoneModel[]>([]);
    const [selectedBrandId, setSelectedBrandId] = useState("");
    const [gridRefresh, setGridRefresh] = useState(false);
    const [csvFileName, setCsvFileName] = useState("");
    const [csvFile, setCsvFile] = useState<File | null>(null);
    const [roomUploadResult, setRoomUploadResult] = useState<RoomsUploadResult | undefined>(undefined);
    const [errors, setErrors] = useState<PropertyValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const csvFileUploadRef = useRef<HTMLInputElement>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();
    const {features} = useFeatures();
    const { user, checkAccess } = useUser();    

    useEffect(() => {
        async function getTimeZones() {
            try {
                const payload = await listTimeZones(axiosInstance);
                setTimeZones(payload);
            } catch (e) {
                
            } finally {
            }
        }

        getTimeZones();
    }, []);
    
    useEffect(() => {
        async function getBrands() {
            try {
                const payload = await getBrandDropdownData(axiosInstance);
                setBrandData(payload);
            } catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingBrands,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        getBrands();
    }, [strings.errorRetrievingBrands]);

    useEffect(() => {
        async function getProperties() {
            try {
                const payload = await getPropertyGridData(axiosInstance, selectedBrandId);
                const properties = payload.map(pr => parseLanguageStringsFromJson(pr, "en"));
                setPropertyData(properties);
            } catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingProperties,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        if (selectedBrandId !== "") {
            getProperties();
        }
    }, [selectedBrandId, gridRefresh, strings.errorRetreivingProperties]);

    async function handleEditRowClick(id: string) {
        setSelectedPropertyId(id);

        try {
            const payload = (await getPropertyById(axiosInstance, id)) as PropertyItemDetailModel;

            // We need lat/lon as stirngs for the textfield
            payload.latitudeString = payload.latitude.toString();
            payload.longitudeString = payload.longitude.toString();

            setSelectedProperty(payload);
            setIsEditMode(true);
            setDetailsDialogOpen(true);
        }
        catch (error) {
            setNotify({ isOpen: true, message: `${error}`, msgType: "error" });
        }
        finally {
            setIsLoading(false);
        }

        setDetailsDialogOpen(true);
    }

    function handleAddClick() {
        setSelectedPropertyId("");
        setSelectedProperty(initialPropertyState);
        setIsEditMode(false);
        setSelectedProperty((prevState) => ({
            ...prevState,
            brandId: selectedBrandId
        }));

        setDetailsDialogOpen(true);
    }

    async function deleteSelectedProperty(propertySelected: string) {
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
        ];

        await deleteGraphics(axiosInstance, "property", propertySelected, images, selectedPropertyId);
        await deleteProperty(axiosInstance, propertySelected);
        setPropertyData(propertyData?.filter((property) => property.id !== propertySelected));

        setNotify({
            isOpen: true,
            message: strings.propertyDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
    }

    function handleDeleteClick(id: string) {
        const displayName = propertyData?.find(property => property.id === id)?.name ?? "";

        alertDialog({
            title: strings.deletePropertyAlertTitle,
            message: strings.deletePropertyAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedProperty(id);
        });
    }

    function handleBrandSelectionChange(e: SelectChangeEvent) {
        setSelectedBrandId(e.target.value as string);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleDescriptionChange(content: string, language: string) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            id: event.target.value
        }));
    }

    function handlePropertyBrandSelectionChange(e: SelectChangeEvent) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            brandId: e.target.value
        }));
    }

    function handleTimeZoneSelectionChange(e: SelectChangeEvent) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            timeZoneId: e.target.value
        }));
    }

    function handleBaseImageUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            baseImageUrl: event.target.value == "" ? null : event.target.value
        }));
    }

    function handleBaseVideoUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            baseVideoUrl: event.target.value == "" ? null : event.target.value
        }));
    }

    function handleLatitudeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            latitudeString: event.target.value            
        }));
    }

    function handleLongitudeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            longitudeString: event.target.value            
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedProperty((prevState) => ({
            ...prevState,
            enabled: event.target.checked
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setTabValue(0);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        const isUpdate = selectedPropertyId.trim().length > 0;
        try {
            var property = selectedProperty;            
            property.latitude = Number(property.latitudeString);
            property.longitude = Number(property.longitudeString);

            if (isUpdate) {
                await updateProperty(axiosInstance, property);
            } else {
                property.brandId = selectedBrandId;
                await createProperty(axiosInstance, property);
            }
        } catch (error: unknown) {
            let userMessage = "";
            const result = getAxiosError(error);

            if (result?.response?.status === 409) {
                userMessage = strings.errorPropertyIdAlreadyExists;
            }
            else {
                userMessage = result?.message ?? "unable to parse error info";
            }
        
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingItem
                    : strings.errorUpdatingItem
                ).replace("{{error}}", userMessage),
                msgType: "error",
            });

            return false;
        }

        try {
            if (graphicManagerRef.current) {
                await graphicManagerRef.current.saveGraphics(selectedProperty.id, selectedProperty.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setTabValue(0);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.propertySavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateProperty() {
        var fieldsToValidate: ValidationField[] = [{
                property: "name.en",
                type: ValidationType.Required,
                message: strings.validationErrorPropertyNameRequired
            },
            {
                property: "id",
                type: ValidationType.Custom,
                custom: (value) => !(value.trim().length < 1 || value.trim().length > 10),
                message: strings.validationErrorPropertyIdLength // matt todo: change to IdLength in strings
            },
            {
                property: "id",
                type: ValidationType.Pattern,
                pattern: /^[A-Za-z0-9]*$/,
                message: strings.validationErrorPropertyIdInvalidChars
            },
            {
                property: "latitude",
                type: ValidationType.Required,
                message: strings.validationLatitudeRequired
            },
            {
                property: "longitude",
                type: ValidationType.Required,
                message: strings.validationLongitudeRequired
            }
        ];              

        if (selectedProperty.baseImageUrl && selectedProperty.baseImageUrl !== "") {               
            fieldsToValidate.push({
                property: "baseImageUrl",
                type: ValidationType.URL,
                message: strings.validationUrlInvalid
            });
        }

        if (selectedProperty.baseVideoUrl && selectedProperty.baseVideoUrl !== "") {            
            fieldsToValidate.push({
                property: "baseVideoUrl",
                type: ValidationType.URL,
                message: strings.validationUrlInvalid
            });
        }
        
        var errors = validate<PropertyItemDetailModel, PropertyValidationErrors>(fieldsToValidate, selectedProperty);                
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    function mapFeaturesToKeyValues(features: Feature[]) {
        return features.map(feature => {
            return {
                key: feature.id,
                value: feature.name
            }   
        })
    }

    function handleFeatureListChange(values: {key: string; value: string;}[]) {
        setSelectedProperty((old: PropertyItemDetailModel) => ({
            ...old,
            features: values.map(val => val.key)
        }));
    }

    const handleCsvUploadClick = () => {
        setRoomUploadResult(undefined)
        if (csvFileUploadRef != null) {
            if (csvFileUploadRef.current != null) {
                csvFileUploadRef.current.click();
            }
        }
    }

    function onImageUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        if (files !== null) {
            setCsvFile(files[0]);
            setCsvFileName(files[0].name)
        }
    }

    async function handleRoomUploadClick() {
        if (csvFile !== null) {
            try {
                var result = await uploadRooms(axiosInstance, selectedProperty.id, csvFile);
                setRoomUploadResult(result.uploadResult)
                setCsvFile(null)
                setCsvFileName("")
                setNotify({
                    isOpen: true,
                    message: strings.roomsUploadedSuccessfully,
                    msgType: "success",
                });
            } catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUploadingRooms.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });
    
                return false;
            }
        }
    }


    function mapTimeZonesToKeyValues(timeZones: TimeZoneModel[]): { key: string; value: string }[] {
        let kvps: { key: string; value: string }[] = [];

        kvps = timeZones.map((tz, index) => {
            return {
                key: tz.id,
                value: tz.name,
            };
        });

        return kvps;
    }

    const gridColumns: GridColumns = [
        { field: "id", headerName: strings.propertyIdLabel, flex: 1 }, // matt todo: change to id in strings
        { field: "name", headerName: strings.name, flex: 1 },
        {
            field: "enabled",
            headerName: strings.enabled,
            type: "boolean",
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="administration_company" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />
            ],
        },
    ];

    const dialogTitle = selectedPropertyId === "" ? strings.companyManagerPropertyTitleAdd : strings.companyManagerPropertyTitleEdit.replace("{{name}}", selectedProperty?.name["en"] ?? ""); // TODO: default language

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-label">
                            {strings.brandSelectLabel}
                        </InputLabel>
                        <Select
                            id="selectFieldPropertyBrandList"
                            value={selectedBrandId}
                            label={strings.brandSelectLabel}
                            onChange={handleBrandSelectionChange}
                            MenuProps={{
                                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                transformOrigin: { vertical: "top", horizontal: "left" }
                            }}
                        >
                            {brandData && mapBrandsToKeyValue(brandData).map((kvp, index) => (
                                <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {propertyData && checkAccess("administration_company", Access.Create) &&
                        <Button
                            startIcon={<AddIcon />}
                            sx={{ marginLeft: 'auto' }}
                            variant="contained"                            
                            onClick={handleAddClick}
                            aria-label="Add property"                    
                        >
                            {strings.propertyAddPropertyText}                    
                        </Button>
                    }
                </Box>
                <Spacer y={2}/>
                {propertyData && (
                    <div>
                        <DataGrid
                            getRowId={(row) => row.id} 
                            permissionKey="administration_company"
                            rows={propertyData}
                            columns={gridColumns}
                            showAddButton={false}
                        />
                    </div>
                )}

                <DetailsDialog
                    permissionKey="administration_company"
                    adding={!isEditMode}
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onValidateForm={handleValidateProperty}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 850, height: 714.28 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <ValidateTab label={strings.details} errors={Boolean(errors.name) || Boolean(errors.latitude) || Boolean(errors.longitude)} />                            
                            <Tab label={strings.graphics} />
                            <Tab label={strings.propertyFeaturesTabLabel} />
                            <ValidateTab label={strings.configuration} errors={Boolean(errors.baseImageUrl) || Boolean(errors.baseVideoUrl)} />
                            {selectedPropertyId !== "" &&
                                <Tab label={strings.propertyRoomUploadTabLabel} />
                            }                            
                        </Tabs>
                    </Box>                    
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <Stack direction="row">
                            <TextField
                                id="property-id"
                                label={strings.propertyIdLabel} 
                                value={selectedProperty.id}
                                disabled={isEditMode}
                                width={295}
                                onChange={(e) => {
                                    handleIdChange(e);
                                }}
                                error={Boolean(errors.id)}
                                helperText={errors.id}
                            />
                            <Spacer x={2} />
                            <DialogSelect
                                label={strings.brandSelectLabel}
                                keyValues={mapBrandsToKeyValue(brandData ?? [])}
                                selectedValue={selectedProperty.brandId}
                                onChangeHandler={handlePropertyBrandSelectionChange}
                                sx={{ minWidth: 200 }}
                            />
                        </Stack>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="property-name"
                                label={strings.name}
                                values={selectedProperty.name}
                                width={818}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                            <LanguageRichTextField
                                id="property-description"
                                label={strings.description}
                                values={selectedProperty.description}
                                width={818}
                                height={100}
                                onChange={handleDescriptionChange}
                            />
                        </LanguageForm>
                        <Spacer />
                        <Stack direction="row" spacing={2}>                            
                            <TextField
                                id="latitude"
                                type="number"
                                label={strings.latitude}
                                value={selectedProperty.latitudeString}                                
                                width={250}
                                onChange={handleLatitudeChange}
                                error={Boolean(errors.latitude)}
                                helperText={errors.latitude}
                            />
                            <TextField
                                id="longitude"
                                type="number"
                                label={strings.longitude}
                                value={selectedProperty.longitudeString}                                
                                width={250}
                                onChange={handleLongitudeChange}
                                error={Boolean(errors.longitude)}
                                helperText={errors.longitude}
                            />                            
                        </Stack>       
                        <Spacer />
                        <DialogSelect
                            label={strings.propertyTimeZoneIdLabel}
                            keyValues={mapTimeZonesToKeyValues(timeZones)}
                            selectedValue={selectedProperty.timeZoneId}
                            onChangeHandler={handleTimeZoneSelectionChange}
                            sx={{ minWidth: 200 }}
                        />    
                        <Spacer />
                        <Checkbox
                            label={strings.enabled}
                            checked={selectedProperty?.enabled}
                            onChange={handleEnabledChange}
                        />                                                                                                        
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={1}>
                        <GraphicManager
                            propertyId={selectedPropertyId}
                            itemType="Property"
                            itemKey={selectedPropertyId}
                            imageKeys={["main"]}
                            ref={graphicManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={2}>
                    <TransferList
                            id="features-transfer-list"
                            leftKeyValues={mapFeaturesToKeyValues(features)}
                            rightKeys={selectedProperty.features}
                            leftTitle={strings.availableFeatures}                            
                            rightTitle={strings.selectedFeatures} 
                            onChangeHandler={handleFeatureListChange}
                            height={375}
                            permissionKey="administration_company"
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={3}>
                        <TextField
                            id="property-imageurl"
                            label={strings.propertyBaseImageUrlLabel} 
                            value={selectedProperty.baseImageUrl == null ? "" : selectedProperty.baseImageUrl}
                            width={818}
                            onChange={handleBaseImageUrlChange}
                            error={Boolean(errors.baseImageUrl)}
                            helperText={errors.baseImageUrl}
                        />
                        <Spacer />
                        <TextField
                            id="property-videourl"
                            label={strings.propertyBaseVideoUrlLabel} 
                            value={selectedProperty.baseVideoUrl == null ? "" : selectedProperty.baseVideoUrl}
                            width={818}
                            onChange={handleBaseVideoUrlChange}
                            error={Boolean(errors.baseVideoUrl)}
                            helperText={errors.baseVideoUrl}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={4}>
                        <div>
                            {roomUploadResult === undefined &&
                                <div style={{display: 'flex'}}>
                                    <Typography variant="body1" style={{marginRight: '10px'}}>
                                        Csv File:
                                    </Typography>
                                    <Typography variant="body1">
                                        {csvFileName}
                                    </Typography>
                                </div>
                            }
                            {roomUploadResult !== undefined &&
                                <div style={{display: 'grid', width: '818px', gridTemplateColumns: '50% 50%'}}>
                                    <div>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="body1" style={{marginRight: '10px'}}>
                                                Total Rooms In Csv:
                                            </Typography>
                                            <Typography variant="body1">
                                                {roomUploadResult === undefined ? "" : roomUploadResult.totalRooms}
                                            </Typography>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="body1" style={{marginRight: '10px'}}>
                                                Rooms Uploaded:
                                            </Typography>
                                            <Typography variant="body1">
                                                {roomUploadResult === undefined ? "" : roomUploadResult.roomsUploaded}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="body1" style={{marginRight: '10px'}}>
                                                Total Tvs In Csv:
                                            </Typography>
                                            <Typography variant="body1">
                                                {roomUploadResult === undefined ? "" : roomUploadResult.totalTvs}
                                            </Typography>
                                        </div>
                                        <div style={{display: 'flex'}}>
                                            <Typography variant="body1" style={{marginRight: '10px'}}>
                                                TVs Uploaded:
                                            </Typography>
                                            <Typography variant="body1">
                                                {roomUploadResult === undefined ? "" : roomUploadResult.tvsUploaded}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div style={{display: 'grid', width: '818px', gridTemplateColumns: '50% 50%'}}>
                                <div>
                                    <Spacer/>
                                    <Spacer/>
                                    <Button variant="contained" endIcon={<FileOpenIcon />} onClick={handleCsvUploadClick}>
                                        {strings.propertyRoomUploadSelectFileButton}
                                    </Button>
                                </div>
                                <div>
                                    <Spacer/>
                                    <Spacer/>
                                    {csvFileName !== "" &&
                                        <Button variant="contained" endIcon={<HotelIcon />} onClick={handleRoomUploadClick}>
                                            {strings.propertyRoomUploadButton}
                                        </Button>
                                    }
                                </div>
                            </div>

                            <input
                                style={{visibility: 'hidden'}} 
                                type="file"
                                accept=".csv"
                                ref={csvFileUploadRef}
                                onChange={onImageUploadChange} />
                        </div>
                    </TabPanelKeepMounted>                    
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default PropertyList;
