import EditIcon from "@mui/icons-material/Edit";
import { Button, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from '@mui/material/styles';
import axios from "axios";
import * as React from 'react';
import { useEffect, useState } from "react";
import { IMask, IMaskInput } from 'react-imask';
import { getConfiguration, updateConfiguration } from "../../../api/pms/configurationApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { Property } from "../../../models/pms/Property";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import { default as DialogSelect } from "../../common/details/Select";
import { default as TextField } from '../../common/details/TextField';
import { HHmmMaskedTextField } from "../../common/details/MaskedTextField";

interface ValidationErrors {
    externalId: string;
    guestSyncTime: string;
    fidelioAddress: string;    
    maestroPassword: string;    
    ohipUrl: string;
    ohipClientId: string;
    ohipClientSecret: string;
    ohipUserName: string;
    ohipPassword: string;
    ohipCashierId: string;
}

function getPropertyInitialState(): Property {
    return {
        externalId: "",
        provider: ""
    }
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const HHmmMask = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        const [initialSet, setInitialSet] = React.useState(true);

        function getValue() {
            return "";
        }

        return (
            <IMaskInput
                {...other}
                mask="HHmm"
                blocks={{
                    HH: {
                        mask: IMask.MaskedRange,
                        placeholderChar: 'HH',
                        from: 0,
                        to: 23,
                        maxLength: 2
                    },
                    mm: {
                        mask: IMask.MaskedRange,
                        placeholderChar: 'mm',
                        from: 0,
                        to: 59,
                        maxLength: 2
                    }
                }}
                inputRef={ref}     
                value={getValue()}
                onAccept={(value: any) => {
                    if (initialSet) {
                        setInitialSet(false);
                        return
                    }
                    onChange({ target: { name: props.name, value } })
                }}
                overwrite
            />
        );
    },
);

const PMS = () => {
    const initialErrorState: ValidationErrors = {
        externalId: "",
        guestSyncTime: "",
        fidelioAddress: "",        
        maestroPassword: "",
        ohipUrl: "",
        ohipClientId: "",
        ohipClientSecret: "",
        ohipUserName: "",
        ohipPassword: "",
        ohipCashierId: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [property, setProperty] = useState<Property>(getPropertyInitialState());
    const [editProperty, setEditProperty] = useState<Property>(getPropertyInitialState());
    const [isLoading, setIsLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();

    useEffect(() => {
        async function load() {
            try {
                const payload = await getConfiguration(axiosInstance, user.currentProperty?.code ?? "");
                setProperty(payload);
                setEditProperty(JSON.parse(JSON.stringify(payload))); // HACK: https://stackoverflow.com/questions/42306712/react-one-state-variable-depends-on-multiple-other-states-variables                
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: "Error retrieving configuration. {{error}}".replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [gridRefresh, user.currentProperty?.code]);

    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(true);
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setEditProperty(JSON.parse(JSON.stringify(property)));
        setDetailsDialogOpen(false);
    }

    const handleProviderChange = (event: SelectChangeEvent) => {
        setEditProperty((prevState) => ({
            ...prevState,
            provider: event.target.value
        }));
    };

    function handleExternalIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            externalId: event.target.value
        }));
    }

    function handleFidelioAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            fidelioConfiguration: {
                ...prevState.fidelioConfiguration,
                address: event.target.value
            }
        }));
    }

    function handleGuestSyncTimeChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            guestSyncTime: event.target.value
        }));
    }

    function handleMaestroPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            maestroConfiguration: {
                ...prevState.maestroConfiguration,
                password: event.target.value
            }
        }));
    }

    function handleOHIPUrlChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                url: event.target.value
            }
        }));
    }

    function handleOHIPClientIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                clientId: event.target.value
            }
        }));
    }

    function handleOHIPClientSecretChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                clientSecret: event.target.value
            }
        }));
    }

    function handleOHIPUserNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                userName: event.target.value
            }
        }));
    }

    function handleOHIPPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                password: event.target.value
            }
        }));
    }

    function handleOHIPCashierIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setEditProperty((prevState) => ({
            ...prevState,
            ohipConfiguration: {
                ...prevState.ohipConfiguration,
                cashierId: parseInt(event.target.value)
            }
        }));
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
       
        try {            
            await updateConfiguration(axiosInstance, user.currentProperty?.code ?? "", editProperty);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: "Error updating configuration. {{error}}".replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setNotify({
            isOpen: true,
            message: "Configuration saved successfully",
            msgType: "success",
        });

        return true;
    }

    function handleValidate() {
        var fields: ValidationField[] = [
            { property: "externalId", type: ValidationType.Required, message: "External ID is required" } ,    
            { property: "guestSyncTime", type: ValidationType.Required, message: "Guest sync time is invalid.", custom: (value) => value.trim().length === 4, errorProperty: "guestSyncTime" }
        ]

        switch (editProperty.provider) {
            case "Fidelio":
                fields.push({ property: "fidelioConfiguration.address", type: ValidationType.Required, message: "Address is required.", errorProperty: "fidelioAddress" });                
                break;
            case "Maestro": {
                fields.push({ property: "maestroConfiguration.password", type: ValidationType.Required, message: "Password is required" , errorProperty: "maestroPassword" } );
                break;
            }
            case "Ohip": {
                fields.push({ property: "ohipConfiguration.url", type: ValidationType.Required, message: "URL is required" , errorProperty: "ohipUrl" } );
                fields.push({ property: "ohipConfiguration.clientId", type: ValidationType.Required, message: "Client ID is required" , errorProperty: "ohipClientId" } );
                fields.push({ property: "ohipConfiguration.clientSecret", type: ValidationType.Required, message: "Client Secret is required" , errorProperty: "ohipClientSecret" } );
                fields.push({ property: "ohipConfiguration.userName", type: ValidationType.Required, message: "User Name is required" , errorProperty: "ohipUserName" } );
                fields.push({ property: "ohipConfiguration.password", type: ValidationType.Required, message: "Password is required" , errorProperty: "ohipPassword" } );
                break;
            }
        }

        var errors = validate<Property, ValidationErrors>(fields, editProperty);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }        
    }

    function getReadOnlyFields(provider: string) {        
        switch (provider) {
            case "Fidelio":
                return (
                    <>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Address:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.fidelioConfiguration?.address}</Typography></td>
                        </tr>
                    </>
                );
            case "Maestro":
                return (
                    <>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Password:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.maestroConfiguration?.password}</Typography></td>
                        </tr>                        
                    </>
                );
            case "Ohip":
                return (
                    <>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>URL:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.ohipConfiguration?.url}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Client ID:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.ohipConfiguration?.clientId}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>User Name:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.ohipConfiguration?.userName}</Typography></td>
                        </tr>
                        <tr>
                            <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Cashier ID:</Typography></td>
                            <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.ohipConfiguration?.cashierId}</Typography></td>
                        </tr>                        
                    </>
                );
            default:
                return (<></>);
        }        
    }

    function getFields(provider: string) {        
        switch (provider) {
            case "Fidelio":
                return (
                    <>
                        <TextField
                            id="fidelio-address"
                            label="Address"
                            width={400}
                            value={editProperty?.fidelioConfiguration?.address ?? ""}
                            onChange={handleFidelioAddressChange}
                            error={Boolean(errors.fidelioAddress)}
                            helperText={errors.fidelioAddress} />
                    </>
                );
            case "Maestro":
                return (
                    <>
                        <TextField
                            id="maestro-password"
                            label="Password"
                            width={400}
                            value={editProperty?.maestroConfiguration?.password ?? ""}
                            onChange={handleMaestroPasswordChange}
                            error={Boolean(errors.maestroPassword)}
                            helperText={errors.maestroPassword} />
                        <Spacer y={2} />
                    </>
                );
            case "Ohip":
                return (
                    <>
                        <TextField
                            id="ohip-url"
                            label="URL"
                            width={800 + parseFloat(theme.spacing(2))}                            
                            value={editProperty?.ohipConfiguration?.url?.toString() ?? ""}
                            onChange={handleOHIPUrlChange}
                            error={Boolean(errors.ohipUrl)}
                            helperText={errors.ohipUrl} />
                        <Spacer y={2} />
                        <Stack direction="row">
                            <TextField
                                id="ohip-clientid"
                                label="Client ID"
                                width={400}                            
                                value={editProperty?.ohipConfiguration?.clientId?.toString() ?? ""}
                                onChange={handleOHIPClientIdChange}
                                error={Boolean(errors.ohipClientId)}
                                helperText={errors.ohipClientId} />
                            <Spacer x={2} />
                            <TextField
                                id="ohip-clientsecret"
                                label="Client Secret"
                                type="password"
                                width={400}                            
                                value={editProperty?.ohipConfiguration?.clientSecret?.toString() ?? ""}
                                onChange={handleOHIPClientSecretChange}
                                error={Boolean(errors.ohipClientSecret)}
                                helperText={errors.ohipClientSecret} />
                        </Stack>
                        <Spacer y={2} />
                        <Stack direction="row">
                            <TextField
                                id="ohip-username"
                                label="User Name"
                                width={400}                            
                                value={editProperty?.ohipConfiguration?.userName?.toString() ?? ""}
                                onChange={handleOHIPUserNameChange}
                                error={Boolean(errors.ohipUserName)}
                                helperText={errors.ohipUserName} />
                            <Spacer x={2} />
                            <TextField
                                id="ohip-password-id"
                                label="Password"
                                type="password"
                                width={400}                            
                                value={editProperty?.ohipConfiguration?.password?.toString() ?? ""}
                                onChange={handleOHIPPasswordChange}
                                error={Boolean(errors.ohipPassword)}
                                helperText={errors.ohipPassword} />
                        </Stack>
                        <Spacer y={2} />
                        <TextField
                            id="cashier-id"
                            label="Cashier ID"
                            width={400}                            
                            value={editProperty?.ohipConfiguration?.cashierId?.toString() ?? ""}
                            onChange={handleOHIPCashierIdChange}
                            error={Boolean(errors.ohipCashierId)}
                            helperText={errors.ohipCashierId} />
                        <Spacer y={2} />
                    </>
                );
            default:
                return (<></>);
        }        
    }

    function getProviderName(provider: string) {
        switch (provider.toUpperCase()) {
            case "FIDELIO":
                return "Fidelio";
            case "HTNG":
                return "HTNG";
            case "OHIP":
                return "OHIP";
            case "MAESTRO":
                return "Maestro";
            case "DEMO":
            default:
                return"Demo";
        }
    }

    return (
        <Box mt={2} ml={2}>
            {checkAccess("administration_configuration", Access.Update) &&
                <>
                    <Tooltip title={strings.edit}>
                        <Button
                            variant="contained"
                            size="large"
                            aria-label="audit"
                            aria-haspopup="true"
                            onClick={handleEditClick}
                            startIcon={<EditIcon />}
                        >
                            {strings.edit}
                        </Button>
                    </Tooltip>
                    <Spacer y={2} />
                </>
            }
            <table cellSpacing={theme.spacing(1)} style={{ marginLeft: theme.spacing(-1) }}>
                <tbody>
                    <tr>
                        <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Provider:</Typography></td>
                        <td><Typography sx={{ whiteSpace: 'nowrap' }}>{getProviderName(property.provider)}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>External ID:</Typography></td>
                        <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.externalId}</Typography></td>
                    </tr>
                    <tr>
                        <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>Guest Sync Time:</Typography></td>
                        <td><Typography sx={{ whiteSpace: 'nowrap' }}>{property.guestSyncTime}</Typography></td>
                    </tr>
                    {getReadOnlyFields(editProperty.provider)}
                </tbody>
            </table>

            <DetailsDialog
                permissionKey="administration_configuration"
                open={detailsDialogOpen}
                title={strings.editConfigurationTitle}
                onValidateForm={handleValidate}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                contentSize={{ width: 848, height: 672.34 }}
            >
                <Box mt={2} ml={2}>
                    <DialogSelect
                        label="Provider"
                        keyValues={[
                            { key: "Demo", value: "Demo" },
                            { key: "Fidelio", value: "Fidelio" },
                            { key: "Htng", value: "HTNG" },
                            { key: "Maestro", value: "Maestro" },
                            { key: "Ohip", value: "OHIP" }
                        ]}
                        selectedValue={editProperty.provider}
                        onChangeHandler={handleProviderChange}
                        sx={{ minWidth: 250 }}
                    />                   
                    <Spacer y={2} />
                    <TextField
                        id="role-name"
                        label="External ID"
                        width={400}
                        value={editProperty.externalId}
                        onChange={handleExternalIdChange}
                        error={Boolean(errors.externalId)}
                        helperText={errors.externalId} />
                    <Spacer y={2} />
                    <HHmmMaskedTextField
                        id="refresh-time"
                        label="Guest Sync Time - UTC (HHmm)"
                        width={400}
                        value={editProperty?.guestSyncTime ?? ""}
                        onChange={handleGuestSyncTimeChange}
                        error={Boolean(errors.guestSyncTime)}
                        helperText={errors.guestSyncTime}
                     />
                     <Spacer y={2} />
                     {getFields(editProperty.provider)}
                </Box>
            </DetailsDialog>

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default PMS;
