import * as React from "react";
import Box from "@mui/material/Box";
import { GridColumns, GridRowParams } from "@mui/x-data-grid";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import Spacer from "../../common/Spacer";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import { LoyaltyLevel, LoyaltyLevelDetail, initialItemState } from "../../../models/modules/roommanager/LoyaltyLevel";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import LinearProgress from "@mui/material/LinearProgress";
import axios from "axios";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import {
    getLoyaltyLevelGridData,
    getLoyaltyLevelById,
    createLoyaltyLevel,
    updateLoyaltyLevel,
    deleteLoyaltyLevel
} from "../../../api/loyaltyLevelApi"
import { useTheme } from "@mui/material/styles";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";

interface LoyaltyLevelValidationErrors {
    name: string;
    externalKey: string; 
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const initialErrorState: LoyaltyLevelValidationErrors = { 
    name: "", 
    externalKey: "" 
}

function LoyaltyLevelList() {
    const [loyaltyLevels, setLoyaltyLevels] = React.useState<LoyaltyLevel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = React.useState(false);
    const [errors, setErrors] = React.useState<LoyaltyLevelValidationErrors>(initialErrorState);
    const [selectedItem, setSelectedItem] = React.useState<LoyaltyLevelDetail>(initialItemState);
    const [selectedLoyaltyLevelId, setSelectedLoyaltyLevelId] = React.useState("");
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();
    const { user, checkAccess } = useUser();

    React.useEffect(() => {
        const getLoyaltyLevels = async () => {
            try {
                setIsLoading(true);
                const response = await getLoyaltyLevelGridData(axiosInstance, user.currentProperty?.code ?? ""); 
                setLoyaltyLevels(response.loyaltyLevels
                    .map(loyaltyLevelItem =>
                        parseLanguageStringsFromJson(loyaltyLevelItem, "en")));
            }
            catch (err: unknown) {
                const error = axios.isAxiosError(err)
                ? { message: err.message }
                : { message: "Unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingLoyaltyLevels.replace("{{error}}", error.message),
                    msgType: "error"
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getLoyaltyLevels();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetrievingLoyaltyLevels]);

    async function handleEditRowClick(id: string) {
        setSelectedLoyaltyLevelId(id);
        try {
            const response = await getLoyaltyLevelById(axiosInstance, user.currentProperty?.code ?? "", id)
            const item = parseLanguageStringsFromJson(response) as LoyaltyLevelDetail;
            setSelectedItem(item ?? initialItemState);
            setDetailsDialogOpen(true);
        }
        catch (err: unknown) {
            const error = axios.isAxiosError(err)
                ? { message: err.message }
                : { message: "Unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingLoyaltyLevel.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    async function deleteSelectedLoyaltyLevel(id: string) {
        try {
            await deleteLoyaltyLevel(axiosInstance, user.currentProperty?.code ?? "", id);
            setLoyaltyLevels(loyaltyLevels.filter((item) => item.id !== id));

            setNotify({
                isOpen: true,
                message: strings.loyaltyLevelDeletedSuccessfully,
                msgType: "success",
            });
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "Unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingLoyaltyLevel.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    function handleDeleteClick(id: string) {
        const displayName = loyaltyLevels.find(item => item.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteLoyaltyLevelAlertTitle,
            message: strings.deleteLoyaltyLevelAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedLoyaltyLevel(id);
        });
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);

        const isUpdate = selectedLoyaltyLevelId.trim().length > 0;
        try {
            if (isUpdate) {
                await updateLoyaltyLevel(axiosInstance, user.currentProperty?.code ?? "", selectedItem);
            } else {
                await createLoyaltyLevel(axiosInstance, user.currentProperty?.code ?? "", selectedItem);
            }
        } 
        catch (err: unknown) {
            const error = axios.isAxiosError(err)
                ? { message: err.message }
                : { message: "Unable to parse error info" };

            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingItem
                    : strings.errorUpdatingItem
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.loyaltyLevelSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateLoyaltyLevel() {
        var fieldsToValidate: ValidationField[] = [{
            property: "name.en",
            type: ValidationType.Required,
            message: strings.validationErrorLoyaltyLevelNameRequired
          },
          {
            property: "externalKey",
            type: ValidationType.Required,
            message: strings.validationErrorLoyaltyLevelExternalKeyRequired
          }];
              
          var errors = validate<LoyaltyLevelDetail, LoyaltyLevelValidationErrors>(fieldsToValidate, selectedItem);
          if (errors) {
            setErrors(errors);
            return false;
          }
          
          return true;
    }

    function handleNameChange(e: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedItem((current: LoyaltyLevelDetail) => ({
            ...current,
            name: {
                ...current.name,
                [e.target.language]: e.target.value
            }
        }));
    }

    function handleExternalKeyChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedItem((current: LoyaltyLevelDetail) => ({
            ...current,
            externalKey: e.currentTarget.value,
        }));
    }

    function handleAddClick() {
        setSelectedLoyaltyLevelId("");
        setSelectedItem(initialItemState);
        setDetailsDialogOpen(true);
    }

    const gridColumns: GridColumns = [
        { field: "name", headerName: strings.name, flex: 1 },
        { field: "externalKey", headerName: strings.externalId, flex: 1 },
        //{ field: "tier", headerName: "tier-add ML", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="administration_company" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
              />,
                <DataGridDeleteButton
                    permissionKey="administration_company"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    const dialogTitle = selectedLoyaltyLevelId === "" ? strings.roomManagerLoyaltyLevelTitleAdd : strings.roomManagerLoyaltyLevelTitleEdit.replace("{{name}}", selectedItem?.name["en"] ?? "");

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <DataGrid
                    permissionKey="administration_company"
                    columns={gridColumns}
                    rows={loyaltyLevels}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.addLoyaltyLevelButtonText}                    
                    components={{
                        LoadingOverlay: LinearProgress
                    }}
                />
                <DetailsDialog
                    permissionKey="administration_company"
                    adding={selectedLoyaltyLevelId.length === 0}
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    onValidateForm={handleValidateLoyaltyLevel}
                    contentSize={{ width: 536, height: 276.72 }}
                >
                    <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="loyalty-level-name"
                                label={strings.name}
                                width={200}
                                disabled={false}
                                values={selectedItem.name}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                        </LanguageForm>
                        <TextField
                            id="external-id"
                            label={strings.externalId}
                            width={200}
                            value={selectedItem.externalKey}
                            disabled={false}
                            onChange={handleExternalKeyChange}
                            error={Boolean(errors.externalKey)}
                            helperText={errors.externalKey}
                        />
                    </Box>
                </DetailsDialog>
                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
}

export default LoyaltyLevelList;
