import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import MuiIconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { GridColumns, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { getDevices } from "../../../api/stb/devicesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Device, deviceInitialState } from "../../../models/stb/Device";
import { useUser } from "../../../providers/UserProvider";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { NotificationOptions } from "../../common/NotificationMessage";
import TimeSpan from "../../../utilities/classes/TimeSpan";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { default as MaterialTextField } from "@mui/material/TextField";
import SearchIcon from '@mui/icons-material/Search';
import Spacer from "../../common/Spacer";
import DataGridExportToolBar from '../../reporting/itv/DataGridExportToolBar';

const StbList = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [devices, setDevices] = useState<Device[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<Device>(deviceInitialState);
    const [isLoading, setIsLoading] = useState(true);   
    const [searchValue, setSearchValue] =  useState("");
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isSearching, setIsSearching] = useState(false);
    const open = Boolean(anchorEl);
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();  
   // const classes = useStyles();

    useEffect(() => {
        async function load() {
            try {
                setIsLoading(true);
                var payload = await getDevices(axiosInstance, user.currentProperty?.code ?? "");
                payload = payload.filter(d => isNumber(d.externalId));
                setDevices(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingStbs.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        load();
    }, [user.currentProperty?.code, strings.errorRetrievingStbs]);

    function isNumber(n: string) {
        return !isNaN(parseFloat(n));
    }

    function handleMoreButtonClicked(id: string, event: React.MouseEvent<HTMLElement>) {
        var device = devices?.find(d => d.id === id);
        if (device) {
            setSelectedDevice(device);
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    async function handleSearchClick() {        
        try {
            setIsSearching(true);

            let response;
            if (searchValue !== null && searchValue !== "") {
                response = await getDevices(axiosInstance, user.currentProperty?.code ?? "", searchValue);
            }
            else {
                response = await getDevices(axiosInstance, user.currentProperty?.code ?? "");
            }
            response = response.filter(d => isNumber(d.externalId));
            setDevices(response);
        } catch (e) {
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingStbs,
                msgType: "error",
            });
        }
        finally {
            setIsSearching(false);
        }
    };

    function handleSearchRoomChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(event.target.value as string);
    }

    const gridColumns: GridColumns = [            
        { field: "roomNumber", headerName: strings.roomNumber,  valueGetter: (params) => params.row.television?.room?.number, flex: 1 },
        { field: "tvName", headerName: strings.name, valueGetter: (params) => params.row.television?.name, flex: 2 },
        { field: "externalId", headerName: strings.externalId, flex: 2 }, 
        { field: "showPreviewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (params) => params.row.television?.showPreviewOnly,  sortable: false,  flex: 1, disableExport: true },           
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => params.row.television ? [    
                <Tooltip title={strings.more}>
                    <MuiIconButton sx={{ color: "primary.dark" }} aria-label="more" component="label" onClick={(event: React.MouseEvent<HTMLElement>) => handleMoreButtonClicked(params.row.id, event)}>                    
                        <MoreHorizIcon />
                    </MuiIconButton>
                </Tooltip>
            ] : [],
        },
    ];

    return (
        <Box sx={{ padding: theme.spacing(2) }}>
            <Box>
                <Stack direction="row">                
                    <MaterialTextField                    
                        onChange={handleSearchRoomChange}
                        label={strings.roomNumber}
                        variant="outlined"
                        value={searchValue}   
                        onKeyDown={(e) => (e.keyCode === 13 ? handleSearchClick() : null)}
                    />
                    <Spacer x={2} />           
                    <Button                    
                        variant="contained"                                            
                        onClick={handleSearchClick}
                        startIcon={<SearchIcon />}
                        aria-label="Search">
                        {strings.search}
                    </Button>
                </Stack>
                <Spacer y={2} />  
                <DataGrid
                    permissionKey="configuration_property"
                    rows={devices}
                    columns={gridColumns}                    
                    showAddButton={false}
                    loading={isSearching}
                    components={{
                        LoadingOverlay: LinearProgress,
                        Toolbar: DataGridExportToolBar
                    }}
                />
            </Box>

            <Typography variant="caption">{strings.apDisclaimer}</Typography>

            <Popover                
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <table cellSpacing={theme.spacing(1)} style={{ padding: theme.spacing(1) }}>   
                    <tbody>
                        {selectedDevice.assignedOn &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.assignedOn}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedDevice.assignedOn.toLocaleString()}</Typography></td>
                            </tr>
                        }
                        {selectedDevice.lastActivityAt &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.lastSeen}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedDevice.lastActivityAt.toLocaleString()}</Typography></td>
                            </tr>
                        }
                        {selectedDevice.restartedAt &&
                            <tr>
                                <td><Typography fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>{strings.restartedAt}:</Typography></td>
                                <td><Typography sx={{ whiteSpace: 'nowrap' }}>{selectedDevice.restartedAt.toLocaleString()}</Typography></td>
                            </tr>
                        }
                    </tbody>  
                </table>                
            </Popover>
        </Box>
    )
}

export default StbList;