import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataGrid from "../../../common/datatable/DataGridWrapper";
import { GridColumns, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../../hooks/useCreateAxios";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../../utilities/Validator";
import { useAlertDialog } from "../../../common/AlertDialog/AlertDialogProvider";
import DataGridEditButton from "../../../common/datatable/DataGridEditButton";
import DataGridDeleteButton from "../../../common/datatable/DataGridDeleteButton";
import DetailsDialog from "../../../common/details/DetailsDialog";
import NotificationMessage, { NotificationOptions } from "../../../common/NotificationMessage";
import { useLocalizedStrings } from "../../../../localization/LocalizedStringsProvider";
import TabPanelKeepMounted from "../../../common/TabPanelKeepMounted";
import Spacer from "../../../common/Spacer";
import TextField from "../../../common/details/TextField";
import Checkbox from "../../../common/details/Checkbox";
import { listCustomers, getCustomer, createCustomer, updateCustomer, deleteCustomer } from "../../../../api/accounts/customerApi";
import { Customer, initialCustomerState } from "../../../../models/accounts/Customer";
import { useUser } from "../../../../providers/UserProvider";
import { useAuth } from "oidc-react";

interface ValidationErrors {
    id: string;
    name: string;
}

const initialErrorState: ValidationErrors = {
    id: "",
    name: "",
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
}

function CustomerList() {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [customerList, setCustomerList] = useState<Customer[]>([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer>(initialCustomerState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const { user, setUser } = useUser();
    const auth = useAuth();

    useEffect(() => {
        async function getCustomers() {
            try {
                const payload = await listCustomers(axiosInstance);
                setCustomerList(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingCustomers.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        getCustomers();
    }, [gridRefresh])

    function handleAddClick() {
        setSelectedCustomerId("");
        setSelectedCustomer(initialCustomerState);
        setDetailsDialogOpen(true);
    }

    async function handleEditRowClick(id: string) {
        setSelectedCustomerId(id);

        try {
            const payload = await getCustomer(axiosInstance, id);
            setSelectedCustomer(payload);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingCustomer.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    async function handleSaveClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const isUpdate = selectedCustomerId.length > 0;

        try {
            if (isUpdate) {
                await updateCustomer(axiosInstance, selectedCustomer);
            } else {
                await createCustomer(axiosInstance, selectedCustomer);
                await signOutUserThenRedirect();
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: selectedCustomerId === ""
                    ? strings.errorCreatingCustomer
                    : strings.errorUpdatingCustomer
                        .replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setSelectedCustomerId("");
        setGridRefresh(!gridRefresh);
        setDetailsDialogOpen(false);
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.customerSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    async function signOutUserThenRedirect(): Promise<void> {
        auth.userManager.removeUser();
        auth.userManager.signinRedirect({ acr_values: `tenant_id:admin`, prompt: "login" }).then(() => {
            setUser({ ...user, id: undefined })
        });
    }

    function handleDeleteClick(id: string) {
        const displayName = customerList.find(customer => customer.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteCustomerAlertTitle,
            message: strings.deleteCustomerAlertMessage.replace("{{name}}", displayName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedCustomer(id);
        });
    }

    async function deleteSelectedCustomer(id: string) {
        try {
            await deleteCustomer(axiosInstance, id);
            await signOutUserThenRedirect();
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingCustomer.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.customerDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedCustomerId("");
        setSelectedCustomer(initialCustomerState);
    }

    function handleCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setSelectedCustomer(initialCustomerState);
    }

    function handleValidateForm() {
        setErrors(initialErrorState);

        const toValidate: ValidationField[] = [
            {
                property: "name",
                type: ValidationType.Required,
                message: strings.validationErrorCustomerNameRequired
            },
            {
                property: "id",
                type: ValidationType.Custom,
                custom: (value) => !(value.trim().length < 1 || value.trim().length > 10),
                message: strings.validationErrorCustomerIdLength
            },
            {
                property: "id",
                type: ValidationType.Pattern,
                pattern: /^[A-Za-z0-9]*$/,
                message: strings.validationErrorCustomerIdInvalidChars
            }
        ];

        const errors = validate<Customer, ValidationErrors>(toValidate, selectedCustomer);

        if (errors) {
            setErrors(errors);
            return false;
        }

        return true;
    }

    function handleTabChange(event: React.SyntheticEvent<Element, Event>, newValue: any) {
        setTabValue(newValue);
    };

    function handleNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSelectedCustomer((current: Customer) => ({
            ...current,
            name: e.currentTarget.value
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedCustomer((prevState: Customer) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handleIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedCustomer((prevState: Customer) => ({
            ...prevState,
            id: event.target.value,
        }));
    }

    const dialogTitle = selectedCustomerId === ""
        ? strings.addCustomerButtonText
        : strings.customerDialogTitleEdit.replace("{{name}}", selectedCustomer?.name ?? "");

    const gridColumns: GridColumns = [
        { field: "id", headerName: strings.idLabel, hideable: false, flex: 1 },
        { field: "name", headerName: strings.name, flex: 1 },
        { field: "enabled", headerName: strings.enabled, type: "boolean", flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="configuration_security"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditRowClick(params.id.toString())}
                />
            ],
        },
    ];

    return (
        <Box sx={{ pt: 2, pr: 1 }}>
            <DataGrid
                permissionKey="configuration_security"
                rows={customerList}
                columns={gridColumns}
                onAddButtonClick={handleAddClick}
                addButtonText={strings.addCustomerButtonText}
                loading={isLoading}
                components={{
                    LoadingOverlay: LinearProgress
                }}
            />
            <DetailsDialog
                permissionKey="configuration_security"
                open={detailsDialogOpen}
                title={dialogTitle}
                onCancelClick={handleCancelClick}
                onSaveClick={handleSaveClick}
                onValidateForm={handleValidateForm}
                adding={selectedCustomerId === ""}
                contentSize={{ width: 500, height: 325 }}
            >
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="Customer Details Tab"
                    >
                        <Tab label={strings.detailsTabTitle} />
                    </Tabs>
                </Box>
                <TabPanelKeepMounted value={tabValue} index={0}>
                    <TextField
                        id="customer-id"
                        label={strings.idLabel}
                        width={300}
                        disabled={selectedCustomerId.trim().length > 1}
                        value={selectedCustomer.id}
                        onChange={handleIdChange}
                        error={Boolean(errors.id)}
                        helperText={errors.id}
                    />
                    <Spacer />
                    <TextField
                        id="customer-name"
                        label={strings.name}
                        width={300}
                        value={selectedCustomer.name}
                        onChange={handleNameChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                    />
                    <Spacer />
                    <Checkbox
                        id="customer-enable"
                        label={strings.enabled}
                        checked={selectedCustomer.enabled}
                        onChange={handleEnabledChange}
                    />
                </TabPanelKeepMounted>
            </DetailsDialog>
            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    )
}

export default CustomerList;