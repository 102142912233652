import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ImageIcon from '@mui/icons-material/Image';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import RttIcon from '@mui/icons-material/Rtt';
import VideocamIcon from '@mui/icons-material/Videocam';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { GridColumns, GridSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { useAuth } from "oidc-react";
import { useEffect, useRef, useState } from "react";
import { approveMessage, deleteMessage, getInReviewMessages } from "../../../api/messaging/messagesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import useGuestSelectorData from "../../../hooks/useGuestSelectorData";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { getInitialMessageState, Message as MessageModel } from "../../../models/modules/messaging/Message";
import { MessageType } from '../../../models/modules/messaging/MessageType';
import { useUser } from "../../../providers/UserProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import Message from "./Message";
import Box from '@mui/material/Box';
import { VideoManagerRefObject } from '../../common/video/VideoManager';
import { mapLanguagesToKeyValues } from '../../../models/configuration/settings/Language';

interface ValidationErrors {
    from: string;
    sendAt: string;
    subject: string;
    text: string;
}

interface InReviewProps {
    onGridRefresh: () => void;
}

const InReview = (props: InReviewProps) => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const initialErrorState: ValidationErrors = {
        from: "",
        sendAt: "",
        subject: "",
        text: ""
    }

    const [gridRefresh, setGridRefresh] = useState(false);
    const [messages, setMessages] = useState<MessageModel[]>([]);
    //const [allRooms, setAllRooms] = useState<{id: string, number: string}[]>([]);
    //const [allClasses, setAllClasses] = useState<{id: string, name: string}[]>([]);
    //const [allLoyaltyLevels, setAllLoyaltyLevels] = useState<{id: string, name: string}[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [selectedMessage, setSelectedMessage] = useState<MessageModel>(getInitialMessageState());
    const [selectedPreviewMessage, setSelectedPreviewMessage] = useState<MessageModel>(getInitialMessageState());
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [isLoading, setIsLoading] = useState(true);        
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [newMessageMenuAnchor, setNewMessageMenuAnchor] = useState<null | HTMLElement>(null);
    const newMessageMenuOpen = Boolean(newMessageMenuAnchor);
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user, checkAccess } = useUser();
    const auth = useAuth();
    const {selectorIdsToValues, formattedSelectorValues} = useGuestSelectorData("en");

    useEffect(() => {
        async function loadMessages() {
            try {
                const payload = await getInReviewMessages(axiosInstance, user.currentProperty?.code ?? "");
                setMessages(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } 

            setIsLoading(false);
        }

        loadMessages();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingMessages]);

    useEffect(() => {
        setSelectedMessageId("");
        setSelectedMessage(getInitialMessageState());
        setSelectedPreviewMessage(getInitialMessageState());
    }, [user.currentProperty?.code]);

    async function handleApproveClick(event: React.MouseEvent<HTMLButtonElement>) {
        return approve(selectedMessage);
    }

    async function approve(message: MessageModel) {
        try {            
            await approveMessage(axiosInstance, user.currentProperty?.code ?? "", message);    
            setGridRefresh(!gridRefresh);
            props.onGridRefresh();
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorUpdatingMessage.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        } 

        if (message.type === "Image") {
            try {
               if (graphicManagerRef.current) {
                   await graphicManagerRef.current.saveGraphics(message.id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
               }
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }
        else if (message.type === "Video") {
            try {
                if (videoManagerRef.current) {                    
                    await videoManagerRef.current.saveVideos(message.id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
                }
            } catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }

        setDetailsDialogOpen(false);
        setSelectedMessageId("");
        setSelectedMessage(getInitialMessageState());
        setSelectedPreviewMessage(getInitialMessageState());
        setErrors(initialErrorState);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.messageSentSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleEditClick(event: React.MouseEvent<HTMLButtonElement>) {            
        setDetailsDialogOpen(true);
    }

    function handleDeleteClick(event: React.MouseEvent<HTMLButtonElement>) {        
        alertDialog({
            title: strings.deleteMessageAlertTitle,
            message: strings.deleteMessageAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedMessage(selectedMessageId);
        });
    }

    const handleLanguageChange = (event: SelectChangeEvent) => {
        setSelectedLanguage(event.target.value as string);
    };

    async function deleteSelectedMessage(id: string) {
        try {
            await deleteMessage(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingMessage.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.messageDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        props.onGridRefresh();
        setSelectedMessageId("");
        setSelectedMessage(getInitialMessageState());
        setSelectedPreviewMessage(getInitialMessageState());
    }

    function handleSelectionModelChange(ids: GridSelectionModel) {
        setSelectedLanguage("en"); // TODO: default language
        
        var id = ids[0] as string;

        if (id) {
            setSelectedMessageId(id);

            var message = messages?.find(m => m.id === id);
            if (message) {
                setSelectedMessage(message);
                setSelectedPreviewMessage(message);
            }
        }
    }

    function getIcon(type: string) {
        switch (type) {            
            case "Image":
                return (<ImageIcon fontSize="small" />);
            case "Video":
                return (<VideocamIcon fontSize="small" />);
            case "Text":
            default:
                return (<RttIcon fontSize="small" />);
        }
    }

    function buildSubjectField(row: MessageModel) {
        return (
            <>
                <span style={{ marginRight: theme.spacing(1) }}>{getIcon(row.type)}</span>
                <span>{row.subject?.en}</span>
            </>
        );
    }

    const gridColumns: GridColumns = [        
        { field: "from", headerName: strings.from, valueGetter: (params) => params.row.from.en, flex: 1 },
        { field: "to", headerName: strings.to, valueGetter: (params) => formattedSelectorValues(selectorIdsToValues(params.row.toData), strings), flex: 3 },
        { field: "subject", headerName: strings.subject, renderCell: (params) => buildSubjectField(params.row), flex: 3 },       
    ];

    function handleMessageChange(message: MessageModel) {
        setSelectedMessage(message);
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {    
        setSelectedMessage(selectedPreviewMessage);
        setErrors(initialErrorState);   
        setDetailsDialogOpen(false);        
    }   

    async function handleSendClick(event: React.MouseEvent<HTMLButtonElement>) {     
        setSelectedPreviewMessage(selectedMessage);
        return approve(selectedMessage);                
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000);
    }

    function buildImageUrl(messageId: string) {
        return `${process.env.REACT_APP_MANAGER_API_URL}/images/${user.currentProperty?.code}/message/${messageId}/main?nocache=${generateRandomNumber()}&access_token=${auth.userData?.access_token}`;
    }

    function buildVideoUrl(messageId: string) {
        return `${process.env.REACT_APP_MANAGER_API_URL}/videos/${user.currentProperty?.code}/message/${messageId}/main?nocache=${generateRandomNumber()}&access_token=${auth.userData?.access_token}`;
    }

    const videoStyle = {    
        border: "solid",
        borderWidth: 1,
        borderColor: theme.palette.secondary.main
    } as const;

    function onNotFoundError(e: React.SyntheticEvent<HTMLVideoElement | HTMLSourceElement, Event>) {
        setNotify({
            isOpen: true,
            message: strings.videoNotFound,
            msgType: "error",
        }); 
    }

    function onVideoLoad(e: React.SyntheticEvent<HTMLVideoElement, Event>) {
    }

    interface MessageDetailsProps {
        message: MessageModel;
    }

    const MessageDetails = (props: MessageDetailsProps) => {
        switch (props.message.type) {
            case "Image":
                return (<img src={buildImageUrl(props.message.id)} alt="" style={{ width: "100%" }} />);
            case "Video":
                return (
                    <video 
                        preload="metadata" 
                        controls 
                        id="message-video"
                        style={videoStyle}                        
                        width="100%"
                        onError={(e) => onNotFoundError(e)}
                        onCanPlay={(e) => onVideoLoad(e)}
                    >
                        <source 
                            src={buildVideoUrl(props.message.id)}
                            onError={(e) => onNotFoundError(e)} />
                    </video>
                );
            case "Text":
            default:
                return (<div dangerouslySetInnerHTML={{__html: props.message.text?.[selectedLanguage] ?? props.message.text?.en ?? ""}} />);
        }
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>                      
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataGrid
                            permissionKey="content_messaging"
                            rows={messages}
                            columns={gridColumns}
                            onSelectionModelChange={handleSelectionModelChange}    
                            showAddButton={false}
                        />              
                    </Grid>
                    <Grid item xs={6}>   
                        {selectedPreviewMessage.id !== "" &&
                            <>                                
                                <Grid container justifyContent="right" sx={{ paddingBottom: 1 }}>
                                    {checkAccess("content_messaging_review", Access.Delete, true) &&
                                        <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={handleDeleteClick}>
                                            {strings.delete}
                                        </Button>
                                    }
                                    <Spacer />
                                    {checkAccess("content_messaging_review", Access.Update, true) &&
                                        <>
                                            <Button variant="contained" startIcon={<EditIcon />} onClick={handleEditClick}>
                                                {strings.edit}
                                            </Button>
                                            <Spacer />
                                            <Button variant="contained" startIcon={<CheckIcon />} onClick={handleApproveClick}>
                                                {strings.approve}
                                            </Button>
                                        </>
                                    }
                                </Grid>                                
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">{strings.language}</InputLabel>
                                    <Select
                                        aria-labelledby={"select-label-langauge"}
                                        label="Language"
                                        value={selectedLanguage}
                                        onChange={handleLanguageChange}
                                        variant="outlined"
                                    >                                    
                                        {mapLanguagesToKeyValues(user.currentProperty?.languages ?? []).map((kvp, index) => (
                                            <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText color="error"> </FormHelperText>
                                </FormControl>
                                <Grid container>                            
                                    <Typography variant="h3" gutterBottom component="div">
                                        {selectedPreviewMessage.subject?.[selectedLanguage] ?? selectedPreviewMessage.subject?.en ?? ""}
                                    </Typography>                              
                                    {selectedPreviewMessage.isHighImportance &&
                                        <PriorityHighIcon color="error" />
                                    }
                                </Grid>
                                <Typography variant="h6" gutterBottom component="div">
                                    {strings.from}: {selectedPreviewMessage.from?.[selectedLanguage] ?? selectedPreviewMessage.from?.en ?? ""}
                                </Typography>
                                <Typography variant="h6" gutterBottom component="div">
                                    {strings.to} {selectedPreviewMessage.toData && formattedSelectorValues(selectorIdsToValues(selectedPreviewMessage.toData), strings)}
                                </Typography>                          
                                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                                <div>
                                    <MessageDetails message={selectedPreviewMessage} />
                                </div>
                            </>
                        }
                    </Grid>
                </Grid>

                <Message 
                    open={detailsDialogOpen}
                    type={selectedMessage.type}
                    message={selectedMessage} 
                    graphicManagerRef={graphicManagerRef}
                    videoManagerRef={videoManagerRef}
                    reviewing={true}
                    onChange={handleMessageChange}
                    onCancelClick={handleCancelClick}
                    onSendClick={handleSendClick}
                    sendEnabled={true}
                />

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }
}

export default InReview;