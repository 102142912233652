import CheckIcon from '@mui/icons-material/Check';
import SendIcon from '@mui/icons-material/Send';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useRef, useState } from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Message as MessageModel, getInitialMessageState } from "../../../models/modules/messaging/Message";
import { MessageType } from "../../../models/modules/messaging/MessageType";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import Checkbox from "../../common/details/Checkbox";
import DetailsDialog from '../../common/details/DetailsDialog';
import GuestSelector, { GuestSelectorRefObject } from "../../common/GuestSelector";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import { GuestSelectorData } from "../../../models/common/GuestSelectorData";
import { useUser } from "../../../providers/UserProvider";
import { Access } from "../../../models/configuration/security/Permission";
import VideoManager, { VideoManagerRefObject } from '../../common/video/VideoManager';
import { VideoModel } from "../../../models/common/VideoModel";
import { deleteVideos } from "../../../api/videoApi";

interface ValidationErrors {
    from: string;
    toData: string;
    sendAt: string;
    subject: string;
    text: string;
}

interface MessageProps {
    open: boolean;
    type: MessageType;
    message: MessageModel;
    templateId?: string | null; // This is only used to pull the graphic when we start with a template
    graphicManagerRef: React.RefObject<GraphicManagerRefObject>;
    videoManagerRef: React.RefObject<VideoManagerRefObject>;
    reviewing?: boolean;
    onChange?: (message: MessageModel) => void;
    onCancelClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSendClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<boolean>;
    sendEnabled?: boolean;
    readOnly?: boolean;
}

const Message = (props: MessageProps) => {
    const initialErrorState: ValidationErrors = {
        from: "",
        toData: "",      
        sendAt: "",
        subject: "",
        text: ""
    }
        
    const [tabValue, setTabValue] = useState(0);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);    
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const { user, checkAccess } = useUser();
    const selectorRef = React.useRef<GuestSelectorRefObject>(null);    

    React.useEffect(() => {
        if (!props.open) {
            setTabValue(0);
        }
    }, [props.open]);

    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        setTabValue(newValue);
    }   

    function handleFromChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        var message = {
            ...props.message,
            from: {
                ...props.message.from,
                [event.target.language]: event.target.value,
            },
        }

        if (props.onChange) {
            props.onChange(message);
        }
    }

    function handleSendAtChange(newValue: Date | null) {
        if (newValue) {
            newValue.setSeconds(0);
        }

        var message = {
            ...props.message,
            sendAt: newValue
        }

        if (props.onChange) {
            props.onChange(message);
        }
    }

    function handleFilterAccept(data: GuestSelectorData) {
        var message = {
            ...props.message,
            toData: data
        }

        if (props.onChange) {
            props.onChange(message);
        }
    }

    function handleSubjectChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        var message = {
            ...props.message,
            subject: {
                ...props.message.subject,
                [event.target.language]: event.target.value,
            }
        }

        if (props.onChange) {
            props.onChange(message);
        }
    }

    function handleIsHighImportanceChange(event: React.ChangeEvent<HTMLInputElement>) {
        var message = {
            ...props.message,
            isHighImportance: event.target.checked
        }

        if (props.onChange) {
            props.onChange(message);
        }
     }

    function handleTextChange(content: string, language: string) {
        var message = {
            ...props.message,
            text: {
                ...props.message.text,
                [language]: content,
            },
        }

        if (props.onChange) {
            props.onChange(message);
        }
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {        
        setErrors(initialErrorState);
        props.onCancelClick(event);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        return props.onSendClick(event);
    }

    function handleValidate() {
        var valid = true;
        
        var validators = [
            { property: "from.en", type: ValidationType.Required, message: strings.validationMessageFrom },            
            { property: "subject.en", type: ValidationType.Required, message: strings.validationMessageSubject },
            { property: "sendAt", type: ValidationType.Required, message: strings.validationMessageSendAt },
            { property: "toData", type: ValidationType.Custom, custom: () => {
               return selectorRef?.current?.validateSelection() ?? false;
            }, message: strings.validationMessageScheduleTo }
        ]

        if (props.type === "Text") {
            validators.push({ property: "text.en", type: ValidationType.Required, message: strings.validationMessageSubject });
        }

        var errors = validate<MessageModel, ValidationErrors>(validators, props.message);

        if (errors) {
            setErrors(errors);
            valid = false;
        }      
        
        if (props.type === "Image") {
            const imageIsValid = props.graphicManagerRef.current?.validate() ?? false;
            valid = valid && imageIsValid;
        }
        else if (props.type === "Video") {
            const videoIsValid = props.videoManagerRef.current?.validate() ?? false;
            valid = valid && videoIsValid;
        }

        return valid;
    }    

    function getTypeComponents(type: string) {
        switch (type) {            
            case "Image":
                return (
                    <GraphicManager
                        propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}  
                        itemType="message"
                        itemKey={props.templateId ? props.templateId : props.message.id}
                        imageKeys={["main"]}                            
                        ref={props.graphicManagerRef}
                        disabled={props.readOnly}
                    />);
            case "Video":
                return (
                    <VideoManager
                        propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                        itemType="message"
                        itemKey={props.templateId ? props.templateId : props.message.id}
                        videoKeys={["main"]}                            
                        ref={props.videoManagerRef}
                        disabled={props.readOnly}
                    />);
            case "Text":
            default:
                return (
                    <LanguageRichTextField
                        values={props.message.text ?? {}}                                   
                        id="item-description"
                        onChange={handleTextChange}                                
                        disabled={props.readOnly}
                        width="100%"
                        height={300}
                        customButtons={["insertGuestName", "insertRoomNumber"]}
                    />);
        }
    }

    function getPopupHeight(type: string) {
        switch (type) {            
            case "Image":
                return 602.5;
            case "Video":
                return 602.5;
            case "Text":
            default:
                return 720.38;
        }
    }

    function getPopupTitle() {
        if (props.reviewing) {
            return strings.approve;
        }
        else if (props.message.id && props.message.id !== "") {
            return strings.viewMessage; 
        }
        else {
            switch (props.type) {            
                case "Image":
                    return strings.newImageMessage;
                case "Video":
                    return strings.newVideoMessage;
                case "Text":
                default:
                    return strings.newTextMessage;
            }
        }
    }
    
    function getSendButtonLabel() {
        if (props.reviewing) {
            return strings.approve
        }
        else if (checkAccess("content_messaging_review", Access.Read, true)) {
            return strings.submitForApproval;
        }
        else {
            return strings.send;
        }
    }

    return (
        <DetailsDialog
            permissionKey="content_messaging"
            adding={true}
            open={props.open}
            title={getPopupTitle()}
            onValidateForm={handleValidate}
            onCancelClick={handleCancelClick}
            saveButtonLabel={getSendButtonLabel()}
            saveButtonIcon={ props.reviewing ? <CheckIcon /> : <SendIcon />}
            onSaveClick={handleSaveClick}
            contentSize={{ width: 1200, height: getPopupHeight(props.type) }}
            saveEnabled={props.sendEnabled}
            readOnly={props.readOnly}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <LanguageForm>
                    <Grid container direction="column">
                        <Grid item sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(2) }}>                        
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="message-from"
                                label={strings.from}
                                values={props.message.from ?? {}}
                                width={800}
                                disabled={props.readOnly}
                                onChange={handleFromChange}
                                error={Boolean(errors.from)}
                                helperText={errors.from}
                            />
                            <Spacer />
                            <Grid container direction="row">
                                <GuestSelector
                                    id="message-to"
                                    label={strings.to}                                
                                    filterData={props.message.toData ?? {}}
                                    width={800}
                                    onFilterAccept={handleFilterAccept}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {}}      
                                    permissionKey="content_messaging"
                                    disabled={props.readOnly}
                                    error={Boolean(errors.toData)}
                                    helperText={errors.toData}
                                    ref={selectorRef}
                                />
                                <Spacer x={2} />
                                <DateTimePicker
                                    label={strings.sendDate}
                                    value={props.message.sendAt ?? null}
                                    disabled={props.readOnly}
                                    minDateTime={props.message.sendAt ?? new Date()}
                                    onChange={handleSendAtChange}
                                    renderInput={(params) => <TextField {...params} error={Boolean(errors.sendAt)} helperText={errors.sendAt} />}
                                />
                            </Grid>                            
                            <Spacer />
                            <Grid container direction="row" alignContent="top">
                                <LanguageTextField
                                    id="message-subject"
                                    label={strings.subject}
                                    values={props.message.subject ?? {}}
                                    width={800}
                                    disabled={props.readOnly}
                                    onChange={handleSubjectChange}
                                    error={Boolean(errors.subject)}
                                    helperText={errors.subject}
                                />
                                <Spacer x={2} />                                                                
                                <Checkbox 
                                    checked={props.message.isHighImportance} 
                                    disabled={props.readOnly}
                                    onChange={handleIsHighImportanceChange} 
                                    label={strings.highImportance} 
                                    height={56} />
                            </Grid>           
                        </Grid>
                        <Grid item sx={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2), paddingTop: theme.spacing(1) }}>
                            {getTypeComponents(props.type)}
                        </Grid>
                    </Grid>
                </LanguageForm>
            </LocalizationProvider>
        </DetailsDialog>
    );
}

export default Message;