import PeopleIcon from '@mui/icons-material/People';
import InputAdornment from '@mui/material/InputAdornment';
import MuiIconButton from '@mui/material/IconButton';
import { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useTheme } from '@mui/material/styles';
import React, { Ref, useImperativeHandle } from "react";
import TextField from "./details/TextField";
import { useLocalizedStrings } from "../../localization/LocalizedStringsProvider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { default as DetailsDialog, useDialog } from './details/DetailsDialog';
import Spacer from "../common/Spacer";
import TransferList from "../common/details/TransferList";
import Box from "@mui/material/Box";
import Typography from '@material-ui/core/Typography';
import { Field as ValidationField, Type as ValidationType, validate } from "../../utilities/Validator";
import { useSelectedLanguage } from './language/LanguageForm';
import { GuestSelectorData } from '../../models/common/GuestSelectorData';
import useGuestSelectorData from '../../hooks/useGuestSelectorData';


interface GuestSelectorErrors {
    bookingId: string;
    externalKey: string;
}

const initialFilterState: GuestSelectorData = {
    roomIds: undefined,
    externalKey: undefined,
    bookingId: undefined,
    loyaltyLevelIds: undefined,
    roomClassIds: undefined,
}

const initialFilterErrorState: GuestSelectorErrors = {
    bookingId: "",
    externalKey: ""
}

export function parseData(data: GuestSelectorData | null, strings: any) {
    if (data === null) {
        return "";
    }

    if (data.roomIds) {
        return (data.roomIds.length > 1 ? `${strings.roomNumbers}: ` : `${strings.roomNumber}:`) + data.roomIds.join(", ");
    }
    else if (data.externalKey) {
        return `${strings.externalId}: ${data.externalKey}`
    }
    else if (data.bookingId) {
        return `${strings.bookingId}: ${data.bookingId}`
    }
    else if (data.loyaltyLevelIds) {
        return (data.loyaltyLevelIds.length > 1 ? `${strings.loyaltyLevels}: ` : `${strings.loyaltyLevel}:`) + data.loyaltyLevelIds.join(", ");
    }
    else if (data.roomClassIds) {
        return (data.roomClassIds.length > 1 ? `${strings.roomClasses}: ` : `${strings.roomClass}:`) + data.roomClassIds.join(", ");
    }
    else {
        return "";
    }
}

export interface GuestSelectorRefObject {
    validateSelection : () => boolean; 
}

export interface GuestSelectorProps {
    id: string;
    label: string;
    onFilterAccept?: (data: GuestSelectorData) => void;
    filterData?: GuestSelectorData;
    InputProps?: Partial<OutlinedInputProps>;
    width: string | number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    permissionKey?: string;
    disabled?: boolean;
    error?: boolean | undefined;
    helperText?: string | undefined;
}

const GuestSelector = React.forwardRef((props: GuestSelectorProps, ref: Ref<GuestSelectorRefObject>) => {
    const theme = useTheme();
    const strings = useLocalizedStrings();
    const [roomDialogOpen, setRoomDialogOpen] = React.useState(false);
    const [loyaltyDialogOpen, setLoyaltyDialogOpen] = React.useState(false);
    const [classDialogOpen, setClassDialogOpen] = React.useState(false);
    const [bookingDialogOpen, setBookingDialogOpen] = React.useState(false);
    const [externalDialogOpen, setExternalDialogOpen] = React.useState(false);
    const [guestFilterMenuAnchor, setGuestFilterMenuAnchor] = React.useState<null | HTMLElement>(null);
    const guestFilterMenuOpen = Boolean(guestFilterMenuAnchor);
    const [guestFilter, setGuestFilter] = React.useState<GuestSelectorData>(props.filterData || initialFilterState);
    const [guestFilterErrors, setGuestFilterErrors] = React.useState<GuestSelectorErrors>(initialFilterErrorState);
    const { selectedLanguage } = useSelectedLanguage();
    const filterRef = React.useRef<GuestSelectorData>(initialFilterState);
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const [text, setText] = React.useState("");

   // server data
   const { allRooms, allClasses, allLoyaltyLevels, loading, selectorIdsToValues, formattedSelectorValues } = useGuestSelectorData(selectedLanguage);

    React.useEffect(() => {
        if (!props?.filterData?.bookingId && !props?.filterData?.externalKey && !props?.filterData?.loyaltyLevelIds && !props?.filterData?.roomClassIds && !props?.filterData?.roomIds) {
            return;
        }

        setGuestFilter(props?.filterData || initialFilterState);
        
        if (props.filterData) {
            filterRef.current = props.filterData
        }
    }, [props?.filterData]); 

    // menus
    function handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGuestFilterMenuAnchor(event.currentTarget);
    }

    function handleRoomMenuClose() {
        setGuestFilterMenuAnchor(null);
    }

    function handleRoomMenuClick() {
        setGuestFilterMenuAnchor(null);
        setRoomDialogOpen(true);
    }

    function handleBookingIdMenuClick() {
        setGuestFilterMenuAnchor(null);
        setBookingDialogOpen(true);
    }

    function handleLoyaltyLevelMenuClick() {
        setGuestFilterMenuAnchor(null);
        setLoyaltyDialogOpen(true);
    }

    function handleRoomClassMenuClick() {
        setGuestFilterMenuAnchor(null);
        setClassDialogOpen(true);
    }

    function handleExternalIdMenuClick() {
        setGuestFilterMenuAnchor(null);
        setExternalDialogOpen(true);
    }

    // Rooms
    function handleRoomListChange(values: { key: string, value: string }[]) {
        setText("");
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            roomIds: values.map(({ key }) => key)
        }));
        setDirty(true);
    }

    function validateRoomFilter() {
        return true;
    }

    function handleRoomFilterCancelClick() {
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            roomIds: filterRef.current.roomIds
        }))
        setGuestFilterErrors(initialFilterErrorState);
        setRoomDialogOpen(false)
    }

    function updateRefs(filterType: 'Room' | 'Booking' | 'Loyalty' | 'Class' | 'External', value?: any) {
        filterRef.current = {
            roomIds: filterType === "Room" ? (value ?? guestFilter.roomIds) : undefined,
            bookingId: filterType === "Booking" ? (value ?? guestFilter.bookingId) : undefined,
            loyaltyLevelIds: filterType === "Loyalty" ? (value ?? guestFilter.loyaltyLevelIds) : undefined,
            roomClassIds: filterType === "Class" ? (value ?? guestFilter.roomClassIds) : undefined,
            externalKey: filterType === "External" ? (value ?? guestFilter.externalKey) : undefined
        }
    }

    function clearRefs() {
        filterRef.current = {
            roomIds: undefined,
            bookingId: undefined,
            loyaltyLevelIds: undefined,
            roomClassIds: undefined,
            externalKey: undefined
        }
    }

    async function handleRoomFilterOkClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGuestFilter((old: GuestSelectorData) => ({
            bookingId: undefined,
            externalKey: undefined,
            loyaltyLevelIds: undefined,
            roomClassIds: undefined,
            roomIds: old.roomIds
        }))
        
        setDirty(true);

        // todo: change later, had to make prop optional to leave messsage/schedule unchanged
        if (props?.onFilterAccept) {
            props.onFilterAccept({
                roomIds: guestFilter.roomIds?.map(v => v) ?? undefined
            });
        }

        updateRefs("Room");
        setGuestFilterErrors(initialFilterErrorState);
        setRoomDialogOpen(false);

        return true;
    }

    // Booking ID
    function handleBookingIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setText("");
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            bookingId: event.target.value
        }));        
    }

    function handleBookingFilterCancelClick() {
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            bookingId: filterRef.current.bookingId
        }));
        setGuestFilterErrors(initialFilterErrorState);
        setBookingDialogOpen(false);
    }

    async function handleBookingFilterOkClick(event: React.MouseEvent<HTMLButtonElement>) {
         setGuestFilter((old: GuestSelectorData) => ({
            bookingId: old.bookingId,
            externalKey: undefined,
            loyaltyLevelIds: undefined,
            roomClassIds: undefined,
            roomIds: undefined
        }));

        setDirty(true);

         // todo: change later, had to make prop optional to leave messsage/schedule unchanged
        if (props?.onFilterAccept) {        
            props.onFilterAccept({
                bookingId: guestFilter.bookingId ?? undefined,
            });
        }
        updateRefs("Booking");
        setGuestFilterErrors(initialFilterErrorState);
        setBookingDialogOpen(false);

        return true;
    }

    function validateBookingFilter() {
        const errors = validate<GuestSelectorData, GuestSelectorErrors>(
            [
                {
                    property: "bookingId",
                    type: ValidationType.Required,
                    message: strings.validationGuestSelectorBookingIdRequired
                }
            ], guestFilter
        );

        if (errors) {
            setGuestFilterErrors(errors);
            return false;
        }

        return true;
    }

    function mapRoomsToKeyValues(roomList: { id: string; number: string }[]) {
        return roomList.map(({ id, number }) => ({ key: id, value: number }))
    }

    function mapListToKeyValues(list: { id: string; name: string }[]) {
        return list.map(({ id, name }) => ({ key: id, value: name }))
    }

    // Room Classes
    function handleClassFilterCancelClick() {
        setText("");
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            roomClassIds: filterRef.current.roomClassIds
        }));
        setGuestFilterErrors(initialFilterErrorState);
        setClassDialogOpen(false);
    }

    async function handleClassFilterOkClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGuestFilter((old: GuestSelectorData) => ({
            bookingId: undefined,
            externalKey: undefined,
            loyaltyLevelIds: undefined,
            roomClassIds: old.roomClassIds,
            roomIds: undefined
        }));

        setDirty(true);

        if (props?.onFilterAccept) {
            props.onFilterAccept({
                roomClassIds: guestFilter.roomClassIds?.map(v => v) ?? undefined,
            });
        }

        updateRefs("Class");
        setGuestFilterErrors(initialFilterErrorState);
        setClassDialogOpen(false);

        return true;
    }

    function handleClassListChange(values: { key: string, value: string }[]) {
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            roomClassIds: values.map(({ key }) => key)
        }))
    }

    // Loyalty Levels
    function handleLoyaltyFilterCancelClick() {
        setText("");
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            loyaltyLevelIds: filterRef.current.loyaltyLevelIds
        }));
        setGuestFilterErrors(initialFilterErrorState);
        setLoyaltyDialogOpen(false);
    }

    async function handleLoyaltyFilterOkClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGuestFilterErrors(initialFilterErrorState);
        setGuestFilter((old: GuestSelectorData) => ({
            bookingId: undefined,
            externalKey: undefined,
            loyaltyLevelIds: old.loyaltyLevelIds,
            roomClassIds: undefined,
            roomIds: undefined
        }))

        setDirty(true);

         // todo: change later, had to make prop optional to leave messsage/schedule unchanged
        if (props?.onFilterAccept) {
            props.onFilterAccept({
                loyaltyLevelIds: guestFilter.loyaltyLevelIds?.map(v => v) ?? undefined,
            })
        }

        updateRefs("Loyalty");
        setLoyaltyDialogOpen(false);

        return true;
    }

    function handleLoyaltyListChange(values: { key: string, value: string }[]) {
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            loyaltyLevelIds: values.map(({ key }) => key)
        }))
    }

    // External ID 
    function handleExternalKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setText("");
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            externalKey: event.target.value
        }))
    }

    function handleExternalKeyFilterCancelClick() {
        setGuestFilter((old: GuestSelectorData) => ({
            ...old,
            externalKey: filterRef.current.externalKey
        }));
        setGuestFilterErrors(initialFilterErrorState);
        setExternalDialogOpen(false);
    }

    async function handleExternalKeyFilterOkClick(event: React.MouseEvent<HTMLButtonElement>) {
        setGuestFilterErrors(initialFilterErrorState);
        setGuestFilter((old: GuestSelectorData) => ({
            bookingId: undefined,
            externalKey: old.externalKey,
            loyaltyLevelIds: undefined,
            roomClassIds: undefined,
            roomIds: undefined
        }))

        setDirty(true);

         // todo: change later, had to make prop optional to leave messsage/schedule unchanged
        if (props?.onFilterAccept) {
            props.onFilterAccept({
                externalKey: guestFilter.externalKey ?? undefined,
            });
        }

        updateRefs("External");
        setExternalDialogOpen(false);

        return true;
    }

    function validateExternalKeyFilter() {
        const errors = validate<GuestSelectorData, GuestSelectorErrors>(
            [
                {
                    property: "externalKey",
                    type: ValidationType.Required,
                    message: strings.validationGuestSelectorExtKeyRequired
                }
            ], guestFilter
        );

        if (errors) {
            setGuestFilterErrors(errors);
            return false;
        }

        return true;
    }

    useImperativeHandle(ref, () => ({validateSelection}))

    function validateSelection(): boolean {
        if (text !== "") {
            return validateAndParseText();
        }

        if (guestFilter?.roomIds && guestFilter?.roomIds.length > 0) return true; 

        if (guestFilter?.loyaltyLevelIds && guestFilter?.loyaltyLevelIds.length > 0) return true; 
        
        if (guestFilter?.roomClassIds && guestFilter?.roomClassIds.length > 0) return true; 
 
        if (guestFilter?.bookingId && guestFilter?.bookingId.trim().length > 1) return true; 
        
        if (guestFilter?.externalKey && guestFilter?.externalKey.trim().length > 1) return true; 

        return false;
    }

    function handleFilterTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        var value = event.target.value;
        setText(value);    
        
        if (value === "") {
            clearRefs();
        }
    }

    function validateAndParseText() {
        if (text.length) {
            const value = text.toLowerCase();

            if ((value.startsWith("room class") || value.startsWith("room") || value.startsWith("loyalty level") || value.startsWith("booking id") || value.startsWith("external id")) && value.indexOf(":") !== -1 && value.indexOf(":") < value.length) {
                const split = value.split(":");

                if (value.startsWith("room class")) {                    
                    const ids = allClasses.filter(x => split[1].split(",").map(y => y.trim()).includes(x.name.toLowerCase())).map(z => z.id);
                    setGuestFilter((old: GuestSelectorData) => ({
                        bookingId: undefined,
                        externalKey: undefined,
                        loyaltyLevelIds: undefined,
                        roomClassIds: ids,
                        roomIds: undefined
                    }));

                    if (ids.length === 0) {                        
                        return false;
                    }

                    updateRefs("Class", ids);
                }
                else if (value.startsWith("room")) {                    
                    const ids = allRooms.filter(x => split[1].split(",").map(y => y.trim()).includes(x.number.toLowerCase())).map(z => z.id);                    
                    setGuestFilter((old: GuestSelectorData) => ({
                        bookingId: undefined,
                        externalKey: undefined,
                        loyaltyLevelIds: undefined,
                        roomClassIds: undefined,
                        roomIds: ids
                    }));                   

                    updateRefs("Room", ids);

                    if (ids.length === 0) {                        
                        return false;
                    }
                }
                else if (value.startsWith("loyalty level")) {
                    const ids = allLoyaltyLevels.filter(x => split[1].split(",").map(y => y.trim()).includes(x.name.toLowerCase())).map(z => z.id);                    
                    setGuestFilter((old: GuestSelectorData) => ({
                        bookingId: undefined,
                        externalKey: undefined,
                        loyaltyLevelIds: ids,
                        roomClassIds: undefined,
                        roomIds: undefined
                    }));

                    updateRefs("Loyalty", ids);

                    if (ids.length === 0) {                        
                        return false;
                    }
                }
                else if (value.startsWith("booking id")) {
                    const val = split[1].trim();
                    setGuestFilter((old: GuestSelectorData) => ({
                        bookingId: val,
                        externalKey: undefined,
                        loyaltyLevelIds: undefined,
                        roomClassIds: undefined,
                        roomIds: undefined
                    }));

                    updateRefs("Booking", val);

                    if (val === "") {
                        return false;
                    }
                }
                else if (value.startsWith("external id")) {
                    const val = split[1].trim();                    
                    setGuestFilter((old: GuestSelectorData) => ({
                        bookingId: undefined,
                        externalKey: val,
                        loyaltyLevelIds: undefined,
                        roomClassIds: undefined,
                        roomIds: undefined
                    }));

                    updateRefs("External", val);

                    if (val === "") {
                        return false;
                    }
                }

                setText("");                
                return true;
            }        
        }

        clearRefs();
        return false;
    }

    function getToValue() {
        if (loading) {
            return strings.loading;
        }
        else if (text !== "") {
            return text;
        }
        else {
            return formattedSelectorValues(selectorIdsToValues(filterRef.current), strings);
        }
    }

    return (
        <>
            <Menu
                id="guestFilterMenu"
                MenuListProps={{
                    'aria-labelledby': 'guestFilter',
                }}
                anchorEl={guestFilterMenuAnchor}
                open={guestFilterMenuOpen}
                onClose={handleRoomMenuClose}
            >
                <MenuItem onClick={handleRoomMenuClick}>{strings.guestSelectorMenuRoomsTitle}</MenuItem>
                <MenuItem onClick={handleBookingIdMenuClick}>{strings.guestSelectorMenuBookingTitle}</MenuItem>
                <MenuItem onClick={handleLoyaltyLevelMenuClick}>{strings.guestSelectorMenuLoyaltyTitle}</MenuItem>
                <MenuItem onClick={handleRoomClassMenuClick}>{strings.guestSelectorMenuClassTitle}</MenuItem>
                <MenuItem onClick={handleExternalIdMenuClick}>{strings.guestSelectorMenuExternalIdTitle}</MenuItem>
            </Menu>
            <TextField
                id={props.id}
                label={props.label}
                value={getToValue()}
                placeholder={strings.guestSelectorPlaceholder}
                width={props.width}
                //onChange={props.onChange}
                onChange={handleFilterTextChange}
                error={props.error}
                helperText={props.helperText}
                disabled={loading || props.disabled}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <MuiIconButton 
                            onClick={handleButtonClick} 
                            edge="end" 
                            color={props.error ? "error" : "primary"}
                            disabled={loading || props.disabled}
                        >
                            <PeopleIcon />
                        </MuiIconButton>
                    </InputAdornment>,
                }}
            />
            <DetailsDialog
                permissionKey={permissionKey}
                adding={false}
                open={roomDialogOpen}
                title={strings.roomNumber}
                contentSize={{ width: 600, height: 475 }}
                onCancelClick={handleRoomFilterCancelClick}
                onSaveClick={handleRoomFilterOkClick}
                onValidateForm={validateRoomFilter}
                saveButtonLabel={strings.okButtonTitle}
                hideAudit
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <Spacer />
                    <Typography variant="h6" align="center">{strings.guestSelectorRoomDialogSubtitle}</Typography>
                    <Spacer />
                    <TransferList
                        id="room-transfer-list"
                        leftKeyValues={mapRoomsToKeyValues(allRooms)}
                        rightKeys={guestFilter.roomIds}
                        leftTitle={strings.guestSelectorTransferListRoomsLeftSide}
                        rightTitle={strings.guestSelectorTransferListRoomsRightSide} 
                        onChangeHandler={handleRoomListChange}
                        height={375}
                        permissionKey={permissionKey}
                        includeSearch={true}
                        virtualized={true}
                    />
                </Box>
            </DetailsDialog>
            <DetailsDialog
                permissionKey={permissionKey}
                adding={false}
                open={bookingDialogOpen}
                title={strings.guestSelectorBookingDialogTitle}
                contentSize={{ width: 300, height: 102.91 }}
                onCancelClick={handleBookingFilterCancelClick}
                onSaveClick={handleBookingFilterOkClick}
                onValidateForm={validateBookingFilter}
                saveButtonLabel={strings.okButtonTitle}
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <TextField
                        id="booking-id"
                        label={strings.bookingId}
                        value={guestFilter.bookingId ?? ""}
                        width={250}
                        onChange={handleBookingIdChange}
                        error={Boolean(guestFilterErrors.bookingId)}
                        helperText={guestFilterErrors.bookingId}
                    />
                </Box>
            </DetailsDialog>
            <DetailsDialog
                permissionKey={permissionKey}
                adding={false}
                open={classDialogOpen}
                title={strings.roomClass}
                contentSize={{ width: 600, height: 365 }}
                onCancelClick={handleClassFilterCancelClick}
                onSaveClick={handleClassFilterOkClick}
                saveButtonLabel={strings.okButtonTitle}
                hideAudit
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <Spacer />
                    <TransferList
                        id="class-transfer-list"
                        leftKeyValues={mapListToKeyValues(allClasses)}
                        rightKeys={guestFilter.roomClassIds}
                        leftTitle={strings.guestSelectorTransferListClassesLeftSide}
                        rightTitle={strings.guestSelectorTransferListClassesRightSide}
                        onChangeHandler={handleClassListChange}
                        height={315}
                        permissionKey={permissionKey}
                    />
                </Box>
            </DetailsDialog>
            <DetailsDialog
                permissionKey={permissionKey}
                adding={false}
                open={loyaltyDialogOpen}
                title={strings.loyaltyLevel}
                contentSize={{ width: 600, height: 365 }}
                onCancelClick={handleLoyaltyFilterCancelClick}
                onSaveClick={handleLoyaltyFilterOkClick}
                saveButtonLabel={strings.okButtonTitle}
                hideAudit
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <Spacer />
                    <TransferList
                        id="loyalty-transfer-list"
                        leftKeyValues={mapListToKeyValues(allLoyaltyLevels)}
                        rightKeys={guestFilter.loyaltyLevelIds}
                        leftTitle={strings.guestSelectorTransferListLoyaltyLeftSide}
                        rightTitle={strings.guestSelectorTransferListLoyaltyRightSide}
                        onChangeHandler={handleLoyaltyListChange}
                        height={315}
                        permissionKey={permissionKey}
                    />
                </Box>
            </DetailsDialog>
            <DetailsDialog
                permissionKey={permissionKey}
                adding={false}
                open={externalDialogOpen}
                title={strings.externalId}
                contentSize={{ width: 300, height: 102.91 }}
                onCancelClick={handleExternalKeyFilterCancelClick}
                onSaveClick={handleExternalKeyFilterOkClick}
                onValidateForm={validateExternalKeyFilter}
                saveButtonLabel={strings.okButtonTitle}
                hideAudit
            >
                <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                    <TextField
                        id="external-key"
                        label={strings.externalId}
                        value={guestFilter.externalKey ?? ""}
                        width={250}
                        onChange={handleExternalKeyChange}
                        error={Boolean(guestFilterErrors.externalKey)}
                        helperText={guestFilterErrors.externalKey}
                    />
                </Box>
            </DetailsDialog>
        </>
    );
});

export default GuestSelector;
