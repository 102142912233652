import * as React from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDialog } from "./DetailsDialog";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useTheme } from "@mui/material/styles";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import TextField from "@mui/material/TextField";
import { FixedSizeList as VirtualizedList } from "react-window";
import Spacer from "../Spacer";

function not(a: { key: string; value: string }[], b: { key: string; value: string }[]) {
    return a.filter((element) => b.findIndex((innerElement) => element.key === innerElement.key) === -1);
}

function intersection(a: { key: string; value: string }[], b: { key: string; value: string }[]) {
    return a.filter((element) => b.findIndex((innerElement) => element.key === innerElement.key) !== -1);
}

export type TransferListChangeHandler = (
    values: { key: string; value: string}[]
) => any;

export type TransferListDeltaHandler = (
    values: { key: string; value: string, action: 'add' | 'remove'}[]
) => any;

export interface TransferListProps {
    id?: string;
    leftKeyValues: { key: string; value: string }[];
    rightKeyValues?: { key: string; value: string }[];
    rightKeys?: string[];
    leftTitle?: string;
    rightTitle?: string;
    onChangeHandler: TransferListChangeHandler;
    onDeltaChangeHandler?: TransferListDeltaHandler;
    disabled?: boolean;
    height?: number;
    permissionKey?: string;
    includeSearch?: boolean;
    virtualized? : boolean;
    error?: boolean;
    helperText?: React.ReactNode;
}

const TransferList = (props: TransferListProps) => {
    const rightKeyValues = getRightKeyValues();
    const [checked, setChecked] = React.useState<{ key: string; value: string }[]>([]);
    const [left, setLeft] = React.useState<{ key: string; value: string }[]>(not(props.leftKeyValues, rightKeyValues));
    const [right, setRight] = React.useState<{ key: string; value: string }[]>(rightKeyValues);
    const [initialRight,] = React.useState<{ key: string; value: string }[]>(rightKeyValues);
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const strings = useLocalizedStrings();
    const theme = useTheme();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));
    const [initialLeft,] = React.useState<{ key: string; value: string }[]>(not(props.leftKeyValues, rightKeyValues));
    const [searchState, setSearchState] = React.useState("");

    function getRightKeyValues(): { key: string; value: string }[] {
        if (props.rightKeyValues) {
            return props.rightKeyValues;
        }
        else if (props.rightKeys) {
            var rightKeyValues: { key: string; value: string }[] = [];
            props.rightKeys.forEach((element) => {
                var keyValue = props.leftKeyValues.find(kv => kv.key === element);
                if (keyValue) {
                    rightKeyValues.push(keyValue);
                }
            });
            return rightKeyValues;
        }
        else {
            return [];
        }
    }

    React.useEffect(() => {
        const results = searchState 
            ? initialLeft.filter(l => l.value.includes(searchState || "" ))
            : initialLeft
        
        setLeft(not(results, right.concat(leftChecked)));
        
    }, [searchState]) 

    const handleToggle = (item: { key: string, value: string }) => () => {
        const currentIndex = checked.findIndex((element) => element.key == item.key);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        const newRight = right.concat(left);
        setRight(newRight);
        setLeft([]);
        setDirty(true);
        handleChange(newRight);
    };

    const handleCheckedRight = () => {
        const newRight = right.concat(leftChecked);
        const newLeft = not(left, leftChecked);
        setLeft(newLeft);
        setRight(newRight);
        setChecked(not(checked, leftChecked));
        setDirty(true);
        handleChange(newRight);
    };

    const handleCheckedLeft = () => {
        const newLeft = left.concat(rightChecked);
        const newRight = not(right, rightChecked);
        setLeft(newLeft);
        setRight(newRight);
        setChecked(not(checked, rightChecked));
        setDirty(true);
        handleChange(newRight);
    };

    const handleAllLeft = () => {
        const newLeft = left.concat(right);
        setLeft(newLeft);
        setRight([]);
        setDirty(true);
        handleChange([]);
    };

    const handleChange = (values: { key: string; value: string}[]) => {
        //// https://hustle.bizongo.in/simulate-react-on-change-on-controlled-components-baa336920e04
        //var event = new Event('change', { bubbles: true })t;
        ////ev2.simulated = true;

        // what's been added or removed from original assignments
        if (props.onDeltaChangeHandler) {
            props.onDeltaChangeHandler(getDeltaChanges(values));
        }
        
        // snapshot of rightside of list
        props.onChangeHandler(values); 
    }

    function getDeltaChanges(values: { key: string; value: string}[]) : {key: string; value: string; action: 'add' | 'remove';}[] {
        let adds: { key: string; value: string; action: 'add' | 'remove'; }[] =
            not(values, initialRight)
                .map((item) => ({ ...item, action: "add" }));
        
        let deletes: { key: string; value: string; action: 'add' | 'remove'; }[] =
            not(initialRight, values)
                .map((item) => ({ ...item, action: "remove" }));
   
        return [...adds, ...deletes]
    }

    function handleTextChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let currentVal = event.target.value ?? "";
        setSearchState(currentVal);
    }

    const customList = (items: { key: string, value: string }[], side: "left" | "right",  title?: string ) => (
        <Card>
            {props.includeSearch && <Spacer />}
            {props.includeSearch && side === "left" ? (
                <TextField  
                    variant="outlined"
                    onChange={handleTextChange}
                    size="small"
                    type="Search" 
                    label={strings.search} 
                    disabled={props.disabled || readOnly}
                    fullWidth
                >
                </TextField>
            ) : <CardHeader
                    sx={{
                        px: theme.spacing(1),
                        pt: theme.spacing(1),
                        pb: props.includeSearch && side === "left"
                            ? theme.spacing(2)
                            : theme.spacing(1)
                    }}
                    title={<Typography variant="subtitle2">{title}</Typography>}
                />
            }
            <Divider sx={{ display: side=== "left" && props.includeSearch ? "none" : "block"}} />
            {props.virtualized ? (
                <VirtualizedList
                    itemData={items}
                    itemCount={items.length}
                    itemSize={46}
                    overscanCount={5}
                    height={250}
                    width={"100%"}
                    disabled={props.disabled || readOnly}
                    style={{
                        width: "100%",
                        height: (props.height ?? 200) - 56.97,
                        backgroundColor: 'background.paper',
                        overflow: 'auto',
                    }}
                    {...props}
                >
                    {({ data, index, style, isScrolling}) => {
                        const labelId = `transfer-list-item-${index}-label`;
                        
                        return (
                            <ListItem
                                key={data[index].key}
                                role="listitem"
                                button
                                dense
                                onClick={handleToggle(data[index])}
                                disabled={props.disabled || readOnly}
                                style={style}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.findIndex(element => element.key === data[index].key) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": labelId
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={data[index].value} />
                            </ListItem>
                        )
                    }}
                </VirtualizedList>
            ) : (
                <List
                    dense
                    component="div"
                    role="list"                    
                    sx={{
                        width: "100%",
                        height: (props.height ?? 200) - 56.97,
                        bgcolor: 'background.paper',
                        overflow: 'auto',
                    }}
                >
                    {items.map((item: { key: string, value: string }) => {
                        const labelId = `transfer-list-item-${item.key}-label`;
                        
                        return (
                            <ListItem
                                key={item.key}
                                role="listitem"
                                button
                                onClick={handleToggle(item)}
                                disabled={props.disabled || readOnly}
                                sx={{height: "46px"}}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.findIndex((element) => element.key == item.key) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            "aria-labelledby": labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={item.value} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
        </Card>
    );

    return (
        <>
            <Stack direction="column">
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs>
                        {customList(left, "left", props.leftTitle || strings.transferListUnAssignedTitle)}
                    </Grid>
                    <Grid item style={{ width: 56, marginRight: theme.spacing(2) }}>
                        <Grid container direction="column" alignItems="center" spacing={1}>
                            <Grid item>
                                <Button
                                    sx={{ minWidth: 1 }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleAllRight}
                                    disabled={left.length === 0 || props.disabled || readOnly}
                                    aria-label="move all right"
                                >
                                    <KeyboardDoubleArrowRightIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    sx={{ minWidth: 1 }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0 || props.disabled || readOnly}
                                    aria-label="move selected right"
                                >
                                    <KeyboardArrowRightIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    sx={{ minWidth: 1 }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0 || props.disabled || readOnly}
                                    aria-label="move selected left"
                                >
                                    <KeyboardArrowLeftIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    sx={{ minWidth: 1 }}
                                    variant="outlined"
                                    fullWidth
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0 || props.disabled || readOnly}
                                    aria-label="move all left"
                                >
                                    <KeyboardDoubleArrowLeftIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>{customList(right, "right", props.rightTitle || strings.transferListAssignedTitle)}</Grid>
                </Grid>
                { props.error &&
                    <Typography sx={{ marginLeft: "14px", marginTop: "3px" }} variant="caption" color="error">{props.helperText}</Typography>
                }
            </Stack>
        </>
    );
}

export default TransferList;
