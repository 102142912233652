import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useEffect, useState} from "react";
import { listCustomerPermissionCategories } from "../../../api/permissionApi";
import { listRoles, getRole, createRole, updateRole, deleteRole } from "../../../api/security/roleApi";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { PermissionCategory } from "../../../models/configuration/security/Permission";
import { initialRoleState, Role as RoleModel, RolePermission } from "../../../models/configuration/security/Role";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import RolePermissions, { PermissionCheckboxValue } from "./RolePermissions";
import EditIcon from "@mui/icons-material/Edit";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridActionsCellItem, GridColumns, GridRowParams} from "@mui/x-data-grid";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { Theme, useTheme } from "@mui/material/styles";

interface ValidationErrors {    
    name: string;
}

const RoleList = () => {
    const initialErrorState: ValidationErrors = {        
        name: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState<RoleModel>(initialRoleState);
    const [tabValue, setTabValue] = useState<Number>(0);
    const [permissionCategories, setPermissionCategories] = useState<PermissionCategory[]>([]);
    const [roles, setRoles] = useState<RoleModel[]>([]);
    const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();

    async function apiListRoles() {
        try {
            const response = await listRoles(axiosInstance);
            setRoles(response);
        } 
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingRoles.replace("{{error}}", error.message),
                msgType: "error",
            });
        } 
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function apiListPermissions() {
            try {
                const response = await listCustomerPermissionCategories(axiosInstance);
                setPermissionCategories(response);
                apiListRoles();
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };                
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingRoles.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (permissionCategories.length === 0) {
            apiListPermissions();
        }
    }, [strings.errorRetrievingRoles]);

    function handleAddClick() {
        setTabValue(0);
        setSelectedRoleId(null);
        setSelectedRole(initialRoleState);
        setDetailsDialogOpen(true);
    }

    const handleEditRowClick = async (id: string) => {
        setTabValue(0);
        setSelectedRoleId(id);

        try {
            const response = (await getRole(axiosInstance, id) as RoleModel);
            setSelectedRole(response);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingRole.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleDeleteClick = (id: string) => {
        const name = roles.find(r => r.id === id)?.name ?? "";

        alertDialog({
            title: strings.deleteRoleAlertTitle,
            message: strings.deleteRoleAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedRole(id);
        });
    }
    
    const deleteSelectedRole = async (id: string) => {
        try {
            await deleteRole(axiosInstance, id);

            apiListRoles();

            setNotify({
                isOpen: true,
                message: strings.roleDeletedSuccessfully,
                msgType: "success",
            });
        } 
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };                
            setNotify({
                isOpen: true,
                message: strings.errorDeletingRole.replace("{{error}}", error.message),
                msgType: "error",
            });            
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedRole((prevState) => ({
            ...prevState,
            name: event.target.value,
        }));
    }

    const permissionCheckClickHandler = (event: any) => {
        let permissionCheckboxValue = JSON.parse(event.target.value) as PermissionCheckboxValue

        let newRole: RoleModel = {
            id: selectedRole.id,
            name: selectedRole.name,
            enabled: selectedRole.enabled,
            permissions: selectedRole.permissions,
            users: selectedRole.users
        }

        let index = 0;
        let foundPermission = false;
        while (index < newRole.permissions.length && !foundPermission) {
            let permission = newRole.permissions[index];
            if (permission.typeId === permissionCheckboxValue.type.id) {
                let newPermissionValue: number = newRole.permissions[index].access ^ permissionCheckboxValue.access
                newRole.permissions[index].access = newPermissionValue;
                foundPermission = true;
            }
            index++;
        }

        if (!foundPermission) {
            const permission: RolePermission = {
                typeId: permissionCheckboxValue.type.id,
                access: permissionCheckboxValue.access
            }

            newRole.permissions.push(permission)
        }

        setSelectedRole(newRole);
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);
        setDetailsDialogOpen(false);
        setTabValue(0);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialErrorState);

        try {
            setSelectedRole((prevState) => ({
                ...prevState,
                name: selectedRole.name.trim(),
            }));

            if (selectedRole.id === undefined) {
                await createRole(axiosInstance, selectedRole);
            } 
            else {
                await updateRole(axiosInstance, selectedRole);
            }

            apiListRoles();
            setDetailsDialogOpen(false);
            setTabValue(0);

            setNotify({
                isOpen: true,
                message: strings.roleSavedSuccessfully,
                msgType: "success",
            });
        } 
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (selectedRole.id === null
                    ? strings.errorAddingRole
                    : strings.errorUpdatingRole
                  ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        return true;
    }

    function handleValidate() {
        var errors = validate<RoleModel, ValidationErrors>([
            { property: "name", type: ValidationType.Required, message: strings.validationErrorRoleNameRequired },
        ], selectedRole);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    const gridColumns : GridColumns = [
      { field: "id", hide: true, filterable: false, hideable: false },
      { field: "name", headerName: strings.name, flex:1},
      {
        field: "actions",
        type: "actions",
        headerName: strings.gridActions,
        flex: 1,
        getActions: (params: GridRowParams) => [
            <DataGridEditButton 
                permissionKey="configuration_security" 
                rowId={params.id.toString()} 
                clickHandler={() => handleEditRowClick(params.id.toString())} 
            />,
            <DataGridDeleteButton
                permissionKey="configuration_security"
                rowId={params.id.toString()}
                clickHandler={() => handleDeleteClick(params.id.toString())}
            />,
        ],
      },
    ];
    
    const dialogTitle = selectedRoleId === null ? strings.securityManagerRoleTitleAdd : strings.securityManagerRoleTitleEdit.replace("{{name}}", selectedRole?.name ?? "");

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                <DataGrid
                    permissionKey="configuration_security"
                    rows={roles}
                    columns={gridColumns}
                    pageSize={10}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.roleAddButtonText}
                />
            
                <DetailsDialog
                    permissionKey="configuration_security"
                    adding={selectedRoleId === null}
                    open={detailsDialogOpen}
                    title={dialogTitle}
                    onValidateForm={handleValidate}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 600, height: 563 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                            <Tab label={strings.details} />
                            <Tab label={strings.permissionsTabLabel} />
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <TextField
                            id="role-name"
                            label={strings.roleNameLabel}
                            width={400}
                            value={selectedRole.name}
                            onChange={handleNameChange}
                            error={Boolean(errors.name)}
                            helperText={errors.name} />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={1} noPadding={true}>
                        <RolePermissions
                            role={selectedRole}
                            permissionCategories={permissionCategories}
                            onPermissionCheckClickHandler={permissionCheckClickHandler} />
                    </TabPanelKeepMounted>
                </DetailsDialog>
           
                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default RoleList;
