import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { GridColumns, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { createActivity, deleteActivity, getActivities, updateActivity } from "../../../api/activities/activityApi";
import { getTypes } from "../../../api/activities/typeApi";
import { deleteGraphics } from "../../../api/graphicsApi";
import { deleteVideos } from "../../../api/videoApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { VideoModel } from "../../../models/common/VideoModel";
import { Activity as ActivityModel, initialActivityState } from "../../../models/modules/activities/Activity";
import { mapTypesToKeyValues, Type as TypeModel } from "../../../models/modules/activities/Type";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import RoomClasses from "../../common/details/RoomClasses";
import TransferList from "../../common/details/TransferList";
import ValidateTab from "../../common/details/ValidateTab";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";
import Stack from "@mui/material/Stack";

interface ActivityValidationErrors {
    name: string;
    description: string;    
    types: string;
    roomClasses: string;
}

const ActivityList = () => {
    const initialActivityErrorState: ActivityValidationErrors = {
        name: "",
        description: "",
        types: "",
        roomClasses: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [activities, setActivities] = useState<ActivityModel[]>([]);
    const [types, setTypes] = useState<TypeModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);    
    const [tabValue, setTabValue] = useState<number>(0);
    const [selectedActivityId, setSelectedActivityId] = useState("");
    const [selectedActivity, setSelectedActivity] = useState<ActivityModel>(initialActivityState);    
    const [isLoading, setIsLoading] = useState(true);    
    const [errors, setErrors] = useState<ActivityValidationErrors>(initialActivityErrorState);    
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);        
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        

    useEffect(() => {
        async function loadActivities() {
            try {
                const payload = await getActivities(axiosInstance, user.currentProperty?.code ?? "");
                payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setActivities(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingActivities.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            try {
                const payload = await getTypes(axiosInstance, user.currentProperty?.code ?? "");
                payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setTypes(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTypes.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadActivities();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingActivities, strings.errorRetreivingTypes]);

    const dialogTitle = selectedActivityId === "" ? strings.addActivityTitle : strings.editActivityTitle.replace("{{name}}", selectedActivity?.name?.en ?? ""); // TODO: default language

    function handleEditRowClick(id: string) {        
        setSelectedActivityId(id);
        var activity = activities?.find(p => p.id === id);
        if (activity) {
            setSelectedActivity(activity);
            setDetailsDialogOpen(true);
        }
    }

    function handleAddClick() {
        setSelectedActivityId("");
        setSelectedActivity(initialActivityState);
        setDetailsDialogOpen(true);
    }

    async function deleteSelectedActivity(id: string) {
        try {
            const images: GraphicModel[] = [
                { imageKey: "main", url: "", fileData: null },
                { imageKey: "banner", url: "", fileData: null },
            ];

            const videos: VideoModel[] = [
                { videoKey: "main", url: "", fileData: null },
                { videoKey: "banner", url: "", fileData: null },
            ];

            await deleteGraphics(axiosInstance, "activityItem", selectedActivityId, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteVideos(axiosInstance, "activityItem", selectedActivityId, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteActivity(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingActivity.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.activityDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedActivityId("");
        setSelectedActivity(initialActivityState);
    }

    function handleDeleteClick(id: string) {
        const name = activities.find(p => p.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteActivityAlertTitle,
            message: strings.deleteActivityAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedActivity(id);
        });
    }
   
    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        setTabValue(newValue);        
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedActivity((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleDescriptionChange(content: string, language: string) {
        setSelectedActivity((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedActivity((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }
    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedActivity((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    function handleTypesListChange(values: { key: string; value: string }[]) {     
        // https://stackoverflow.com/questions/47755247/typescript-array-map-return-object
        interface T { id: string; name?: string | undefined }
        let t = values.map((val: any): T => ({
            id: val.key,
            name: ""
        }));

        setSelectedActivity((prevState) => ({
            ...prevState,
            types: t
        }));
    }                

    function handleRoomClassesChange(values: { id: string; name: string }[]) {
        setSelectedActivity((prevState) => ({
            ...prevState,
            roomClasses: values
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialActivityErrorState);
        setDetailsDialogOpen(false);        
        setTabValue(0);
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialActivityErrorState);

        var id = selectedActivityId;
        const isUpdate = selectedActivityId != "";
        try {
            if (isUpdate) {
                await updateActivity(axiosInstance, user.currentProperty?.code ?? "", selectedActivity);
            } else {
                id = await createActivity(axiosInstance, user.currentProperty?.code ?? "", selectedActivity);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingActivity
                    : strings.errorUpdatingActivity
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        try {
            if (graphicManagerRef.current) {
                await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        try {
            if (videoManagerRef.current) {
                await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setTabValue(0);
        setNotify({
            isOpen: true,
            message: strings.activitySavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateActivity() {
        var errors = validate<ActivityModel, ActivityValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationActivityName },
            { property: "description.en", type: ValidationType.Required, message: strings.validationActivityDescription },
            { property: "types", type: ValidationType.Required, message: strings.validationActivityTypes }            
        ], selectedActivity);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }
    
    const gridColumns: GridColumns = [        
        { field: "name", headerName: strings.name, valueGetter: (params) => params.row.name.en, flex: 4 },      
        { field: "enabled", headerName: strings.enabled, type: "boolean", valueGetter: (params) => params.row.enabled, flex: 1 },  
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (params) => params.row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_activities" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_activities"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>                                                
                <DataGrid
                    permissionKey="content_activities"
                    rows={activities}
                    columns={gridColumns}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.addActivityButton}                    
                />

                <DetailsDialog
                    permissionKey="content_activities"
                    open={detailsDialogOpen}
                    adding={selectedActivityId === ""}
                    title={dialogTitle}
                    onValidateForm={handleValidateActivity}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 879.5, height: 586.41 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <ValidateTab label={strings.details} errors={Boolean(errors.name) || Boolean(errors.description)} />
                            <Tab label={strings.graphics} />
                            <Tab label={strings.videos} />
                            <ValidateTab label={strings.activityTypes} errors={Boolean(errors.types)} />
                            <Tab label={strings.roomClasses} />                            
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.name}
                                values={selectedActivity.name ?? {}}
                                width={410}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                            <LanguageRichTextField
                                values={selectedActivity.description ?? {}}
                                label={strings.description}
                                id="item-description"
                                onChange={handleDescriptionChange}                                
                                width="100%"
                                error={Boolean(errors.description)}
                                helperText={errors.description}
                            />
                            <Spacer />
                            <Stack direction="row">
                                <Checkbox
                                    label={strings.enabled}
                                    checked={selectedActivity.enabled}
                                    onChange={handleEnabledChange}
                                />
                                <Spacer y={2} />                            
                                <Checkbox  
                                    label={strings.previewOnly}
                                    checked={selectedActivity.previewOnly ?? false}
                                    onChange={handlePreviewOnlyChange}                                                                
                                />
                            </Stack>
                        </LanguageForm>
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={1}>
                        <GraphicManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="activityItem"
                            itemKey={selectedActivity.id}
                            imageKeys={["main"]}                            
                            ref={graphicManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={2}>
                        <VideoManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="activityItem"
                            itemKey={selectedActivity.id}
                            videoKeys={["main"]}                            
                            ref={videoManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={3}>
                        <TransferList
                            id="types-transfer-list"
                            leftKeyValues={mapTypesToKeyValues(types)}
                            rightKeys={selectedActivity.types?.map(t => t.id)}
                            leftTitle={strings.availableTypes}                            
                            rightTitle={strings.selectedTypes} 
                            onChangeHandler={handleTypesListChange}
                            height={375}                            
                            virtualized={true}         
                            error={Boolean(errors.types)}
                            helperText={errors.types}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={4}>
                        <RoomClasses
                            assignedClasses={selectedActivity.roomClasses}
                            onChangeHandler={handleRoomClassesChange}                            
                            height={453.56}
                            error={Boolean(errors.roomClasses)}
                            helperText={errors.roomClasses}
                        />
                    </TabPanelKeepMounted>                    
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }    
};

export default ActivityList;
