import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { GridColumns, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import React from "react";
import { deleteGraphics } from "../../../api/graphicsApi";
import { deleteItem, getItems, toggleItemSoldOut } from "../../../api/roomservice/itemApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { Item as ItemModel, initialItemState } from "../../../models/modules/roomservice/Item";
import { useUser } from "../../../providers/UserProvider";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DataGridSoldOutButton from './DataGridSoldOutButton';
import Item from './Item';

const ItemList = () => {    
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [loading, setLoading] = React.useState(true);
    const [gridRefresh, setGridRefresh] = React.useState(false);
    const [items, setItems] = React.useState<ItemModel[]>([]);         
    const [selectedItemId, setSelectedItemId] = React.useState("");
    const [selectedItem, setSelectedItem] = React.useState<ItemModel>(initialItemState);   
    const [itemDialogOpen, setItemDialogOpen] = React.useState(false);    
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);            
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        

    React.useEffect(() => {
        const getInitialData = async() => {                        
            try {                                
                const itemResponse = await getItems(axiosInstance, user?.currentProperty?.code ?? "");
                itemResponse.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setItems(itemResponse);
            }
            catch(e: unknown) {
                const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };

                setNotify({
                    isOpen: true,
                    message: strings.roomServiceErrorRetreivingItems.replace("{{error}}", error.message),
                    msgType: "error",
                });

                return;
            }
            finally {
                setLoading(false);
            }
        }

        getInitialData();
    }, [user.currentProperty?.code, gridRefresh])
   
    function handleItemSelectionChange(itemSelectionModel: GridSelectionModel) {
        if (itemSelectionModel.length > 0) {
            const id = itemSelectionModel[0].toString();
            const find = items.find(i => i.id === id);
            if (find) {
                setSelectedItemId(id);
                setSelectedItem(find);
            }                       
        }
    }

    async function handleEditItemClick(id: string) {
        setSelectedItemId(id);
        setItemDialogOpen(true);
    }

    function handleAddClick() {                
        setItemDialogOpen(true);
    }

    function handleItemDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {        
        setSelectedItemId(id);

        var find = items.find(i => i.id === id);
        alertDialog({
            title: strings.roomServiceDeleteItemAlertTitle,
            message: strings.roomServiceDeleteItemAlertMessage2.replace("{{name}}", find?.name?.en ?? ""),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedItem(id); 
        });
    }

    async function deleteSelectedItem(id: string) {
        const images: GraphicModel[] = [{ imageKey: "main", url: "", fileData: null }];

        try {
            await deleteGraphics(axiosInstance, "roomServiceItem", id, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);            
            await deleteItem(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionItem.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.promotionItemDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedItemId("");
        setSelectedItem(initialItemState);
    }
   
    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemDialogOpen(false);
        setSelectedItemId("");
        setSelectedItem(initialItemState);
    } 

    function handleItemSaved(event: React.MouseEvent<HTMLButtonElement>) {
        setItemDialogOpen(false);
        setSelectedItemId("");
        setSelectedItem(initialItemState);
        setGridRefresh(!gridRefresh);
    } 

    const itemColumns: GridColumns = [
        { field: "name", headerName: strings.name, valueGetter: (params) => params.row.name.en, flex: 4 },
        { field: "sku", headerName: strings.sku, valueGetter: (params) => params.row.sku, flex: 2 },
        { field: "price", headerName: strings.price, valueGetter: (params) => params.row.pricePerUnit ? params.row.pricePerUnit.toLocaleString(undefined, { style: "currency", currency: "USD" }) : "", flex: 1 }, 
        { field: "soldOut", headerName: strings.available, type: "boolean", valueGetter: (params) => !params.row.soldOut, flex: 1 },
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (params) => params.row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={() => handleEditItemClick(params.id.toString())}
                />,
                <DataGridSoldOutButton
                    rowId={params.id.toString()}   
                    soldOut={items.find(i => i.id === params.id)?.soldOut ?? true}                    
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleSoldOutClick(params.id.toString(), event)}
                />,
                <DataGridDeleteButton
                    permissionKey="content_roomservice"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleItemDeleteClick(params.id.toString(), event)}
                />
            ],
        }
    ]

    async function handleSoldOutClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        try {            
            await toggleItemSoldOut(axiosInstance, user?.currentProperty?.code ?? "", id);
        }
        catch(e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.generalInfoErrorUpdatingItem.replace("{{error}}", error.message),
                msgType: "error",
            });
            
            return false;
        }

        setGridRefresh(!gridRefresh);
    }     

    if (loading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ margin: theme.spacing(2) }}>                                                    
                <DataGrid
                    permissionKey="content_roomservice"
                    rows={items}
                    columns={itemColumns}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.roomServiceAddItemButton}
                />
              
                <Item
                    open={itemDialogOpen}
                    itemId={selectedItemId}                                                
                    adding={selectedItemId === ""}
                    onCancelClick={handleItemCancelClick}
                    onSaved={handleItemSaved}
                    onNotification={(options: NotificationOptions) => { setNotify(options) }}
                />      

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }    
};

export default ItemList;
