import { DataGridWrapperProps, default as DataGrid } from "../datatable/DataGridWrapper";
import { useDialog } from "./DetailsDialog";
import { useEffect } from "react";

export interface DetailsDataGridProps extends DataGridWrapperProps {
    dirty: boolean
}

const DetailsGrid = (props: DetailsDataGridProps) => {
    const { dirty, setDirty } = useDialog();

    useEffect(() => {
        setDirty(props.dirty)
    }, [props.dirty]);

    return (
        <DataGrid
            permissionKey={props.permissionKey}
            rows={props.rows}
            onSelectionModelChange={props.onSelectionModelChange} 
            columns={props.columns}
            height={props.height}
            pageSize={props.pageSize}
            showAddButton={props.showAddButton}
            showAddButtonEditOnly={props.showAddButtonEditOnly}
            addButtonDisabled={props.addButtonDisabled}
            onAddButtonClick={props.onAddButtonClick}
            addButtonText={props.addButtonText}
        />
    )
}

export default DetailsGrid;