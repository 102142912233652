import { default as MaterialTextField } from "@mui/material/TextField";
import { TimePicker as MaterialTimePicker, TimePickerProps as MaterialTimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { useDialog } from "./DetailsDialog";
import { Access } from "../../../models/configuration/security/Permission";
import { useUser } from "../../../providers/UserProvider";
import React from "react";

export interface TimePickerProps<TInputDate, TDate> extends MaterialTimePickerProps<TInputDate, TDate> {  
    permissionKey?: string;
}

const TimePicker = <TInputDate, TDate = TInputDate>(props: TimePickerProps<TInputDate, TDate>) => {         
    const { checkAccess } = useUser();
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const [readOnly] = React.useState(!checkAccess(props.permissionKey ?? permissionKey, adding ? Access.Create : Access.Update));

    function handleChange(newValue: TDate | null, keyboardInputValue?: string) {        
        setDirty(true);
        props.onChange(newValue);
    };

    return (
        <MaterialTimePicker
            {...props}
            disabled={props.disabled || readOnly}
            onChange={handleChange}                                                        
        />  
    );
};

export default TimePicker;
