import {useState, useEffect} from "react";
import { useCreateAxios } from './useCreateAxios';
import { useUser } from '../providers/UserProvider';
import { getRoomsForTransferList as getAllRooms} from "../api/roomApi";
import { getClassesForTransferList as getAllClasses } from "../api/roomClassApi";
import { getLoyaltyLevelGridData as getAllLoyaltyLevels } from "../api/loyaltyLevelApi";
import { parseLanguageStringsFromJson } from '../utilities/JsonHelper';
import { GuestSelectorData } from '../models/common/GuestSelectorData';

// TODO: import models and use those + error state and messages

function useGuestSelectorData(languageKey: string) {
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const [allRooms, setAllRooms] = useState<{ id: string, number: string }[]>([]);
    const [allClasses, setAllClasses] = useState<{ id: string; name: string; }[]>([]);
    const [allLoyaltyLevels, setAllLoyaltyLevels] = useState<{ id: string; name: string }[]>([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        async function getAllData() {
            try {
                const roomData = await getAllRooms(axiosInstance, user.currentProperty?.code ?? "");
                setAllRooms(roomData);

                const classData = await getAllClasses(axiosInstance, user.currentProperty?.code ?? "");
                setAllClasses(classData);

                const loyaltyData = await getAllLoyaltyLevels(axiosInstance, user.currentProperty?.code ?? "");
                setAllLoyaltyLevels(loyaltyData.loyaltyLevels.map(l => parseLanguageStringsFromJson(l, languageKey)));

            }
            catch (e) {
                console.log('error fetching data in useRoomList', e);
            }

            finally {
                setLoading(false);
            }
        }

        getAllData();
    }, [user.currentProperty?.code, getAllRooms, getAllClasses, languageKey, parseLanguageStringsFromJson, getAllLoyaltyLevels])

    function selectorIdsToValues(data: GuestSelectorData): GuestSelectorData {
        const { roomIds, bookingId, roomClassIds, loyaltyLevelIds, externalKey } = data;

        let roomsData;
        roomsData = allRooms.filter(r => roomIds?.map(i => i.toUpperCase()).includes(r.id.toUpperCase())).map(val => val.number);
        roomsData = roomsData.length > 0 ? roomsData : undefined;

        let loyaltyData;
        loyaltyData = allLoyaltyLevels.filter(l => loyaltyLevelIds?.includes(l.id)).map(val => val.name);
        loyaltyData = loyaltyData.length > 0 ? loyaltyData : undefined;

        let classData;
        classData = allClasses.filter(c => roomClassIds?.includes(c.id)).map(val => val.name);
        classData = classData.length > 0 ? classData : undefined;

        return {
            bookingId: bookingId ?? undefined,
            roomIds: roomsData,
            loyaltyLevelIds: loyaltyData,
            roomClassIds: classData,
            externalKey: externalKey ?? undefined
        }
    }

    function getFormattedRooms(roomIds: string[], strings: any) {
        if (roomIds.length > 10) {
            return `${strings.roomCount}: ${roomIds.length}`;
        }
        else if (roomIds.length > 1) {
            return `${strings.roomNumbers}: ${roomIds.join(", ")}`;
        }
        else {
            return `${strings.roomNumber}: ${roomIds[0]}`;
        }
    }

    function formattedSelectorValues(data: GuestSelectorData | null, strings: any) {
        if (data === null) {
            return "";
        }

        if (data.roomIds) {
            return getFormattedRooms(data.roomIds, strings);
        }
        else if (data.externalKey) {
            return `${strings.externalId}: ${data.externalKey}`
        }
        else if (data.bookingId) {
            return `${strings.bookingId}: ${data.bookingId}`
        }
        else if (data.loyaltyLevelIds) {
            return (data.loyaltyLevelIds.length > 1 ? `${strings.loyaltyLevels}: ` : `${strings.loyaltyLevel}: `) + data.loyaltyLevelIds.join(", ");
        }
        else if (data.roomClassIds) {
            return (data.roomClassIds.length > 1 ? `${strings.roomClasses}: ` : `${strings.roomClass}: `) + data.roomClassIds.join(", ");
        }
        else {
            return "";
        }
    }

    return {
        allRooms,
        allClasses,
        allLoyaltyLevels,
        loading,
        selectorIdsToValues,
        formattedSelectorValues
    }
}

export default useGuestSelectorData;