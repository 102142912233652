import { FormControl, InputLabel, Select, SelectChangeEvent, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from '@mui/material/styles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ValidateTab from "../../common/details/ValidateTab";
import { GridColumns, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { createService, deleteService, getService, listServices, updateService } from "../../../api/guestservices/serviceApi";
import { getOptions } from "../../../api/guestservices/optionsApi";
import { getTypes } from "../../../api/guestservices/typeApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { initialServiceState, Service as ServiceModel } from "../../../models/modules/guestservices/Service";
import { initialServiceAttributeState, mapAttributeTypesToKeyValues, mapMinuteIncrementToKeyValues, ServiceAttribute } from "../../../models/modules/guestservices/ServiceAttribute";
import { initialServiceAttributeChoiceState, ServiceAttributeChoice } from "../../../models/modules/guestservices/ServiceAttributeChoice";
import { mapTypesToKeyValues, Type as TypeModel } from "../../../models/modules/guestservices/Type";
import { useUser } from "../../../providers/UserProvider";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import Checkbox from "../../common/details/Checkbox";
import { default as DetailsDialog, useDialog } from "../../common/details/DetailsDialog";
import DetailsGrid from "../../common/details/DetailsGrid";
import RoomClasses from "../../common/details/RoomClasses";
import { default as DialogSelect } from "../../common/details/Select";
import TextField from "../../common/details/TextField";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageRichTextField from "../../common/language/LanguageRichTextField";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DisplayOrderButton, { moveBottom, moveDown, moveTop, moveUp } from "../../common/DisplayOrderButton";
import { initialOptionsState, Options } from "../../../models/modules/guestservices/Options";

interface ServiceValidationErrors {
    name: string;
    description: string;
    typeId: string;
    roomClasses: string;
    emailAddress: string;
    emailSubject: string;
    externalId: string;
}

interface ServiceAttributeValidationErrors {
    instructions: string;
    minQuantity: string;
    maxQuantity: string;
    minuteIncrement: string;
    type: string;
}

interface ServiceAttributeChoiceValidationErrors {
    choice: string;
}

const ServiceList = () => {
    const initialServiceErrorState: ServiceValidationErrors = {
        name: "",
        description: "",
        typeId: "",
        roomClasses: "",
        emailAddress: "",
        emailSubject: "",
        externalId: "",
    }

    const initialServiceAttributeErrorState: ServiceAttributeValidationErrors = {
        instructions: "",
        minQuantity: "",
        maxQuantity: "",
        minuteIncrement: "",
        type: "" 
    }

    const initialServiceAttributeChoiceErrorState: ServiceAttributeChoiceValidationErrors = {
        choice: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [attributesGridDirty, setAttributesGridDirty] = useState(false);
    const [attributeChoicesGridDirty, setAttributeChoicesGridDirty] = useState(false);
    const [gridRefresh, setGridRefresh] = useState(false);
    const [services, setServices] = useState<ServiceModel[]>([]);
    const [options, setOptions] = useState<Options>(initialOptionsState);
    const [types, setTypes] = useState<TypeModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [attributesDialogOpen, setAttributesDialogOpen] = useState(false);
    const [attributeChoiceDialogOpen, setAttributeChoiceDialogOpen] = useState(false);  
    const [tabValue, setTabValue] = useState<number>(0);
    const [attributesTabValue, setAttributesTabValue] = useState<number>(0);
    const [selectedServiceId, setSelectedServiceId] = useState("");
    const [selectedService, setSelectedService] = useState<ServiceModel>(initialServiceState);   
    const [selectedTypeId, setSelectedTypeId] = useState<string>("0");  
    const [selectedAttributeId, setSelectedAttributeId] = useState("");
    const [selectedAttribute, setSelectedAttribute] = useState<ServiceAttribute>(initialServiceAttributeState);   
    const [selectedAttributeChoiceId, setSelectedAttributeChoiceId] = useState("");
    const [selectedAttributeChoice, setSelectedAttributeChoice] = useState<ServiceAttributeChoice>(initialServiceAttributeChoiceState);   
    const [isLoading, setIsLoading] = useState(true);    
    const [errors, setErrors] = useState<ServiceValidationErrors>(initialServiceErrorState);    
    const [attributeErrors, setAttributeErrors] = useState<ServiceAttributeValidationErrors>(initialServiceAttributeErrorState);  
    const [attributeChoiceErrors, setAttributeChoiceErrors] = useState<ServiceAttributeChoiceValidationErrors>(initialServiceAttributeChoiceErrorState);  
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);        
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        
    const { dirty, setDirty, adding, permissionKey } = useDialog();
    const { checkAccess } = useUser();
    const [readOnly] = React.useState(!checkAccess("content_guestservices"));
    const canEdit = checkAccess("content_guestservices", Access.Update);
    const canDelete = checkAccess("content_guestservices", Access.Delete);
    const canAdd = checkAccess("content_guestservices", Access.Create);

    useEffect(() => {

        async function loadTypes() {
            try {
                const payload = await getTypes(axiosInstance, user.currentProperty?.code ?? "", true);
                payload.sort((a, b) => a.displayOrder && b.displayOrder);
                setTypes(payload);
                if (selectedTypeId !== "0") {
                    loadServices(selectedTypeId);
                }
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTypes.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            
            try {                
                const payload = await getOptions(axiosInstance, user.currentProperty?.code ?? "");
                setOptions(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingGuestServiceOptions.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);            
        }

        loadTypes();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingGuestServices, strings.errorRetreivingGuestService, strings.errorRetreivingTypes]);

    const dialogTitle = selectedServiceId === "" ? strings.addGuestServiceTitle : strings.editGuestServiceTitle.replace("{{name}}", selectedService?.name?.en ?? ""); // TODO: default language

    const dialogAttributeTitle = selectedAttributeId === "" ? strings.addGuestServiceAttributeTitle : strings.editGuestServiceAttributeTitle.replace("{{name}}", selectedService?.name?.en ?? ""); // TODO: default language

    const dialogAttributeChoiceTitle = selectedAttributeChoiceId === "" ? strings.addGuestServiceAttributeChoiceTitle : strings.editGuestServiceAttributeChoiceTitle; // TODO: default language

    async function loadServices(typeId: string) {
        try {
            const payload = await listServices(axiosInstance, user.currentProperty?.code ?? "", typeId);
            payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);

            setServices(payload);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingGuestServices.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    async function loadService(id: string) {
        try {
            const payload = await getService(axiosInstance, user.currentProperty?.code ?? "", id);

            setSelectedServiceId(id);
            setSelectedService(payload);
            setDetailsDialogOpen(true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingGuestService.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
    }

    function handleAddClick() {
        setSelectedServiceId("");
        setSelectedService(initialServiceState);
        setSelectedAttributeId("")
        setSelectedAttribute(initialServiceAttributeState)
        setDetailsDialogOpen(true);
    }

    function handleEditRowClick(id: string) {        
        loadService(id)
    }

    function handleAddAttributeClick() {
        setSelectedAttributeId("");
        setSelectedAttribute(initialServiceAttributeState);
        setAttributesDialogOpen(true);
    }

    function handleEditAttributeRowClick(id: string) {        
        var attribute = selectedService.attributes.find(p => p.id === id);
        if (attribute) {
            setSelectedAttributeId(id);
            setSelectedAttribute(attribute);
            changeAttributeType(attribute.type!!.toString(), true);
            setAttributesDialogOpen(true);
        }
    }

    function handleDeleteAttributeClick(id: string) { 
        var attributes: ServiceAttribute[] = [];

        selectedService.attributes.forEach (a => {
            if (a.id !== id) {
                attributes.push(a)
            }
        })
        
        setSelectedService((prevState) => ({
            ...prevState,
            attributes: attributes
        }));

        setAttributesGridDirty(true);
    }

    function handleAddAttributeChoiceClick() {
        setSelectedAttributeChoiceId("");
        setSelectedAttributeChoice(initialServiceAttributeChoiceState);
        setAttributeChoiceDialogOpen(true);
    }

    function handleEditAttributeChoiceRowClick(id: string) {        
        
        var choice = selectedAttribute.choices.find(p => p.id === id);
        if (choice) {
            setAttributesGridDirty(true);
            setSelectedAttributeChoiceId(id);
            setSelectedAttributeChoice(choice);
            setAttributeChoiceDialogOpen(true);
        }
    }

    function handleDeleteAttributeChoiceClick(id: string) {        
        var choices: ServiceAttributeChoice[] = [];

        selectedAttribute.choices.forEach (c => {
            if (c.id !== id) {
                choices.push(c)
            }
        })
        
        setSelectedAttribute((prevState) => ({
            ...prevState,
            choices: choices
        }));

        setAttributeChoicesGridDirty(true);
    }

    async function deleteSelectedService(id: string) {
        try {
            await deleteService(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingGuestService.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.guestServiceDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedServiceId("");
        setSelectedService(initialServiceState);
    }

    function handleDeleteClick(id: string) {
        const name = services.find(p => p.id === id)?.name?.en ?? "";

        alertDialog({
            title: strings.deleteGuestServiceAlertTitle,
            message: strings.deleteGuestServiceAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedService(id);
        });
    }

    const getServiceAttributeTypeName = (type: string): String => {

        if (type === "QTY") {
            return strings.quantity;
        }
        else if (type === "TIME") {
            return strings.time;
        }
        else if (type === "CHOICE") {
            return strings.choice;
        }
        else {
            return strings.text;
        }
    }
   
    function handleTabChange(event: React.SyntheticEvent, newValue: number) {        
        setTabValue(newValue);        
    }

    function handleAttributesTabChange(event: React.SyntheticEvent, newValue: number) {        
        setAttributesTabValue(newValue);        
    }

    function handleNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleDescriptionChange(content: string, language: string) {
        setSelectedService((prevState) => ({
            ...prevState,
            description: {
                ...prevState.description,
                [language]: content,
            },
        }));
    }

    function handleExternalIdChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            externalId: event.target.value,
        }));
    }

    function handleEmailAddressChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            emailAddress: (event.target.value === "" ? null : event.target.value),
        }));
    }

    function handleEmailSubjectChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            emailSubject: (event.target.value === "" ? null : event.target.value),
        }));
    }

    function handleEnabledChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            enabled: event.target.checked,
        }));
    }

    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedService((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    function handleServiceTypeChange(event: SelectChangeEvent) {
        setSelectedService((prevState) => ({
            ...prevState,
            typeId: event.target.value
        }));
    }

    function handleRoomClassesChange(values: { id: string; name: string }[]) {
        setSelectedService((prevState) => ({
            ...prevState,
            roomClasses: values
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setDetailsDialogOpen(false);     
        setErrors(initialServiceErrorState);
        setSelectedServiceId("");
        setSelectedService(initialServiceState);
        setSelectedAttributeId("")
        setSelectedAttribute(initialServiceAttributeState);   
        setTabValue(0);
        setAttributesTabValue(0);
        setAttributesGridDirty(false);
        setAttributeChoicesGridDirty(false);
    }

    function handleCancelAttributeClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAttributeErrors(initialServiceAttributeErrorState);
        setAttributesDialogOpen(false); 
        setAttributesTabValue(0);
    }

    function handleCancelAttributeChoiceClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAttributeChoiceErrors(initialServiceAttributeChoiceErrorState);
        setAttributeChoiceDialogOpen(false); 
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setErrors(initialServiceErrorState);

        var id = selectedServiceId;
        const isUpdate = selectedServiceId != "";

        try {
            if (isUpdate) {
                await updateService(axiosInstance, user.currentProperty?.code ?? "", selectedService);
            } else {
                id = await createService(axiosInstance, user.currentProperty?.code ?? "", selectedService);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingGuestService
                    : strings.errorUpdatingGuestService
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        try {
            if (graphicManagerRef.current) {
                await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setTabValue(0);
        setAttributesTabValue(0);
        setNotify({
            isOpen: true,
            message: strings.guestServiceSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    async function handleSaveAttributeClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAttributeErrors(initialServiceAttributeErrorState);

        var id = selectedAttributeId;
        const isUpdate = selectedAttributeId != "";

        var attributes: ServiceAttribute[] = [];

        if (isUpdate) {
            selectedService.attributes.forEach (a => {
                if (a.id === selectedAttribute.id) {
                    attributes.push(selectedAttribute)
                }
                else {
                    attributes.push(a)
                }
            })
            
            setSelectedService((prevState) => ({
                ...prevState,
                attributes: attributes
            }));
        }
        else {
            selectedService.attributes.forEach (a => {
                attributes.push(a)
            })

            var newAttribute = selectedAttribute
            newAttribute.id = uuid();
            attributes.push(selectedAttribute);
            
            setSelectedService((prevState) => ({
                ...prevState,
                attributes: attributes
            }));
        }

        setAttributesGridDirty(true);
        setAttributesDialogOpen(false);
        setSelectedAttribute(initialServiceAttributeState);
        setSelectedAttributeId("");
        setAttributesTabValue(0);

        return true;
    }

    async function handleSaveAttributeChoiceClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAttributeChoiceErrors(initialServiceAttributeChoiceErrorState);

        var id = selectedAttributeChoiceId;
        const isUpdate = selectedAttributeChoiceId != "";

        var choices: ServiceAttributeChoice[] = [];

        if (isUpdate) {
            selectedAttribute.choices.forEach (c => {
                if (c.id === selectedAttributeChoice.id) {
                    choices.push(selectedAttributeChoice)
                }
                else {
                    choices.push(c)
                }
            })
            
            setSelectedAttribute((prevState) => ({
                ...prevState,
                choices: choices
            }));
        }
        else {
            selectedAttribute.choices.forEach (c => {
                choices.push(c)
            })

            var newChoice = selectedAttributeChoice
            newChoice.id = uuid();
            choices.push(selectedAttributeChoice);

            setSelectedAttribute((prevState) => ({
                ...prevState,
                choices: choices
            }));
        }
        
        setAttributeChoicesGridDirty(true);
        setAttributeChoiceDialogOpen(false);
        setSelectedAttributeChoice(initialServiceAttributeChoiceState);
        setSelectedAttributeChoiceId("");

        return true;
    }

    function handleTypeChange(event: SelectChangeEvent) {
        setSelectedTypeId(event.target.value)
        loadServices(event.target.value)
        setGridRefresh(!gridRefresh)
    }

    function handleAttributeTypeChange(event: SelectChangeEvent) {
        changeAttributeType(event.target.value, false);
    }

    function changeAttributeType(val: string, edit: boolean) {

        if (edit && val === "TIME" ) {
            setSelectedAttribute((prevState) => ({
                ...prevState,
                minuteIncrement: 30,
            }));
        }

        setSelectedAttribute((prevState) => ({
            ...prevState,
            type: val,
        }));
    }

    function handleAttributeInstructionsChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            instructions: {
                ...prevState.instructions,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleAttributeMinQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            minQuantity: Number(event.currentTarget.value) < 0 ? 1 : Number(event.currentTarget.value),
        }));
    }

    function handleAttributeMaxQuantityChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            maxQuantity: Number(event.currentTarget.value) < 0 ? 1 : Number(event.currentTarget.value),
        }));
    }

    function handleAttributeMinuteIncrementChange(event: SelectChangeEvent) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            minuteIncrement: parseInt(event.target.value),
        }));
    }

    function handleAttributeAllowMultiSelectChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            allowMultiSelect: event.target.checked,
        }));
    }

    function handleAttributeChoiceChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedAttributeChoice((prevState) => ({
            ...prevState,
            value: {
                ...prevState.value,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handleValidateService() {
        setErrors(initialServiceErrorState);

        const fields: ValidationField[] = [{ property: "name.en", type: ValidationType.Required, message: strings.validationGuestServiceName },
        { property: "description", type: ValidationType.Required, message: strings.validationGuestServiceDescription },
        { property: "externalId", type: ValidationType.Required, message: strings.validationGuestServiceExternalId },
        {
            property: "typeId",
            type: ValidationType.Custom,
            custom: (value) => {
                if (value === "-1" || value === "") {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceType
        }];
        
        if (options.provider === "EMAIL") {
            fields.push({ property: "emailAddress", type: ValidationType.Required, message: strings.validationGuestServiceEmailAddress });    
            fields.push({ property: "emailAddress", type: ValidationType.Email, message: strings.validationGuestServiceEmailAddress });
            fields.push({ property: "emailSubject", type: ValidationType.Required, message: strings.validationGuestServiceEmailSubject });
        }

        const errors = validate<ServiceModel, ServiceValidationErrors>(fields, selectedService);

        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    function handleValidateServiceAttribute() {
        setAttributeErrors(initialServiceAttributeErrorState);
        
        const fields: ValidationField[] = [
        { property: "instructions.en", type: ValidationType.Required, message: strings.validationGuestServiceAttributeInstructions },
        {
            property: "minQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && (value === "" || Number.isNaN(value))) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMinValue
        },
        {
            property: "minQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && (parseInt(value) > 99 || parseInt(value) < 1)) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMinValueRange
        },
        {
            property: "minQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && selectedAttribute.maxQuantity !== null && (parseInt(value) > selectedAttribute.maxQuantity!!)) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMinLessThanMax
        },
        {
            property: "maxQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && (value === "" || Number.isNaN(value))) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMaxValue
        },
        {
            property: "maxQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && (parseInt(value) > 99 || parseInt(value) < 1)) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMaxValueRange
        },
        {
            property: "maxQuantity",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "QTY" && selectedAttribute.minQuantity !== null && (parseInt(value) < selectedAttribute.minQuantity!!)) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMinLessThanMax
        },
        {
            property: "minuteIncrement",
            type: ValidationType.Custom,
            custom: (value) => {
                if (selectedAttribute.type === "TIME" && value === undefined) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceAttributeMinuteIncrement
        },
        {
            property: "type",
            type: ValidationType.Custom,
            custom: (value) => {
                if (value === "" || value === undefined) {
                    return false;
                }
                else {
                    return true;
                }
            },
            message: strings.validationGuestServiceType
        }];

        const attributeErrors = validate<ServiceAttribute, ServiceAttributeValidationErrors>(fields, selectedAttribute);

        if (attributeErrors) {
            setAttributeErrors(attributeErrors);
            return false;
        }
        else {
            return true;
        }
    }

    function handleValidateServiceAttributeChoice() {
        setAttributeChoiceErrors(initialServiceAttributeChoiceErrorState);
        
        const fields: ValidationField[] = [{ property: "value.en", type: ValidationType.Required, message: strings.validationGuestServiceName }];

        const errors = validate<ServiceAttributeChoice, ServiceAttributeChoiceValidationErrors>(fields, selectedAttributeChoice);

        if (errors) {
            setAttributeChoiceErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

   function handleMoveAttributeUpClick(id: string) {
        setSelectedService((prevState) => ({
            ...prevState,
            attributes: moveUp(selectedService.attributes, id, 1)
        }));
        
        setAttributesGridDirty(true);
    }

    function handleMoveAttributeDownClick(id: string) {
        setSelectedService((prevState) => ({
            ...prevState,
            attributes: moveDown(selectedService.attributes, id, 1)
        }));    
        
        setAttributesGridDirty(true);
    }

    function handleMoveAttributeToTopClick(id: string) {
        setSelectedService((prevState) => ({
            ...prevState,
            attributes: moveTop(selectedService.attributes, id)
        }));    
        
        setAttributesGridDirty(true);
    }

    function handleMoveAttributeToBottomClick(id: string) {
        setSelectedService((prevState) => ({
            ...prevState,
            attributes: moveBottom(selectedService.attributes, id)
        }));  
        
        setAttributesGridDirty(true);
    }

    function getDisplayOrderForAttribute(id: string) {
        let index = selectedService.attributes.findIndex(attr => attr.id === id);
        return index;
    }

    //function updateAttributeDisplayOrder(choiceList: ServiceAttributeChoice[]) {
    //    const idList = choiceList.map(choice => choice.id);

    //    /*saveDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
    //        .then(() => setRestaurants(restaurantList))
    //        .catch((e: unknown) => {
    //            const error = axios.isAxiosError(e)
    //                ? { message: e.message }
    //                : { message: "unable to parse error info" };
    //            setNotify({
    //                isOpen: true,
    //                message: strings.errorUpdatingRestaurant.replace("{{error}}", error.message),
    //                msgType: "error",
    //            });
    //        })*/
    //}
  
    function handleMoveChoiceUpClick(id: string) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            choices: moveUp(selectedAttribute.choices, id, 1)
        }));     
        
        setAttributeChoicesGridDirty(true);
    }

    function handleMoveChoiceDownClick(id: string) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            choices: moveDown(selectedAttribute.choices, id, 1)
        }));     
        
        setAttributeChoicesGridDirty(true);
    }

    function handleMoveChoiceToTopClick(id: string) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            choices: moveTop(selectedAttribute.choices, id)
        }));    
        
        setAttributeChoicesGridDirty(true);
    }

    function handleMoveChoiceToBottomClick(id: string) {
        setSelectedAttribute((prevState) => ({
            ...prevState,
            choices: moveBottom(selectedAttribute.choices, id)
        }));  
        
        setAttributeChoicesGridDirty(true);
    }

    function getDisplayOrderForChoice(id: string) {
        let index = selectedAttribute.choices.findIndex(choice => choice.id === id);
        return index;
    }

    //function updateChoiceDisplayOrder(choiceList: ServiceAttributeChoice[]) {
    //    const idList = choiceList.map(choice => choice.id);

    //    /*saveDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", idList)
    //        .then(() => setRestaurants(restaurantList))
    //        .catch((e: unknown) => {
    //            const error = axios.isAxiosError(e)
    //                ? { message: e.message }
    //                : { message: "unable to parse error info" };
    //            setNotify({
    //                isOpen: true,
    //                message: strings.errorUpdatingRestaurant.replace("{{error}}", error.message),
    //                msgType: "error",
    //            });
    //        })*/
    //}

    function getTypesForGrid(params: GridValueGetterParams<any, any>) {
        return JSON.parse(params.row.type.name).en;
    }

    const getTypeForEdit = (): string => {
        var type = types.find(t => t.id === selectedService.typeId)
        return type === undefined ? "-1" : type.id
    }
    
    const gridColumns: GridColumns = [   
        { field: "id", hide: true, filterable: false, hideable: false },     
        { field: "name", headerName: strings.name, valueGetter: (params) => params.row.name.en, flex: 4 },      
        { field: "type", headerName: strings.type, valueGetter: (params) => getTypesForGrid(params), flex: 4 },      
        { field: "enabled", headerName: strings.enabled, type: "boolean", valueGetter: (params) => params.row.enabled, flex: 1 },  
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (params) => params.row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_guestservices"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />
            ],
        },
    ];

    const gridColumnsAttributes: GridColumns = [   
        { field: "id", hide: true, filterable: false, hideable: false },     
        { field: "type", headerName: strings.type, valueGetter: (params) => getServiceAttributeTypeName(params.row.type as string), flex: 4},      
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    rowId={params.id.toString()} 
                    showWithAdd={true}
                    clickHandler={() => handleEditAttributeRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_guestservices"
                    showWithEdit={true}
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteAttributeClick(params.id.toString())}
                />,
                <DisplayOrderButton
                    permissionKey="content_guestservices"
                    current={getDisplayOrderForAttribute(params.id.toString())}
                    count={selectedService.attributes.length}
                    onMoveTopClick={() => handleMoveAttributeToTopClick(params.id.toString())}
                    onMoveUpClick={() => handleMoveAttributeUpClick(params.id.toString())}
                    onMoveDownClick={() => handleMoveAttributeDownClick(params.id.toString())}
                    onMoveBottomClick={() => handleMoveAttributeToBottomClick(params.id.toString())}
                />
            ],
        },
    ];

    const gridColumnsAttributesNoEdit: GridColumns = [   
        { field: "id", hide: true, filterable: false, hideable: false },     
        { field: "type", headerName: strings.type, valueGetter: (params) => getServiceAttributeTypeName(params.row.type as string), flex: 4},      
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditAttributeRowClick(params.id.toString())} 
                />
            ],
        }
    ];

    const gridColumnsAttributeChoices: GridColumns = [   
        { field: "id", hide: true, filterable: false, hideable: false },     
        { field: "value", headerName: strings.choice, valueGetter: (params) => params.row.value.en, flex: 4},      
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => 
            [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    showWithAdd={true}
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditAttributeChoiceRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_guestservices"
                    showWithEdit={true}
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteAttributeChoiceClick(params.id.toString())}
                />,
                <DisplayOrderButton
                    permissionKey="content_guestservices"
                    current={getDisplayOrderForChoice(params.id.toString())}
                    count={selectedAttribute.choices.length}
                    onMoveTopClick={() => handleMoveChoiceToTopClick(params.id.toString())}
                    onMoveUpClick={() => handleMoveChoiceUpClick(params.id.toString())}
                    onMoveDownClick={() => handleMoveChoiceDownClick(params.id.toString())}
                    onMoveBottomClick={() => handleMoveChoiceToBottomClick(params.id.toString())}
                />
            ],
        },
    ];

    const gridColumnsAttributeChoicesNoEdit: GridColumns = [   
        { field: "id", hide: true, filterable: false, hideable: false },     
        { field: "value", headerName: strings.choice, valueGetter: (params) => params.row.value.en, flex: 4},      
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => 
            [
                <DataGridEditButton 
                    permissionKey="content_guestservices" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditAttributeChoiceRowClick(params.id.toString())} 
                />
            ],
        },
    ];

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}> 
                <FormControl variant="outlined" sx={{ minWidth: 200 }}  disabled={readOnly}>
                    <InputLabel id="simple-select-label-type">{strings.type}</InputLabel>
                    <Select
                        aria-labelledby={"select-label-type"}
                        label={strings.type}
                        value={selectedTypeId !== null ? selectedTypeId.toString() : ""}
                        onChange={handleTypeChange}
                        disabled={false}
                    >
                        {types.map((type, index) => (
                            <MenuItem key={index} value={type.id}>{type.name!!["en"]}</MenuItem>
                        ))}
                    </Select>    
                </FormControl>
                <Spacer/>                                         
                <DataGrid
                    permissionKey="content_guestservices"
                    rows={services}
                    columns={gridColumns}
                    onAddButtonClick={handleAddClick}
                    addButtonText={strings.addGuestServiceButton}
                />
                <DetailsDialog
                    permissionKey="content_guestservices"
                    open={detailsDialogOpen}
                    adding={selectedServiceId === ""}
                    title={dialogTitle}
                    onValidateForm={handleValidateService}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 879.5, height: 726.38 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <ValidateTab label={strings.details} errors={Boolean(errors.name) || Boolean(errors.description) || Boolean(errors.typeId)} />
                            <Tab label={strings.graphics} />
                            <Tab label={strings.guestServiceAttributes} />
                            <Tab label={strings.roomClasses} />      
                            { options.provider === "EMAIL" &&
                                <ValidateTab label={strings.emailSettings} errors={Boolean(errors.emailAddress) || Boolean(errors.emailSubject)} />
                            }
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={tabValue} index={0}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.name}
                                values={selectedService.name ?? {}}
                                width={410}
                                onChange={handleNameChange}
                                error={Boolean(errors.name)}
                                helperText={errors.name}
                            />
                            <Spacer />
                            <LanguageRichTextField
                                values={selectedService.description ?? {}}
                                label={strings.description}
                                id="item-description"
                                error={Boolean(errors.description)}
                                helperText={errors.description}
                                onChange={handleDescriptionChange}                                
                                width="100%"
                            />
                            <Spacer />
                            <TextField
                                onChange={handleExternalIdChange}
                                label={strings.externalId}
                                value={selectedService.externalId}
                                width={300}
                                helperWidth={200}
                                error={Boolean(errors.externalId)}
                                helperText={errors.externalId}                            
                            />
                            <Spacer />
                            <DialogSelect
                                label={strings.type}
                                keyValues={mapTypesToKeyValues(types)}
                                selectedValue={getTypeForEdit()}
                                onChangeHandler={handleServiceTypeChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(errors.typeId)}
                                helperText={errors.typeId} />
                            <Stack direction="row">
                                <Checkbox
                                    label={strings.enabled}
                                    checked={selectedService.enabled}
                                    onChange={handleEnabledChange}
                                />
                                <Spacer x={2} />
                                <Checkbox  
                                    label={strings.previewOnly}
                                    checked={selectedService.previewOnly ?? false}
                                    onChange={handlePreviewOnlyChange}                                                                
                                />
                            </Stack>
                        </LanguageForm>
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={1}>
                        <GraphicManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="guestServiceItem"
                            itemKey={selectedService.id}
                            imageKeys={["main"]}                            
                            ref={graphicManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={tabValue} index={2}>
                        <DetailsGrid
                            permissionKey="content_guestservices"
                            rows={selectedService.attributes}
                            columns={canEdit || (selectedServiceId === "" && canAdd) ? gridColumnsAttributes : gridColumnsAttributesNoEdit}
                            height={400}
                            pageSize={5}
                            showAddButton={canEdit || (selectedServiceId === "" && canAdd)}
                            showAddButtonEditOnly={canEdit}
                            onAddButtonClick={handleAddAttributeClick}
                            addButtonText={strings.addGuestServiceAttributeButton}                                                        
                            dirty={attributesGridDirty} />
                    </TabPanelKeepMounted> 
                    <TabPanelKeepMounted value={tabValue} index={3}>
                        <RoomClasses
                            assignedClasses={selectedService.roomClasses}
                            onChangeHandler={handleRoomClassesChange}                            
                            height={453.56}
                            error={Boolean(errors.roomClasses)}
                            helperText={errors.roomClasses}
                        />
                    </TabPanelKeepMounted>
                    { options.provider === "EMAIL" &&
                        <TabPanelKeepMounted value={tabValue} index={4}>
                            <Spacer />
                            <TextField
                                onChange={handleEmailAddressChange}
                                label={strings.emailAddress}
                                value={selectedService.emailAddress === null ? "" : selectedService.emailAddress}
                                width={400}
                                helperWidth={300}
                                error={Boolean(errors.emailAddress)}
                                helperText={errors.emailAddress}                            
                            />
                            <Spacer />
                            <TextField
                                onChange={handleEmailSubjectChange}
                                label={strings.emailSubject}
                                value={selectedService.emailSubject === null ? "" : selectedService.emailSubject}
                                width={400}
                                helperWidth={300}  
                                error={Boolean(errors.emailSubject)}
                                helperText={errors.emailSubject}
                            />
                        </TabPanelKeepMounted>    
                    }
                </DetailsDialog>

                <DetailsDialog
                    permissionKey="content_guestservices"
                    open={attributesDialogOpen}
                    adding={selectedServiceId === ""}
                    title={dialogAttributeTitle}
                    onValidateForm={handleValidateServiceAttribute}
                    onCancelClick={handleCancelAttributeClick}
                    onSaveClick={handleSaveAttributeClick}
                    contentSize={{ width: 879.5, height: 586.41 }}
                >
                    <Box>
                        <Tabs
                            value={attributesTabValue}
                            onChange={handleAttributesTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab label={strings.details} />
                            {selectedAttribute.type !== undefined && selectedAttribute.type === "CHOICE" &&
                                <Tab label={strings.choices} />     
                            }                    
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={attributesTabValue} index={0}>
                        <DialogSelect
                            label={strings.type}
                            keyValues={mapAttributeTypesToKeyValues()}
                            selectedValue={selectedAttribute.type}
                            onChangeHandler={handleAttributeTypeChange}
                            sx={{ minWidth: 200 }}                                
                            error={Boolean(attributeErrors.type)}
                            helperText={attributeErrors.type} />
                        <LanguageForm>
                            <Spacer />
                            <LanguageSelect />
                            <LanguageTextField
                                id="attribute-instructions"
                                label={strings.instructions}
                                values={selectedAttribute.instructions ?? {}}
                                width={800}
                                multiline={true}
                                lines={2}
                                onChange={handleAttributeInstructionsChange}
                                error={Boolean(attributeErrors.instructions)}
                                helperText={attributeErrors.instructions}
                            />
                        </LanguageForm>
                        <Spacer />
                        {selectedAttribute.type === "QTY" &&
                            <TextField
                                id="attribute-minquantity"
                                type="number"
                                label={strings.minimumQuantity}
                                width={150}
                                value={(selectedAttribute.minQuantity !== null && selectedAttribute.minQuantity !== undefined) ? selectedAttribute.minQuantity.toString() : "0"}
                                onChange={handleAttributeMinQuantityChange}
                                error={Boolean(attributeErrors.minQuantity)}
                                helperText={attributeErrors.minQuantity} 
                            />
                        }

                        {selectedAttribute.type !== undefined && selectedAttribute.type === "QTY" &&
                            <TextField
                                id="attribute-maxquantity"
                                type="number"
                                label={strings.maximumQuantity}
                                width={150}
                                value={(selectedAttribute.maxQuantity !== null && selectedAttribute.maxQuantity !== undefined) ? selectedAttribute.maxQuantity.toString() : "1"}
                                onChange={handleAttributeMaxQuantityChange}
                                error={Boolean(attributeErrors.maxQuantity)}
                                helperText={attributeErrors.maxQuantity} 
                            />
                        }
                        
                        {selectedAttribute.type !== undefined && selectedAttribute.type === "TIME" &&
                            <DialogSelect
                                label={strings.minuteIncrement}
                                keyValues={mapMinuteIncrementToKeyValues()}
                                selectedValue={(selectedAttribute.minuteIncrement !== null && selectedAttribute.minuteIncrement !== undefined) ? selectedAttribute.minuteIncrement.toString() : "30"}
                                onChangeHandler={handleAttributeMinuteIncrementChange}
                                sx={{ minWidth: 200 }}                                
                                error={Boolean(attributeErrors.minuteIncrement)}
                                helperText={attributeErrors.minuteIncrement} />
                        }

                    </TabPanelKeepMounted>   
                    
                    {selectedAttribute.type !== undefined && selectedAttribute.type === "CHOICE" &&
                        <TabPanelKeepMounted value={attributesTabValue} index={1}>
                            <DetailsGrid
                                permissionKey="content_guestservices"
                                rows={selectedAttribute.choices}
                                height={400}
                                pageSize={5}
                                columns={canEdit || (selectedServiceId === "" && canAdd) ? gridColumnsAttributeChoices : gridColumnsAttributeChoicesNoEdit}
                                showAddButton={canEdit || (selectedServiceId === "" && canAdd)}
                                showAddButtonEditOnly={canEdit}
                                onAddButtonClick={handleAddAttributeChoiceClick}
                                addButtonText={strings.addGuestServiceAttributeChoiceButton}                                                                
                                dirty={attributeChoicesGridDirty} />
                            <Checkbox
                                label={strings.allowMultiSelect}
                                checked={selectedAttribute.allowMultiSelect}
                                onChange={handleAttributeAllowMultiSelectChange}
                            />
                        </TabPanelKeepMounted>
                    }
                    
                </DetailsDialog>

                <DetailsDialog
                    permissionKey="content_guestservices"
                    open={attributeChoiceDialogOpen}
                    adding={selectedServiceId === ""}
                    title={dialogAttributeChoiceTitle}
                    onValidateForm={handleValidateServiceAttributeChoice}
                    onCancelClick={handleCancelAttributeChoiceClick}
                    onSaveClick={handleSaveAttributeChoiceClick}
                    contentSize={{ width: 439.75, height: 293.205 }}
                >
                    <Box sx={{ padding: '20px' }}>
                        <LanguageForm>
                            <LanguageSelect />
                            <LanguageTextField
                                id="attribute-choice"
                                label={strings.choice}
                                values={selectedAttributeChoice.value ?? {}}
                                width={400}
                                onChange={handleAttributeChoiceChange}
                                error={Boolean(attributeChoiceErrors.choice)}
                                helperText={attributeChoiceErrors.choice}
                            />
                        </LanguageForm>     
                    </Box>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }    
};

export default ServiceList;

