import React, { useState } from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { getDate } from "date-fns";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    startDatePicker: {
        marginRight: '16px',
    },
}));

export interface SearchEvent<T = Element> extends React.SyntheticEvent<T> {
    target: EventTarget & T;
}

export type DateSearchFieldChangeHandler = (
    startDate: Date,
    endDate: Date
) => any;

export const getDefaultEndDate = (): Date => {
    var date = new Date();
    date.setDate(getDate(date));

    return date;
}

export const getDefaultStartDate = (): Date => {
    return new Date();
}

export interface DateSearchFieldProps {
    startDate: Date | null;
    endDate: Date | null;
    format: string | null;
    onChange: DateSearchFieldChangeHandler;
}

const DateSearchField = (props: DateSearchFieldProps) => {
    
    const [startDateValue, setStartDateValue] = useState<Date>(props.startDate === null ? getDefaultStartDate() : props.startDate);
    const [endDateValue, setEndDateValue] = useState<Date>(props.endDate === null ? getDefaultEndDate() : props.endDate);
    const strings = useLocalizedStrings();
    const classes = useStyles(useTheme());  

    function handleStartDateChange(newValue: Date | null) {
        setStartDateValue(newValue ?? new Date());
        props.onChange(newValue ?? new Date(), endDateValue);
    }
    
    function handleEndDateChange(newValue: Date | null) {
        setEndDateValue(newValue ?? new Date());
        props.onChange(startDateValue, newValue ?? new Date());
    }
    
    return (
        <div className={classes.root}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div className={classes.startDatePicker}>
                    <DesktopDatePicker
                        label={strings.startDate}
                        inputFormat={props.format === null ? "MM/dd/yyyy" : props.format}
                        value={startDateValue}
                        views={props.format !== null && props.format === "MM/yyyy" ? ["month"] : ["day"]}
                        onChange={handleStartDateChange}                        
                        renderInput={(params) => <TextField {...params} />}
                    />
                </div>
                <DesktopDatePicker
                    label={strings.endDate}
                    inputFormat={props.format === null ? "MM/dd/yyyy" : props.format}
                    value={endDateValue}
                    onChange={handleEndDateChange}
                    views={props.format !== null && props.format === "MM/yyyy" ? ["month"] : ["day"]}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        </div>
    );
};

export default DateSearchField;