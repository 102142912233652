import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from "@mui/material/styles";
import { GridColumns, GridRowModel } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getBrandDropdownData, getPropertyGridData } from "../../../api/accounts/propertyApi";
import { listBrandSkins, listDefaultSkins, listPropertySkins, saveBrandSkin, saveDefaultSkin, savePropertySkin } from "../../../api/administration/skin/skinApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Skin, Skin as SkinModel } from "../../../models/administration/Skin";
import { BrandItemModel } from "../../../models/brandModels";
import { PropertyItemModel } from "../../../models/propertyModels";
import { useUser } from "../../../providers/UserProvider";
import { parseLanguageStringsFromJson } from "../../../utilities/JsonHelper";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import DataGrid from "../../common/datatable/DataGridWrapper";
import DetailsDialog from "../../common/details/DetailsDialog";
import TextField from "../../common/details/TextField";

const SkinList = () => {
    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [brandData, setBrandData] = useState<BrandItemModel[]>();
    const [selectedBrandId, setSelectedBrandId] = useState("");
    const [propertyData, setPropertyData] = useState<PropertyItemModel[]>();
    const [selectedPropertyId, setSelectedPropertyId] = useState("");
    const [skins, setSkins] = useState<SkinModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [addKeyDialogOpen, setAddKeyDialogOpen] = useState(false);
    const [addSkin, setAddSkin] = useState<Skin>({key: "", value: ""});
    const [gridRefresh, setGridRefresh] = useState(false);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const alertDialog = useAlertDialog();
    const axiosInstance = useCreateAxios();
    const { user, isSuperAdmin } = useUser();
    const location = useLocation();
    const theme = useTheme();

    const gridColumns : GridColumns = [
      { field: "key", headerName: strings.key, filterable: false, width: 360 },
      { field: "value", headerName: strings.value, editable: true, width: 360},
    ];

    useEffect(() => {
        async function getBrands() {
            try {
                const payload = await getBrandDropdownData(axiosInstance);
                setBrandData(payload);
            } catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingBrands,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        if (location.pathname !== "/skin/default/skins") {
            getBrands();   
        }
    }, [strings.errorRetrievingBrands]);    

    useEffect(() => {
        async function getProperties() {            
            try {                
                const payload = await getPropertyGridData(axiosInstance, selectedBrandId);
                const properties = payload.map(pr => parseLanguageStringsFromJson(pr, "en"));
                setPropertyData(properties);
            } catch (e) {
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingProperties,
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }        
        if (selectedBrandId !== "") {         
            getProperties();
        }
    }, [selectedBrandId, strings.errorRetreivingProperties]);

    useEffect(() => {

        async function apiListSkins() {
            try {
                var response;
                if (location.pathname === "/skin/default/skins") {
                    response = await listDefaultSkins(axiosInstance);
                    setSkins(response);
                }
                else if (selectedPropertyId === "ALL") {
                    response = await listBrandSkins(axiosInstance, selectedBrandId);
                    setSkins(response);
                }
                else if (selectedPropertyId !== "") {
                    response = await listPropertySkins(axiosInstance, selectedPropertyId);
                    setSkins(response);
                }                                
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };                
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingSkins.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }
        
        //if (skins.length === 0) {
            apiListSkins();
        //}
        
    }, [gridRefresh, selectedBrandId, selectedPropertyId, strings.errorRetrievingSkins]);

    async function handleProcessRowUpdate(newRow: GridRowModel) {
        try {
            newRow.value = newRow.value.trim();

            if (location.pathname === "/skin/default/skins") {
                await saveDefaultSkin(axiosInstance, newRow as Skin, false);
            }
            else if (selectedPropertyId === "ALL") {
                await saveBrandSkin(axiosInstance, selectedBrandId, newRow as Skin);
            }
            else {
                await savePropertySkin(axiosInstance, selectedPropertyId, newRow as Skin);
            }
            
            return newRow;  
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };                
            setNotify({
                isOpen: true,
                message: strings.errorSavingSkin.replace("{{error}}", error.message),
                msgType: "error",
            });

            var skin = skins.find(s => s.key === newRow.key)
            return skin
        }          
    }

    function handleBrandSelectionChange(e: SelectChangeEvent) {        
        const value = e.target.value as string;        
        setSelectedBrandId(value);
        setSelectedPropertyId("ALL");        
    }

    function handlePropertySelectionChange(e: SelectChangeEvent) {
        setSelectedPropertyId(e.target.value as string);
    }

    function handleAddClick() {
        setAddKeyDialogOpen(true)
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAddKeyDialogOpen(false)
    }

    async function handleSaveKeyClick(event: React.MouseEvent<HTMLButtonElement>) {

        try {
            await saveDefaultSkin(axiosInstance, addSkin, true);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };                
            setNotify({
                isOpen: true,
                message: strings.errorSavingSkin.replace("{{error}}", error.message),
                msgType: "error",
            });
        }

        setAddSkin({key: "", value: ""})
        setAddKeyDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.addSkinSuccess,
            msgType: "success",
        });

        return true;
    }

    function handleAddSkinKeyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setAddSkin((prevState) => ({
            ...prevState,
            key: event.currentTarget.value,
        }));
    }

    function handleAddSkinValueChange(event: React.ChangeEvent<HTMLInputElement>) {
        setAddSkin((prevState) => ({
            ...prevState,
            value: event.currentTarget.value,
        }));
    }
    
    function getBrands() {
        let kvps: { key: string; value: string }[] = [];
        if (brandData) {
            brandData.forEach(b => {
                kvps.push({ key: b.id, value: JSON.parse(b.name).en });
            });
        }              
        return kvps;
    }

    function getProperties() {
        let kvps: { key: string; value: string }[] = [{ key: "ALL", value: "All" }];
        if (propertyData) {
            propertyData.forEach(p => {
                kvps.push({ key: p.id, value: p.name });
            });
        }              
        return kvps;
    } 
    
    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>
                {location.pathname !== "/skin/default/skins"  &&
                    <>
                        <Stack direction="row">
                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">
                                    {strings.brandSelectLabel}
                                </InputLabel>
                                <Select
                                    id="selectFieldPropertyBrandList"
                                    value={selectedBrandId}
                                    label={strings.brandSelectLabel}
                                    onChange={handleBrandSelectionChange}
                                    MenuProps={{
                                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                        transformOrigin: { vertical: "top", horizontal: "left" }
                                    }}
                                >                            
                                    {getBrands().map((kvp, index) => (
                                        <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                                    ))}                         
                                </Select>
                            </FormControl>
                            <Spacer x={2}/>                    
                            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-label">
                                    {strings.propertySelectLabel}
                                </InputLabel>
                                <Select
                                    id="selectFieldPropertyPropertyList"
                                    value={selectedPropertyId}
                                    label={strings.propertySelectLabel}
                                    onChange={handlePropertySelectionChange}
                                    MenuProps={{
                                        anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                        transformOrigin: { vertical: "top", horizontal: "left" }
                                    }}
                                >                         
                                    {getProperties().map((kvp, index) => (
                                        <MenuItem key={index} value={kvp.key}>{kvp.value}</MenuItem>
                                    ))}                             
                                </Select>
                            </FormControl>                    
                        </Stack>
                        <Spacer y={2} />
                    </>
                }                
                <DataGrid
                    permissionKey="administration_skin"
                    rows={skins}
                    columns={gridColumns}
                    pageSize={100}
                    addButtonText="Add Skinning Key"
                    showAddButton={location.pathname === "/skin/default/skins"}
                    onAddButtonClick={handleAddClick}
                    height={600}                    
                    getRowId={(row) => row.key} 
                    processRowUpdate={handleProcessRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        LoadingOverlay : LinearProgress
                    }}
                />

                <DetailsDialog
                        permissionKey="administration_skin"
                        open={addKeyDialogOpen}
                        adding={true}
                        title={"Add Skinning Key"}
                        onCancelClick={handleCancelClick}
                        onSaveClick={handleSaveKeyClick}
                        contentSize={{ width: 439.75, height: 203.205 }}
                    >
                        <Box sx={{ padding: '20px' }}>
                            <TextField
                                id="add-skin-key"
                                label={strings.key}
                                width={300}
                                value={addSkin.key}
                                onChange={handleAddSkinKeyChange}
                            />
                            <Spacer />
                            <TextField
                                id="add-skin-value"
                                label={strings.value}
                                width={300}
                                value={addSkin.value}
                                onChange={handleAddSkinValueChange}
                            />
                        </Box>
                                     
                </DetailsDialog>
           
                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>
        );
    }
};

export default SkinList;
