import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from '@mui/material/styles';
import { GridColumns, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { deleteGraphics } from "../../../api/graphicsApi";
import { deletePromotionItem, getPromotionItems, savePromotionItemDisplayOrder, updatePromotionItem } from "../../../api/promotions/promotionItemsApi";
import { addExistingPromotionItem, createPromotion, createPromotionItem, deletePromotion, getPromotions, removePromotionItem, updatePromotion } from "../../../api/promotions/promotionsApi";
import { deleteVideos } from "../../../api/videoApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { GraphicModel } from "../../../models/common/GraphicModel";
import { VideoModel } from "../../../models/common/VideoModel";
import { PromotionItem as PromotionItemModel, Promotion as PromotionModel, getPromotionItemTypeStringKey, initialPromotionItemState, initialPromotionState } from "../../../models/modules/promotions/Promotion";
import { useUser } from "../../../providers/UserProvider";
import { Type as ValidationType, validate } from "../../../utilities/Validator";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Spacer from "../../common/Spacer";
import TabPanelKeepMounted from "../../common/TabPanelKeepMounted";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridEditButton from "../../common/datatable/DataGridEditButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import DisplayOrderGrid, { DisplayOrderGridRefObject } from "../../common/datatable/DisplayOrderGrid";
import { default as DetailsDialog } from "../../common/details/DetailsDialog";
import RoomClasses from "../../common/details/RoomClasses";
import TextField from "../../common/details/TextField";
import Checkbox from "../../common/details/Checkbox";
import ValidateTab from "../../common/details/ValidateTab";
import GraphicManager, { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import LanguageForm from "../../common/language/LanguageForm";
import LanguageSelect from "../../common/language/LanguageSelect";
import LanguageTextField, { HTMLLanguageInputElement } from "../../common/language/LanguageTextField";
import VideoManager, { VideoManagerRefObject } from "../../common/video/VideoManager";

interface PromotionValidationErrors {
    internalName: string;
}

interface ItemValidationErrors {
    name: string;
    roomClasses: string;
}

const PromotionList = () => {
    const initialPromotionErrorState: PromotionValidationErrors = {
        internalName: "",        
    }

    const initialItemErrorState: ItemValidationErrors = {
        name: "",
        roomClasses: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const [gridRefresh, setGridRefresh] = useState(false);
    const [promotions, setPromotions] = useState<PromotionModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [itemDetailsDialogOpen, setItemDetailsDialogOpen] = useState(false);
    const [existingItemDialogOpen, setExistingItemDialogOpen] = useState(false);
    const [existingItems, setExistingItems] = useState<PromotionItemModel[]>([]);
    const [itemTabValue, setItemTabValue] = useState<number>(0);
    const [selectedPromotionId, setSelectedPromotionId] = useState("");
    const [selectedPromotion, setSelectedPromotion] = useState<PromotionModel>(initialPromotionState);    
    const [selectedPromotionItemId, setSelectedPromotionItemId] = useState("");
    const [selectedPromotionItem, setSelectedPromotionItem] = useState<PromotionItemModel>(initialPromotionItemState);
    const [isLoading, setIsLoading] = useState(true);    
    const [promotionErrors, setPromotionErrors] = useState<PromotionValidationErrors>(initialPromotionErrorState);
    const [itemErrors, setItemErrors] = useState<ItemValidationErrors>(initialItemErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [addItemMenuAnchorEl, setAddItemMenuAnchorEl] = useState<null | HTMLElement>(null);
    const addItemMenuOpen = Boolean(addItemMenuAnchorEl);
    const [deleteItemMenuAnchorEl, setDeleteItemMenuAnchorEl] = useState<null | HTMLElement>(null);
    const deleteItemMenuOpen = Boolean(deleteItemMenuAnchorEl);
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();        
    const itemGridRef = useRef<DisplayOrderGridRefObject>(null);

    useEffect(() => {
        async function loadPromotions() {
            try {
                const payload = await getPromotions(axiosInstance, user.currentProperty?.code ?? "");
                setPromotions(payload);

                var promotion = payload.find(p => p.id === selectedPromotionId);
                if (promotion) {
                    setSelectedPromotion(promotion);                    
                }

            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingPromotions.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }

            try {
                const payload = await getPromotionItems(axiosInstance, user.currentProperty?.code ?? "");
                payload.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
                setExistingItems(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingPromotionItems.replace("{{error}}", error.message),
                    msgType: "error",
                });
            } finally {
                setIsLoading(false);
            }
        }

        loadPromotions();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingPromotions, strings.errorRetreivingPromotionItems]);

    function handleCanPerformAction() {
        return !itemGridRef.current?.isDirty();
    }

    function handleSelectionModelChange(ids: GridSelectionModel) {
        var id = ids[0] as string;

        if (id) {
            setSelectedPromotionId(id);

            var promotion = promotions?.find(p => p.id === id);
            if (promotion) {
                setSelectedPromotion(promotion);
            }
        }
    }

    const dialogTitle = selectedPromotionId === "" ? strings.addPromotionTitle : strings.editPromotionTitle.replace("{{name}}", selectedPromotion?.internalName ?? ""); // TODO: default language

    function handleEditRowClick(id: string) {        
        setSelectedPromotionId(id);
        var promotion = promotions?.find(p => p.id === id);
        if (promotion) {
            setSelectedPromotion(promotion);            
            setDetailsDialogOpen(true);
        }
    }

    function handleAddClick() {
        setSelectedPromotionId("");
        setSelectedPromotion(initialPromotionState);
        setDetailsDialogOpen(true);
    }

    async function deleteSelectedPromotion(id: string) {
        try {
            await deletePromotion(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotion.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.promotionDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedPromotionId("");
        setSelectedPromotion(initialPromotionState);
        setSelectedPromotionItemId("");
        setSelectedPromotionItem(initialPromotionItemState);
    }

    function handleDeleteClick(id: string) {
        if (handleCanPerformAction()) {
            return;
        }

        const internalName = promotions.find(p => p.id === id)?.internalName ?? "";

        alertDialog({
            title: strings.deletePromotionAlertTitle,
            message: strings.deletePromotionAlertMessage.replace("{{name}}", internalName),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedPromotion(id);
        });
    }
   
    function handleInternalNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPromotion((prevState) => ({
            ...prevState,
            internalName: event.target.value,
        }));
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setPromotionErrors(initialPromotionErrorState);
        setDetailsDialogOpen(false);        
    }

    async function handleSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setPromotionErrors(initialPromotionErrorState);

        const isUpdate = selectedPromotionId !== "";
        try {
            if (isUpdate) {
                await updatePromotion(axiosInstance, user.currentProperty?.code ?? "", selectedPromotion);
            } else {
                await createPromotion(axiosInstance, user.currentProperty?.code ?? "", selectedPromotion);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingPromotion
                    : strings.errorUpdatingPromotion
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setDetailsDialogOpen(false);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.promotionSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidatePromotion() {
        var errors = validate<PromotionModel, PromotionValidationErrors>([
            { property: "internalName", type: ValidationType.Required, message: strings.validationPromotionInternalName }
        ], selectedPromotion);
        if (errors) {
            setPromotionErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }
    
    const itemDialogTitle = selectedPromotionItemId === "" ? strings.addPromotionItemTitle : strings.editPromotionItemTitle.replace("{{name}}", selectedPromotionItem?.name?.en ?? ""); // TODO: default language
    
    function handleAddItemClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (getFilteredItems().length > 0) {
            setAddItemMenuAnchorEl(event.currentTarget);
        }
        else {
            handleAddNewItem();
        }
    }

    function handleAddItemMenuClose() {
        setAddItemMenuAnchorEl(null);        
    }

    function handleAddExistingItemClick(id: string) {
        var item = existingItems?.find(p => p.id === id);
        if (item) {
            setSelectedPromotionItem(item);
            saveExistingItem(item);
        }

        setSelectedPromotionItemId("");        
        setAddItemMenuAnchorEl(null);
    }

    function handleAddNewItem() {
        setSelectedPromotionItemId("");
        setItemDetailsDialogOpen(true);
        setAddItemMenuAnchorEl(null);
    }

    function handleItemDeleteClick(id: string, event: React.MouseEvent<HTMLButtonElement>) {
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        setSelectedPromotionItemId(id);
        setDeleteItemMenuAnchorEl(event.currentTarget);
    }

    function handleDeleteItemMenuClose() {
        setDeleteItemMenuAnchorEl(null);
    }

    async function deleteSelectedPromotionItem(id: string) {
        const images: GraphicModel[] = [
            { imageKey: "main", url: "", fileData: null },
            { imageKey: "banner", url: "", fileData: null },
        ];
        const videos: VideoModel[] = [
            { videoKey: "main", url: "", fileData: null },
            { videoKey: "banner", url: "", fileData: null },
        ];


        try {
            await deleteGraphics(axiosInstance, "promotionItem", selectedPromotionItemId, images, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deleteVideos(axiosInstance, "promotionItem", selectedPromotionItemId, videos, (user.currentProperty === null || user.currentProperty === undefined) ? undefined : user.currentProperty!!.id);
            await deletePromotionItem(axiosInstance, user.currentProperty?.code ?? "", id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingPromotionItem.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.promotionItemDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedPromotionItemId("");
        setSelectedPromotionItem(initialPromotionItemState);
    }

    async function removeSelectedPromotionItem(id: string) {
        try {
            await removePromotionItem(axiosInstance, user.currentProperty?.code ?? "", selectedPromotionId, id);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRemovingPromotionItem.replace("{{error}}", error.message),
                msgType: "error",
            });

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.promotionItemRemovedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedPromotionItemId("");
        setSelectedPromotionItem(initialPromotionItemState);
    }

    function handleRemoveItem() {
        setDeleteItemMenuAnchorEl(null);
        const name = selectedPromotion?.items?.find(i => i.id === selectedPromotionItemId)?.name?.en ?? "";

        alertDialog({
            title: strings.removePromotionItemAlertTitle,
            message: strings.removePromotionItemAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.removeButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            removeSelectedPromotionItem(selectedPromotionItemId);
        });
    }

    function handleDeleteItem() {        
        setDeleteItemMenuAnchorEl(null);
        const name = selectedPromotion?.items?.find(i => i.id === selectedPromotionItemId)?.name?.en ?? "";

        alertDialog({
            title: strings.deletePromotionItemAlertTitle,
            message: strings.deletePromotionItemAlertMessage.replace("{{name}}", name),
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedPromotionItem(selectedPromotionItemId);
        });
    }

    function handleItemEditRowClick(id: string) {       
        if (itemGridRef.current?.isDirty()) {
            return;
        }

        var promotionItem = selectedPromotion?.items?.find(i => i.id === id);
        if (promotionItem) {
            setSelectedPromotionItemId(id);
            setSelectedPromotionItem(promotionItem);
            setItemDetailsDialogOpen(true);
        }
    }

    function handleItemTabChange(event: React.SyntheticEvent, newValue: number) {        
        setItemTabValue(newValue);        
    }

    function handleItemNameChange(event: React.ChangeEvent<HTMLLanguageInputElement>) {
        setSelectedPromotionItem((prevState) => ({
            ...prevState,
            name: {
                ...prevState.name,
                [event.target.language]: event.target.value,
            },
        }));
    }

    function handlePreviewOnlyChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedPromotionItem((prevState) => ({
            ...prevState,
            previewOnly: event.target.checked,
        }));
    }

    function handleItemRoomClassesChange(values: { id: string; name: string }[]) {
        setSelectedPromotionItem((prevState) => ({
            ...prevState,
            roomClasses: values
        }));
    }

    function handleItemCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors(initialItemErrorState);
        setItemDetailsDialogOpen(false);
        setItemTabValue(0);
        setSelectedPromotionItem(initialPromotionItemState);
    }

    async function handleItemSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
        setItemErrors(initialItemErrorState);

        var id = selectedPromotionItemId;
        const isUpdate = selectedPromotionItemId !== "";
        try {
            if (isUpdate) {
                await updatePromotionItem(axiosInstance, user.currentProperty?.code ?? "", selectedPromotionItem);
            } else {
                id = await createPromotionItem(axiosInstance, user.currentProperty?.code ?? "", selectedPromotionId, selectedPromotionItem);
                setSelectedPromotionItemId(id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: (!isUpdate
                    ? strings.errorAddingPromotionItem
                    : strings.errorUpdatingPromotionItem
                ).replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        try {
            if (graphicManagerRef.current) {
                await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        try {
            if (videoManagerRef.current) {
                await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
            }
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                msgType: "error",
            });

            return false;
        }

        setItemDetailsDialogOpen(false);
        setSelectedPromotionItem(initialPromotionItemState);
        setItemTabValue(0);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.promotionItemSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidateItem() {
        var errors = validate<PromotionItemModel, ItemValidationErrors>([
            { property: "name.en", type: ValidationType.Required, message: strings.validationPromotionItemName }            
        ], selectedPromotionItem);
        if (errors) {
            setItemErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    async function saveExistingItem(item: PromotionItemModel) {
        setItemErrors(initialItemErrorState);

        try {
            await addExistingPromotionItem(axiosInstance, user.currentProperty?.code ?? "", selectedPromotionId, item);
        } catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorAddingPromotionItem.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }

        setExistingItemDialogOpen(false);
        setSelectedPromotionItem(initialPromotionItemState);
        setGridRefresh(!gridRefresh);
        setNotify({
            isOpen: true,
            message: strings.promotionItemSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    /*function handleSaveDisplayOrder(ids: any[]) {
        // https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array
        const items = [...selectedPromotion.items];
        items.sort(function(a, b) {  
            return ids.findIndex(id => a.id === id) - ids.findIndex(id => b.id === id);
        });
        updateOrder(items);        
    }
    
    function updateOrder(itemList: PromotionItemModel[]) {    */
    function handleSaveDisplayOrder(idList: any[]) {   
        //const idList = itemList.map(topic => topic.id);
        savePromotionItemDisplayOrder(axiosInstance, user.currentProperty?.code ?? "", selectedPromotionId, idList)
            .then(() => setGridRefresh(!gridRefresh))
            .catch((e: unknown) => {                
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorUpdatingPromotionItem.replace("{{error}}", error.message),
                    msgType: "error",
                });
            })
    }

    const gridColumns: GridColumns = [        
        { field: "internalName", headerName: strings.name, flex: 1 },        
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_promotion" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_promotion"
                    rowId={params.id.toString()}
                    clickHandler={() => handleDeleteClick(params.id.toString())}
                />,
            ],
        },
    ];

    const gridItemColumns: GridColumns = [
        { field: "type", headerName: strings.type, valueGetter: (params) => strings.getString(getPromotionItemTypeStringKey(params.row.type)), flex: 1 },
        { field: "name", headerName: strings.name, valueGetter: (params) => params.row.name.en, flex: 2 },
        { field: "previewOnly", headerName: strings.previewOnly, type: "boolean", valueGetter: (params) => params.row.previewOnly, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [
                <DataGridEditButton 
                    permissionKey="content_promotion" 
                    rowId={params.id.toString()} 
                    clickHandler={() => handleItemEditRowClick(params.id.toString())} 
                />,
                <DataGridDeleteButton
                    permissionKey="content_promotion"
                    rowId={params.id.toString()}
                    clickHandler={(id: string, event: React.MouseEvent<HTMLButtonElement>) => handleItemDeleteClick(params.id.toString(), event)}
                />,
            ],
        },
    ];

    function getFilteredItems() {
        const selectedIds = selectedPromotion.items.map(i => i.id);
        return existingItems.filter(i => !selectedIds.includes(i.id));
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>           
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DataGrid
                            permissionKey="content_promotion"
                            rows={promotions}                               
                            columns={gridColumns}
                            canPerformAction={handleCanPerformAction}
                            onSelectionModelChange={handleSelectionModelChange}
                            onAddButtonClick={handleAddClick}
                            addButtonText={strings.addPromotionButton}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DisplayOrderGrid
                            permissionKey="content_promotion"
                            rows={selectedPromotion.items}                            
                            columns={gridItemColumns}
                            addButtonDisabled={selectedPromotionId === ""}
                            onAddButtonClick={handleAddItemClick}
                            addButtonText={strings.addPromotionItemButton}
                            onSaveDisplayOrder={handleSaveDisplayOrder}
                            ref={itemGridRef}
                        />
                    </Grid>
                </Grid>

                <Menu
                    id="addItemMenu"
                    MenuListProps={{
                        'aria-labelledby': 'buttonAddItem',
                    }}
                    anchorEl={addItemMenuAnchorEl}
                    open={addItemMenuOpen}
                    onClose={handleAddItemMenuClose}
                >                    
                    <MenuItem onClick={handleAddNewItem}>{strings.addPromotionItemButton}</MenuItem>                                        
                    {getFilteredItems().map((item, index) => (
                        <>
                            {index === 0 && <Divider />}
                            <MenuItem key={item.id} value={item.id} onClick={() => handleAddExistingItemClick(item.id)}>                            
                                <ListItemText>
                                    {item.name?.en ?? ""}
                                </ListItemText>
                            </MenuItem> 
                        </>                        
                    ))} 
                </Menu>

                <Menu
                    id="deleteItemMenu"
                    MenuListProps={{
                        'aria-labelledby': 'buttonDeleteItem',
                    }}
                    anchorEl={deleteItemMenuAnchorEl}
                    open={deleteItemMenuOpen}
                    onClose={handleDeleteItemMenuClose}
                >
                    <MenuItem onClick={handleRemoveItem}>{strings.removeButtonTitle}</MenuItem>
                    <MenuItem onClick={handleDeleteItem}>{strings.deleteButtonTitle}</MenuItem>
                </Menu>

                <DetailsDialog
                    permissionKey="content_promotion"
                    open={detailsDialogOpen}
                    adding={selectedPromotionId === ""}
                    title={dialogTitle}
                    onValidateForm={handleValidatePromotion}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                    contentSize={{ width: 300, height: 102.91 }}
                >
                    <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                        <TextField
                            id="internal-name"
                            value={selectedPromotion.internalName}
                            label={strings.name}
                            width={250}                            
                            onChange={handleInternalNameChange}                            
                            error={Boolean(promotionErrors.internalName)}
                            helperText={promotionErrors.internalName}
                        />
                    </Box>
                </DetailsDialog>

                <DetailsDialog
                    permissionKey="content_promotion"
                    adding={selectedPromotionItemId === ""}
                    open={itemDetailsDialogOpen}
                    title={itemDialogTitle}
                    onValidateForm={handleValidateItem}
                    onCancelClick={handleItemCancelClick}
                    onSaveClick={handleItemSaveClick}
                    contentSize={{ width: 879.5, height: 512.56 }}
                >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={itemTabValue}
                            onChange={handleItemTabChange}
                            aria-label="basic tabs example"
                        >
                            <ValidateTab label={strings.details} errors={Boolean(itemErrors.name)} />
                            <Tab label={strings.graphics} />
                            <Tab label={strings.videos} />
                            <Tab label={strings.roomClasses} />
                        </Tabs>
                    </Box>
                    <TabPanelKeepMounted value={itemTabValue} index={0}>
                        <LanguageForm>
                            <LanguageSelect />
                            <Spacer />
                            <LanguageTextField
                                id="item-name"
                                label={strings.name}
                                values={selectedPromotionItem.name ?? {}}
                                width={410}
                                onChange={handleItemNameChange}
                                error={Boolean(itemErrors.name)}
                                helperText={itemErrors.name}
                            />
                            <Spacer />
                            <Checkbox  
                                label={strings.previewOnly}
                                checked={selectedPromotionItem.previewOnly ?? false}
                                onChange={handlePreviewOnlyChange}                                                                
                            />
                        </LanguageForm>
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={itemTabValue} index={1}>
                        <GraphicManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="promotionItem"
                            itemKey={selectedPromotionItem.id}
                            imageKeys={["banner"]}                            
                            ref={graphicManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={itemTabValue} index={2}>
                        <VideoManager
                            propertyId={(user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id}
                            itemType="promotionItem"
                            itemKey={selectedPromotionItem.id}
                            videoKeys={["banner"]}                            
                            ref={videoManagerRef}
                        />
                    </TabPanelKeepMounted>
                    <TabPanelKeepMounted value={itemTabValue} index={3}>
                        <RoomClasses
                            assignedClasses={selectedPromotionItem.roomClasses}
                            onChangeHandler={handleItemRoomClassesChange}                            
                            height={453.56}
                            error={Boolean(itemErrors.roomClasses)}
                            helperText={itemErrors.roomClasses}
                        />
                    </TabPanelKeepMounted>
                </DetailsDialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }    
};

export default PromotionList;
