import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from '@mui/icons-material/Image';
import PeopleIcon from '@mui/icons-material/People';
import RttIcon from '@mui/icons-material/Rtt';
import VideocamIcon from '@mui/icons-material/Videocam';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import MuiIconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Paper, { PaperProps } from "@mui/material/Paper";
import { useTheme } from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { GridActionsCellItem, GridColumns, GridRowParams } from "@mui/x-data-grid";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { createMessage, deleteMessage, getMessage, getSentMessages } from "../../../api/messaging/messagesApi";
import { createTemplate, getTemplates } from "../../../api/messaging/templatesApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import useGuestSelectorData from "../../../hooks/useGuestSelectorData";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { Access } from "../../../models/configuration/security/Permission";
import { getInitialMessageState, Message as MessageModel, SentMessage as SentMessageModel } from "../../../models/modules/messaging/Message";
import { MessageType } from "../../../models/modules/messaging/MessageType";
import { mapTemplatesToKeyValues, Template as TemplateModel } from "../../../models/modules/messaging/Template";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import { useAlertDialog } from "../../common/AlertDialog/AlertDialogProvider";
import DataGridDeleteButton from "../../common/datatable/DataGridDeleteButton";
import DataGridViewButton from "../../common/datatable/DataGridViewButton";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { GraphicManagerRefObject } from "../../common/image/GraphicManager";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Message from "./Message";
import { VideoManagerRefObject } from "../../common/video/VideoManager";
import { Select } from "@mui/material";

const useStyles = makeStyles({
    draggableDialog: {
        cursor: "move",
    },
    dialogContent: {
        cursor: "default",
    },
    appBarSpacer: {
        ...themePrimary.mixins.toolbar,
    },
});

interface ValidationErrors {
    from: string;
    sendAt: string;
    subject: string;
    text: string;
}

interface SentProps {
    onGridRefresh: () => void;
}

const Sent = (props: SentProps) => {
     const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const initialErrorState: ValidationErrors = {
        from: "",
        sendAt: "",
        subject: "",
        text: ""
    }

    const [gridRefresh, setGridRefresh] = useState(false);
    const [messages, setMessages] = useState<SentMessageModel[]>([]);
    const [templates, setTemplates] = useState<TemplateModel[]>([]);
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
    const [isCustom, setIsCustom] = useState(false);
    const [type, setType] = useState<MessageType>("Text");
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
    const [saveTemplateDialogOpen, setSaveTemplateDialogOpen] = useState(false);
    const [saveTemplateOkButtonDisabled, setSaveTemplateOkButtonDisabled] = useState(false);
    const [templateInternalName, setTemplateInternalName] = useState("");
    const [templateError, setTemplateError] = useState("");
    const [recipientsDialogOpen, setRecipientsDialogOpen] = useState(false);
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [selectedMessage, setSelectedMessage] = useState<MessageModel>(getInitialMessageState());
    const [isLoading, setIsLoading] = useState(true);        
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [errors, setErrors] = useState<ValidationErrors>(initialErrorState);
    const [newMessageMenuAnchor, setNewMessageMenuAnchor] = useState<null | HTMLElement>(null);
    const newMessageMenuOpen = Boolean(newMessageMenuAnchor);
    const graphicManagerRef = useRef<GraphicManagerRefObject>(null);
    const videoManagerRef = useRef<VideoManagerRefObject>(null);
    const alertDialog = useAlertDialog();
    const strings = useLocalizedStrings();
    const theme = useTheme();    
    const axiosInstance = useCreateAxios();
    const { user } = useUser();  
    const classes = useStyles();
    const {selectorIdsToValues, formattedSelectorValues} = useGuestSelectorData("en");
  
    useEffect(() => {
        async function loadMessages() {
            try {
                const payload = await getSentMessages(axiosInstance, user.currentProperty?.code ?? "");
                setMessages(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingMessages.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }             

            try {
                const payload = await getTemplates(axiosInstance, user.currentProperty?.code ?? "", true);                
                setTemplates(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetreivingTemplates.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }

            setIsLoading(false);
        }

        loadMessages();
    }, [gridRefresh, user.currentProperty?.code, strings.errorRetreivingMessages, strings.errorRetreivingTemplates]);

    async function getSelectedMessage(id: string) {
        try {
            const payload = await getMessage(axiosInstance, user.currentProperty?.code ?? "", id);
            setSelectedMessage(payload);
            setType(payload.type);
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorRetreivingMessages.replace("{{error}}", error.message),
                msgType: "error",
            });
        } 
    }

    function handleNewMessageClick(event: React.MouseEvent<HTMLButtonElement>) {
        setNewMessageMenuAnchor(event.currentTarget);
    }

    function handleNewMessageMenuClose() {
        setNewMessageMenuAnchor(null);
    }

    function getPropertyTimeZoneId() {
        return user.currentProperty?.timeZoneId;
    }

    function handleCustomMessageClick(type: MessageType) {
        setIsCustom(true);
        setType(type);
        setSelectedTemplateId(null);
        setNewMessageMenuAnchor(null);
        setSelectedMessageId("");
        setSelectedMessage(getInitialMessageState(type, getPropertyTimeZoneId()));
        setDetailsDialogOpen(true);
    }

    function handleTemplateMessageClick(id: string) {
        setIsCustom(false);        
        setSelectedTemplateId(id);
        setNewMessageMenuAnchor(null);

        var template = templates?.find(m => m.id === id);
        if (template) {
            var message: MessageModel = {
                id: "",
                from: template.from,                
                sendAt: new Date(new Date().toLocaleString("en-US", { timeZone: getPropertyTimeZoneId() })),
                subject: template.subject,
                isHighImportance: template.isHighImportance,
                type: template.type,
                text: template.text
            }
            setType(template.type);
            setSelectedMessage(message);
            setDetailsDialogOpen(true);
        }
    }

    function handleDeleteClick(id: string) {        
        alertDialog({
            title: strings.deleteMessageAlertTitle,
            message: strings.deleteMessageAlertMessage,
            destructive: true,
            okButtonTitle: strings.deleteButtonTitle,
            cancelButtonTitle: strings.cancelButtonTitle,
        }).then(() => {
            deleteSelectedMessage(id);
        });
    }    

    async function deleteSelectedMessage(id: string) {
        try {
            await deleteMessage(axiosInstance, user.currentProperty?.code ?? "", id);
        }                
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorDeletingMessage.replace("{{error}}", error.message),
                msgType: "error",
            });       

            return;
        }

        setNotify({
            isOpen: true,
            message: strings.messageDeletedSuccessfully,
            msgType: "success",
        });

        setGridRefresh(!gridRefresh);
        setSelectedMessageId("");
        setSelectedMessage(getInitialMessageState());
    }

    async function handleViewClick(id: string) {  
        setSelectedMessageId(id);
        await getSelectedMessage(id);        
        setDetailsDialogOpen(true);
    }   

    async function handlRecipientsClick(id: string) {  
        setSelectedMessageId(id);
        await getSelectedMessage(id);
        setRecipientsDialogOpen(true);  
    }       

    function handleInternalNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTemplateInternalName(event.target.value);
    }

    function handleCancelTemplateClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSaveTemplateDialogOpen(false);
        setDetailsDialogOpen(false);        
        setSelectedMessage(getInitialMessageState());
    }

    async function handleSaveTemplateClick(event: React.MouseEvent<HTMLButtonElement>) {        
        if (saveTemplateOkButtonDisabled) {
            return;
        }

        if (templateInternalName.trim() === "") {
            setTemplateError(strings.validationMessageTemplateName);
            return;
        }
        else {
            setTemplateError("");
        }        

        setSaveTemplateOkButtonDisabled(true);

        const newTemplate: TemplateModel = {
            id: "",
            internalName: templateInternalName,
            from: selectedMessage.from,
            subject: selectedMessage.subject,
            isHighImportance: selectedMessage.isHighImportance,
            type: type,
            text: selectedMessage.text,
            enabled: true
        }

        var id = "";
        var errored = false;

        try {            
            id = await createTemplate(axiosInstance, user.currentProperty?.code ?? "", newTemplate);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorCreatingMessageTemplate.replace("{{error}}", error.message),
                msgType: "error",
            });    
            
            errored = true;
        }
        
        if (!errored) {
            try {                
                if (graphicManagerRef.current && graphicManagerRef.current.isDirty()) {                
                    await graphicManagerRef.current.copyGraphic("message", selectedMessage.id, id, "main", user.currentProperty?.code ?? "");                
                }
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });

                errored = true;
            }
        }

        setSelectedMessage(getInitialMessageState());       
        setSaveTemplateDialogOpen(false);
        setSaveTemplateOkButtonDisabled(false);
        setDetailsDialogOpen(false);

        if (!errored) {
            setNotify({
                isOpen: true,
                message: strings.messageTemplateSavedSuccessfully,
                msgType: "success",
            });
        }
    }

    interface DataGridRecipientsButtonProps {
        rowId: string;
        clickHandler: (id: string, event: React.MouseEvent<HTMLButtonElement>) => void;
        permissionKey?: string;
    }

    const DataGridRecipientsButton = (props: DataGridRecipientsButtonProps) => {
        const strings = useLocalizedStrings();
        const { checkAccess } = useUser();    

        return (
            <div>
                {checkAccess(props.permissionKey, Access.Read) &&
                    <GridActionsCellItem
                        icon={<PeopleIcon titleAccess={strings.recipients} sx={{ color: "primary.dark" }} />}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.clickHandler(props.rowId, event)}
                        label={strings.recipients}
                    />
                }
            </div>
        );
    }

    function mapStatus(status: number) {
        switch (status) {
            case 0:
                return strings.statusInReview;
            case 1:
                return strings.statusScheduled;
            case 2:
                return strings.statusSent;
            default:
                return "";
        }
    }

    function buildSubjectField(row: SentMessageModel) {
        return (
            <>
                <span style={{ marginRight: theme.spacing(1) }}>{getIcon(row.type)}</span>
                <span>{row.subject?.en}</span>
            </>
        );
    }

    const gridColumns: GridColumns = [        
        { field: "from", headerName: strings.from, valueGetter: (params) => params.row.from.en, flex: 1 },
        { field: "toData", headerName: strings.to, valueGetter: (params) => formattedSelectorValues(selectorIdsToValues(params.row.toData), strings), flex: 3 },
        { field: "subject", headerName: strings.subject, renderCell: (params) => buildSubjectField(params.row), flex: 3 },
        //{ field: "status", headerName: strings.status, valueGetter: (params) => mapStatus(params.row.status), flex: 1 },
        { field: "sendAt", headerName: strings.sendDate, valueGetter: (params) => params.row.sendAt, valueFormatter: ({ value }) => value.toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute:'2-digit'}), flex: 1 },
        { field: "readRate", headerName: strings.readRate, valueGetter: (params) => `${params.row.readRate}%`, flex: 1 },
        {
            field: "actions",
            type: "actions",
            headerName: strings.gridActions,
            flex: 1,
            getActions: (params: GridRowParams) => [                
                <DataGridViewButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={handleViewClick}
                />,
                <DataGridRecipientsButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={handlRecipientsClick}
                />,
                <DataGridDeleteButton
                    permissionKey="content_messaging"
                    rowId={params.id.toString()}
                    clickHandler={handleDeleteClick}
                />
            ],
        },
    ];

    function handleMessageChange(message: MessageModel) {
        setSelectedMessage(message);
    }

    function handleCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setSelectedMessage(getInitialMessageState());
        setErrors(initialErrorState);        
        setDetailsDialogOpen(false); 
        setSelectedMessageId("");
    }

    async function handleSendClick(event: React.MouseEvent<HTMLButtonElement>) {        
        var id = "";

        try {            
            id = await createMessage(axiosInstance, user.currentProperty?.code ?? "", selectedMessage);    
            setSelectedMessage((prevState) => ({
                ...prevState,
                id: id
            }));            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorCreatingMessage.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }      
        
        if (selectedMessage.type === "Image") {
            try {
                if (graphicManagerRef.current) {
                    if (selectedTemplateId == null || graphicManagerRef.current.isDirty()) {
                        await graphicManagerRef.current.saveGraphics(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
                    }
                    else {
                        await graphicManagerRef.current.copyGraphic("message", selectedTemplateId, id, "main", user.currentProperty?.code ?? "");
                    }                
                }
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingGraphic.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }
        else if (selectedMessage.type === "Video") {
            try {
                if (videoManagerRef.current) {        
                    if (selectedTemplateId == null || videoManagerRef.current.isDirty()) {
                        await videoManagerRef.current.saveVideos(id, (user.currentProperty === undefined || user.currentProperty === null) ? undefined : user.currentProperty!!.id);
                    }
                    else {
                        await videoManagerRef.current.copyVideo("message", selectedTemplateId, id, "main", user.currentProperty?.code ?? "");
                    }
                }
            } catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorSavingVideo.replace("{{error}}", error.message), // TODO: get error message
                    msgType: "error",
                });

                return false;
            }
        }
                
        setGridRefresh(!gridRefresh);
        props.onGridRefresh();
        setErrors(initialErrorState);
        setNotify({
            isOpen: true,
            message: strings.messageSentSuccessfully,
            msgType: "success",
        });

        if (isCustom) {
            setSaveTemplateDialogOpen(true);
        }
        else {
            setDetailsDialogOpen(false);
            setSelectedMessage(getInitialMessageState());
        }

        return true;
    }

    function PaperComponent(props: PaperProps) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={"[class*='MuiDialogContent-root']"}
            >
                <Paper {...props} />
            </Draggable>
        );
    }

    const gridRecipientColumns: GridColumns = [        
        { field: "guestId", headerName: strings.externalId, valueGetter: (params) => params.row.externalKey, flex: 1 },
        { field: "name", headerName: strings.name, valueGetter: (params) => `${params.row.lastName}, ${params.row.firstName}`, flex: 1 },
        { field: "isRead", headerName: strings.isRead, type: "boolean", flex: 1 },
        { field: "readDate", headerName: strings.readDate, valueGetter: (params) => params.row.readDate, valueFormatter: ({ value }) => value?.toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute:'2-digit'}), flex: 1 }        
    ];
    
    function handleRecipientsCloseClick(event: React.MouseEvent<HTMLButtonElement>) {
        setRecipientsDialogOpen(false);  
    }

    function getIcon(type: string) {
        switch (type) {            
            case "Image":
                return (<ImageIcon fontSize="small" />);
            case "Video":
                return (<VideocamIcon fontSize="small" />);
            case "Text":
            default:
                return (<RttIcon fontSize="small" />);
        }
    }

    if (isLoading) {
        return <LinearProgress color={"primary"} variant={"query"} />;
    }
    else {
        return (
            <Box sx={{ padding: theme.spacing(2) }}>            
                <DataGrid
                    permissionKey="content_messaging"
                    rows={messages}
                    columns={gridColumns}                    
                    onAddButtonClick={handleNewMessageClick}
                    addButtonText={strings.newMessage}
                />
               
                <Menu
                    id="newMessageMenu"
                    MenuListProps={{
                        'aria-labelledby': 'newMessage',
                    }}
                    anchorEl={newMessageMenuAnchor}
                    open={newMessageMenuOpen}
                    onClose={handleNewMessageMenuClose}
                >
                    <MenuItem key="newText" onClick={() => handleCustomMessageClick("Text")}>
                        <ListItemIcon>
                            {getIcon("Text")}
                        </ListItemIcon>
                        <ListItemText>
                            {strings.newTextMessage}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem key="newImage" onClick={() => handleCustomMessageClick("Image")}>
                        <ListItemIcon>
                            {getIcon("Image")}
                        </ListItemIcon>
                        <ListItemText>
                            {strings.newImageMessage}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem key="newVideo" onClick={() => handleCustomMessageClick("Video")}>
                        <ListItemIcon>
                            {getIcon("Video")}
                        </ListItemIcon>
                        <ListItemText>
                            {strings.newVideoMessage}
                        </ListItemText>
                    </MenuItem>
                    {templates.length > 0 && <Divider />}
                    {mapTemplatesToKeyValues(templates).map((kvp, index) => (
                        <MenuItem key={kvp.key} value={kvp.key} onClick={() => handleTemplateMessageClick(kvp.key)}>
                            <ListItemIcon>
                                {getIcon(kvp.value.type)}
                            </ListItemIcon>
                            <ListItemText>
                                {kvp.value.name}
                            </ListItemText>
                        </MenuItem>
                    ))}                      
                </Menu>

                <Message 
                    open={detailsDialogOpen}
                    type={type}
                    message={selectedMessage} 
                    templateId={selectedTemplateId}
                    graphicManagerRef={graphicManagerRef}
                    videoManagerRef={videoManagerRef}
                    onChange={handleMessageChange}
                    onCancelClick={handleCancelClick}
                    onSendClick={handleSendClick}
                    readOnly={selectedMessageId !== ""}
                />

                <Dialog
                    open={saveTemplateDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {strings.saveTemplateAlertTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {strings.saveTemplateAlertMessage}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label={strings.messageTemplateName}                            
                            fullWidth
                            variant="standard"
                            onChange={handleInternalNameChange}
                            error={Boolean(templateError)}
                            helperText={templateError}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelTemplateClick}>{strings.cancelButtonTitle}</Button>
                        <Button onClick={handleSaveTemplateClick} disabled={saveTemplateOkButtonDisabled}>{strings.saveButtonTitle}</Button>
                    </DialogActions>
                </Dialog>             

                <Dialog
                    scroll={"body"}
                    open={recipientsDialogOpen}
                    onClose={handleRecipientsCloseClick}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={{
                        sx: {
                            width: 1000,
                            maxWidth: 1000,
                            height: 848,
                        },
                    }} // (173.5 is height of headers and footer) https://medium.com/the-clever-dev/how-to-size-and-position-the-material-ui-mui-dialog-component-a5601cedc1c9
                    fullWidth={true}
                >
                    <AppBar /* style={{ cursor: "move" }} */ id="draggable-dialog">
                        {/* Try to get default padding (themePrimary.mixins.toolbar?) and subtract the X button margin from the right (it has a bult in padding of 8px that you can see when you highlight). Hard coding 24 and 12 is the only way to get the X to look correct. */}
                        <Toolbar
                            disableGutters
                            sx={{ paddingLeft: "24px", paddingRight: "24px" }}
                        >
                            <Typography variant="h6" noWrap component="div">
                                {strings.recipients}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box>
                                <MuiIconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="large"
                                    edge="end"
                                    onClick={handleRecipientsCloseClick}
                                >
                                    <CloseIcon />
                                </MuiIconButton>
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.appBarSpacer}></div>
                    <DataGrid
                        sx={{ marginTop: theme.spacing(2), marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                        permissionKey="content_messaging"
                        rows={selectedMessage.recipients ?? []}
                        getRowId={(row) => row.externalKey}
                        columns={gridRecipientColumns}
                        showAddButton={false}
                    />
                    <DialogActions
                        sx={{ position: "absolute", bottom: "8px", right: "8px" }}
                    >
                    <Button variant="outlined" onClick={handleRecipientsCloseClick}>
                        {strings.closeButtonTitle}
                    </Button>                    
                </DialogActions>
                </Dialog>

                <NotificationMessage notificationState={[notify, setNotify]} />
            </Box>           
        )
    }
}

export default Sent;