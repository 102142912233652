import { Button, Card, Typography, styled } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { default as DataGrid } from "../../common/datatable/DataGridWrapper";
import { MouseEventHandler, useEffect, useState } from "react";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Box from '@mui/material/Box';
import { GridColumns, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import DataGridViewButton from "../../common/datatable/DataGridViewButton";
import { CircuitStatsByProperty } from "../../../models/dashboard/support/CircuitStatsByProperty";
import { CircuitStats } from "../../../models/dashboard/support/CircuitStats";
import TimelineIcon from '@mui/icons-material/Timeline';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import DateSearchField from "../../common/search/DateSearchField";
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme: Theme) => ({
    monthCard: {
        border: '1px solid ' + themePrimary.palette.grey[400],
        marginBottom: themePrimary.spacing(2),
    },
    monthHeader: {
        padding: themePrimary.spacing(1),
        display: 'grid',
        gridTemplateColumns: '50% 50%',
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },
    statsCard: {
        padding: themePrimary.spacing(1),
        borderWidth: '1px !important',
        backgroundColor: themePrimary.palette.grey[200] + " !important",
        margin: themePrimary.spacing(1),
    },
    statsGrid: {
        display: 'grid',
        gridTemplateColumns: '16% 16% 16% 16% 16% 17%',
        textAlign: 'center',
        columnGap:  themePrimary.spacing(1),
    },
    statsDetailsGrid: {
        display: 'grid',
        gridTemplateColumns: '70% 30%',
        textAlign: 'center',
    },
    statsTextLabel: {
        textAlign: 'left',
    },
}));

export interface PropertyCircuitStatsProps {
    propertyId: string;
    propertyName: string;
    stats: CircuitStats[];
    onViewDailyBreakDown: (stats: CircuitStats, propertyId: string, propertyName: string) => void
    onViewMonthGraph: (propertyId: string, propertyName: string) => void;
}

const PropertyCircuitStats = (props: PropertyCircuitStatsProps) => {

    const strings = useLocalizedStrings();
    const theme = useTheme();  
    const classes = useStyles(theme);

    const getDate = (month: number, year: number): string => {
        var date = ""

        if (month === 1) {
            date = strings.january
        }
        else if (month === 2) {
            date = strings.february
        }
        else if (month === 3) {
            date = strings.march
        }
        else if (month === 4) {
            date = strings.april
        }
        else if (month === 5) {
            date = strings.may
        }
        else if (month === 6) {
            date = strings.june
        }
        else if (month === 7) {
            date = strings.july
        }
        else if (month === 8) {
            date = strings.august
        }
        else if (month === 9) {
            date = strings.september
        }
        else if (month === 10) {
            date = strings.october
        }
        else if (month === 11) {
            date = strings.november
        }
        else if (month === 12) {
            date = strings.december
        }

        return date + " " + year.toString()
    }

    return (
            <div>   
                
                {props.stats.map(s =>
                    <div className={classes.monthCard}>
                        <div className={classes.monthHeader}>
                            <Typography variant="body1">
                                <span style={{fontWeight: 'bold'}}>{getDate(s.month, s.year)}</span>
                                <span>{" (Circuit Size: " + s.circuitSize + ")"}</span>
                            </Typography>
                            <div style={{justifySelf: 'end'}} >
                                <Button variant="outlined" size="small" style={{justifySelf: 'end',width: '180px',}} startIcon={<HorizontalSplitIcon />}
                                    onClick={() => {props.onViewDailyBreakDown(s, props.propertyId, props.propertyName)}}>
                                    {strings.circuitDailyBreakdown}
                                </Button>
                                <Button variant="outlined" size="small" style={{justifySelf: 'end',width: '180px',marginLeft:themePrimary.spacing(1)}} startIcon={<TimelineIcon />}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {props.onViewMonthGraph(props.propertyId, props.propertyName);event.stopPropagation();}}>
                                    {strings.circuitMonthGraph}
                                </Button>
                            </div>
                        </div>
                        <div className={classes.statsGrid}>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInbound0To2}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysInbound0To2.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutbound0To2}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound0To2.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInbound2To30}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysInbound2To30.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutbound2To30}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound2To30.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInbound30To50}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound30To50.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutbound30To50}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound30To50.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInbound50To70}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysInbound50To70.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutbound50To70}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound50To70.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInbound70To95}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysInbound70To95.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutbound70To95}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutbound70To95.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            <div>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysInboundOver95}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysInboundOver95.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                                <Card variant="outlined" className={classes.statsCard}>
                                    <div>
                                        <div className={classes.statsDetailsGrid}>
                                            <Typography variant="body1" component="div" className={classes.statsTextLabel}>
                                                {strings.daysOutboundOver95}
                                            </Typography>
                                            <Typography variant="body1" component="div" style={{textAlign: 'right', marginRight: '10dp'}}>
                                                {s.daysOutboundOver95.toString()}
                                            </Typography>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                            
                        </div>
                        
                    </div>
                    )
                }
            </div>
    );
}

export default PropertyCircuitStats;