import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { getBandwidthGraph } from "../../../api/dashboard/network/networkMonitorApi";
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import { useUser } from "../../../providers/UserProvider";
import themePrimary from "../../../styles/themePrimary";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import Box from '@mui/material/Box';
import { BandwidthDateSearchFilter } from "../../../models/dashboard/network/BandwidthDateSearchFilter";
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField";
import { getDate } from "date-fns";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { default as MaterialTextField } from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { start } from "repl";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) => ({
    dateFields: {
        marginLeft: '20px',
    },
    graphContainer: {
        width: 1000,
        height: 532
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },
    hidden: {
        visibility: 'hidden',
    },
    visible: {
        visibility: 'visible',
    }
}));

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

const getDefaultSearchStartDateLastWeek = (): Date => {
    var date = new Date();
    date.setDate(getDate(date) - 7);

    return date;
}

const Bandwidth = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const theme = useTheme();  
    const classes = useStyles(theme);
    const [baseUrl, setBaseUrl] = useState<string>("");
    const [graphUrl, setGraphUrl] = useState<string>("");
    const [graphLoaded, setGraphLoaded] = useState<boolean>(false);
    const [startDateError, setStartDateError] = useState<boolean>(false);
    const [endDateError, setEndDateError] = useState<boolean>(false);
    const [selectedFilterType, setSelectedFilterType] = useState<BandwidthDateSearchFilter>(BandwidthDateSearchFilter.Live);
    const [filterType, setFilterType] = useState<BandwidthDateSearchFilter>(BandwidthDateSearchFilter.Live);
    const [startDate, setStartDate] = useState<Date | null>(getDefaultSearchStartDateLastWeek());
    const [endDate, setEndDate] = useState<Date | null>(getDefaultEndDate); 
    const { user } = useUser();

    useEffect(() => {
        async function apiGetBandwidthGraph() {
            try {
                setIsLoading(true);
                const response = await getBandwidthGraph(axiosInstance, user.currentProperty?.code ?? "");                
                setBaseUrl(response);
                loadGraph(response);
            } 
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };                
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingBandwidthGraphs.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        apiGetBandwidthGraph();
        
    }, [user.currentProperty?.code, strings.errorRetrievingBandwidthGraphs]);

    function loadGraph(url: string) {
        const queryParams = "&width=1000&height=500&graphstyling=showLegend%3D%271%27+baseFontSize%3D%2712%27&hide=-4";

        if (selectedFilterType === BandwidthDateSearchFilter.Live) {
            setGraphUrl(url + "&graphid=0" + queryParams)
        }
        else if (selectedFilterType === BandwidthDateSearchFilter.Day) {
            setGraphUrl(url + "&graphid=1" + queryParams)
        }
        else if (selectedFilterType === BandwidthDateSearchFilter.Month) {
            setGraphUrl(url + "&graphid=2" + queryParams)
        }
        else if (selectedFilterType === BandwidthDateSearchFilter.Year) {
            setGraphUrl(url + "&graphid=3" + queryParams)
        }
        else {
            var startDateString = startDate!!.toISOString();
            startDateString = startDateString.substring(0, startDateString.indexOf("T")) + "-00-00-00";

            var endDateString = endDate!!.toISOString();
            endDateString = endDateString.substring(0, endDateString.indexOf("T")) + "-23-59-59";
            setGraphUrl(url + "&sdate=" + startDateString + "&edate=" + endDateString + "&graphid=-1" + queryParams)
        }
    }

    const onGraphLoad = () => {
        setFilterType(selectedFilterType);
        setGraphLoaded(true);
    }

    function handleDateFilterChange(e: SelectChangeEvent) {
        var filter: BandwidthDateSearchFilter = parseInt(e.target.value);
        setSelectedFilterType(filter);
    }

    const onViewGraphClickHandler = () => {
        var valid = true
        setStartDateError(false);
        setEndDateError(false)

        if (selectedFilterType === BandwidthDateSearchFilter.Custom) {
            if (!startDate) {
                setStartDateError(true);
                valid = false;
            }
            else {
                setStartDateError(false);
            }

            if (!endDate || !startDate || (endDate < startDate)) {
                setEndDateError(true);
                valid = false;
            }
            else {
                setEndDateError(false);
            }
        }

        if (valid) {
            setGraphLoaded(false);
            setGraphUrl("");
            loadGraph(baseUrl);
        }
    }

    function handleStartDateChange(newValue: Date | null) {
        if (newValue?.toString() !== "Invalid Date") {
            setStartDate(newValue);
        }
    }

    function handleEndDateChange(newValue: Date | null) {
        if (newValue?.toString() !== "Invalid Date") {
            setEndDate(newValue);
        }
    }

    const getBandwidthDateSearchFilterString = (filter: BandwidthDateSearchFilter): string => {
        if (filter === BandwidthDateSearchFilter.Live) {
            return strings.bandWidthLive;
        }
        else if (filter === BandwidthDateSearchFilter.Day) {
            return strings.bandWidthDay;
        }
        else if (filter === BandwidthDateSearchFilter.Month) {
            return strings.bandWidthMonth;
        }
        else if (filter === BandwidthDateSearchFilter.Year) {
            return strings.bandWidthYear;
        }
        else {
            return strings.bandWidthCustom;
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <>
                { (isLoading || !graphLoaded) &&
                    <LinearProgress color={"primary"} variant={"query"} />
                }            
                <Box sx={{ padding: theme.spacing(2) }}>                
                    {graphLoaded &&
                        <div className={classes.searchBox}>
                            <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                                <InputLabel>{strings.range}</InputLabel>
                                <Select
                                    id="bandwidthGraphDateFilter"
                                    value={selectedFilterType.toString()}
                                    label={strings.range}
                                    onChange={handleDateFilterChange}>
                                    <MenuItem key="0" value="0">{strings.bandWidthLive}</MenuItem>
                                    <MenuItem key="1" value="1">{strings.bandWidthDay}</MenuItem>
                                    <MenuItem key="2" value="2">{strings.bandWidthMonth}</MenuItem>
                                    <MenuItem key="3" value="3">{strings.bandWidthYear}</MenuItem>
                                    <MenuItem key="4" value="4">{strings.bandWidthCustom}</MenuItem>
                                </Select>
                            </FormControl>
                        
                            {selectedFilterType === BandwidthDateSearchFilter.Custom && 
                                <div className={classes.dateFields}>
                                    <DesktopDatePicker
                                        label={strings.startDate}
                                        inputFormat="MM/dd/yyyy"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        renderInput={(params) => <MaterialTextField {...params} error={startDateError} helperText={startDateError ? strings.validationStartDate : " "} />}
                                        disabled={false}
                                    />
                                    <span style={{marginRight: "10px"}} />
                                    <DesktopDatePicker
                                        label={strings.endDate}
                                        inputFormat="MM/dd/yyyy"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        renderInput={(params) => <MaterialTextField {...params} error={endDateError} helperText={endDateError ? strings.validationEndDate : " "} />}
                                        disabled={false}
                                    />
                                </div>
                            }
                            <Button
                                sx={{ marginLeft: theme.spacing(2), height: "56px" }}
                                variant="contained"                   
                                onClick={onViewGraphClickHandler}
                                aria-label="View Graph">
                                {strings.bandWidthViewGraphButton}
                            </Button>
                        </div>
                    }
                
                    <div>            
                        <>
                            {graphUrl !== "" &&
                                <div className={classes.graphContainer}>
                                    {graphLoaded && 
                                        <Typography variant="h5">
                                            {getBandwidthDateSearchFilterString(filterType)}
                                        </Typography>
                                    }
                                    <img className={graphLoaded ? classes.visible : classes.hidden} src={graphUrl} alt={"Bandwidth Graph - " + getBandwidthDateSearchFilterString(filterType)} onLoad={onGraphLoad} />
                                </div>
                            }
                    
                        </>            

                        <NotificationMessage notificationState={[notify, setNotify]} />
                    </div>
                </Box>
            </>
        </LocalizationProvider>
    );
}

export default Bandwidth;