import * as React from "react";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridColumns } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Button, MenuItem, FormControl, InputLabel, Box } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import themePrimary from "../../../styles/themePrimary";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField"
import SearchIcon from '@mui/icons-material/Search';
import { getItemViews, getMenuFilters, ReportType } from "../../../api/reporting/itv/reportingApi";
import { ItemView } from "../../../models/reporting/itv/ItemView";
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import { MenuFilter } from "../../../models/reporting/itv/MenuFilter";
import DataGridExportToolBar from "./DataGridExportToolBar";

const useStyles = makeStyles((theme: Theme) => ({
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    }
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setMonth(0, 1);

    return date;
}

const initialNotficationState: NotificationOptions = {
    isOpen: false,
    message: "",
    msgType: undefined,
};

function ItemViews() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [startDate, setStartDate] = React.useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = React.useState<Date>(getDefaultEndDate);
    const [viewData, setViewData] = React.useState<ItemView[]>([]);
    const [notify, setNotify] = React.useState<NotificationOptions>(initialNotficationState);
    const [selectedFilterId, setSelectedFilterId] = React.useState(""); // TODO Add enum for screen_class/'moduleId' => through to backend? 
    const [filterData, setFilterData] = React.useState<MenuFilter[]>([]);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();
    const { user } = useUser();
    const classes = useStyles(); 
    //const {moduleFilters, selectedModuleFilter, setSelectedModuleFilter, notify, setNotify, isLoading, setIsLoading} = useModuleFilters();

    React.useEffect(() => {
        async function apiGetFilterData() {
            try {
                const filters = await getMenuFilters(axiosInstance, user.currentProperty?.code ?? "", ReportType.Item);
                setFilterData(filters);

                if (filters.length > 0) {
                    setSelectedFilterId(filters[0].id)
                }
            }
            catch(e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingModuleFilters.replace("{{error}}", error.message),
                    msgType: "error",
                });
            }
            finally{
                setIsLoading(false);
            }
        }
        
        apiGetFilterData();

    }, [user.currentProperty?.code, strings.errorRetrievingBrands, ReportType.Item]) 
    
    React.useEffect(() => {
        async function apiGetItemViews() {
            try {
                const payload = await getItemViews(axiosInstance, user.currentProperty?.code ?? "", selectedFilterId, startDate, endDate);
                setViewData(payload);
            }
            catch (e: unknown) {
                const error = axios.isAxiosError(e)
                    ? { message: e.message }
                    : { message: "unable to parse error info" };            
                setNotify({
                    isOpen: true,
                    message: strings.errorRetrievingItemViews.replace("{{error}}", error.message), 
                    msgType: "error",
                });
            }
            finally {
                setIsLoading(false);
            }
        }

        if (isLoading && selectedFilterId.length > 0) {
            apiGetItemViews();
        }
        
    }, [user.currentProperty?.code, isLoading, selectedFilterId, startDate, endDate])

    function onSearchClick() {
        setIsLoading(true);
    }

    function onDateSearchFieldChangeHandler (startDate: Date, endDate: Date) {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    function handleFilterChange(e: SelectChangeEvent) {
       setSelectedFilterId(e.target.value as string);
    }

    const gridColumns: GridColumns = [
        { field: 'itemId',  flex: 1, hide: true },
        { field: 'itemName', headerName: strings.reportingItemName, flex: 1 },
        { field: 'viewCount', headerName: strings.reportingViewCount, flex: 1 }
    ];

    return (
        <Box>
            <Box className={classes.searchBox}>
                <DateSearchField 
                    startDate={getDefaultSearchStartDate()}
                    endDate={null}
                    format={null}
                    onChange={onDateSearchFieldChangeHandler} 
                />
                <FormControl variant="outlined" sx={{ marginLeft: themePrimary.spacing(2), minWidth: 220 }}>
                        <InputLabel>{strings.reportingModuleFilterSelectLabel}</InputLabel>
                        <Select
                            id="module-filter-select"
                            value={selectedFilterId}
                            label={strings.reportingModuleFilterSelectLabel}
                            onChange={handleFilterChange}>
                            {filterData.map(x => {
                                return <MenuItem key={x.id} value={x.id}>{x.name} </MenuItem>
                            })}
                        </Select>
                </FormControl>

                <Button
                    sx={{ my: 0.5,  marginLeft: themePrimary.spacing(2) }}
                    variant="contained"
                    size="large"
                    disabled={selectedFilterId.length === 0}
                    onClick={onSearchClick}
                    startIcon={<SearchIcon />}
                    aria-label="Search">
                    {strings.search}
                </Button>
            </Box>
            <DataGrid 
                permissionKey="reporting_itv"
                showAddButton={false}
                columns={gridColumns} 
                rows={viewData} 
                getRowId={(row) => `${row.itemId}`} 
                loading={isLoading} 
                components={{LoadingOverlay: LinearProgress, Toolbar: DataGridExportToolBar}} 
                componentsProps= {{toolbar: {fileName: strings.reportingItemViewsTabLabel.replace(" ", "")}}}
            />

            <NotificationMessage notificationState={[notify, setNotify]} />
        </Box>
    );
};

export default ItemViews;  