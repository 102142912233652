import {useEffect, useState } from "react";
import { listTickets, createTicket } from "../../../api/dashboard/support/ticketApi";
import { useLocalizedStrings } from "../../../localization/LocalizedStringsProvider";
import NotificationMessage, { NotificationOptions } from "../../common/NotificationMessage";
import DataGrid from "../../common/datatable/DataGridWrapper";
import { GridActionsCellItem, GridColumns, GridRowParams} from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import { useCreateAxios } from "../../../hooks/useCreateAxios";
import { Button, TextField as MuiTextField, MenuItem, FormControl, InputLabel, Grid, Box, Typography, Card, Menu } from "@mui/material";
import TextField from "../../common/details/TextField";
import { Theme, useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { getDate } from "date-fns";
import themePrimary from "../../../styles/themePrimary";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DateSearchField, { getDefaultEndDate } from "../../common/search/DateSearchField"
import { TicketSearchFilter } from "../../../models/dashboard/support/TicketSearchFilter";
import { ServiceTicket } from "../../../models/dashboard/support/ServiceTicket";
import { TicketSearchStatus } from "../../../models/dashboard/support/TicketSearchStatus";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FilterListIcon from '@mui/icons-material/FilterList';
import AppDialog from "../../common/AppDialog";
import TicketNoteList from "./TicketNoteList";
import SearchIcon from '@mui/icons-material/Search';
import DetailsDialog from "../../common/details/DetailsDialog";
import validator from "validator";
import Spacer from "../../common/Spacer";
import { ServiceTicketForCreation, initialServiceTicketForCreationState } from "../../../models/dashboard/support/ServiceTicketForCreation";
import { Field as ValidationField, Type as ValidationType, validate } from "../../../utilities/Validator";
import { useUser } from "../../../providers/UserProvider";
import axios from "axios";
import TicketNoteDialog from "./TicketNoteDialog";
import { default as DialogSelect } from "../../common/details/Select";
import { Access } from "../../../models/configuration/security/Permission";
import UserPropertyDropdown from "../../common/UserPropertyDropdown";
import { UserProperty } from "../../../models/configuration/security/User";

const useStyles = makeStyles(theme => ({
    detailContainer: {
        margin: '8px',
    },
    detailGrid: {
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main,
        color: themePrimary.palette.primary.contrastText,
    },
    detailLabel: {
        fontWeight: 'bold',
    },
    searchSelect: {
        marginLeft: themePrimary.spacing(2),
    },
    root: {
        //padding: themePrimary.spacing(2),
    },
    searchBox: {
        display: 'flex',
        paddingBottom: themePrimary.spacing(2),
        width: '100%',
    },
    summaryGrid: {
        paddingBottom: themePrimary.spacing(2),
        display: 'grid',
        gridTemplateColumns: '25% 25% 25% 25%',
    },
    summaryBox: {
        marginRight: themePrimary.spacing(2),
    },
    summaryCard: {
        padding: themePrimary.spacing(2),
        backgroundColor: themePrimary.palette.primary.main + " !important",
    },
    text: {
        color: themePrimary.palette.primary.contrastText,
        textAlign: 'center',
    },
}));

const getDefaultSearchStartDate = (): Date => {
    var date = new Date();
    date.setDate(getDate(date) - 7);

    return date;
}

interface ValidationErrors {
    ticketType: string;
    summary: string;
    description: string;
    callbackName: string;
    callbackPhone: string;
    callbackEmail: string;
}

const TicketList = () => {

    const initialErrorState: ValidationErrors = {
        ticketType: "",
        summary: "",
        description: "",
        callbackName: "",
        callbackPhone: "",
        callbackEmail: ""
    }

    const initialNotficationState: NotificationOptions = {
        isOpen: false,
        message: "",
        msgType: undefined,
    };

    const { user, checkAccess } = useUser();    
    const [gridRefresh, setGridRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [allProperties, setAllProperties] = useState(getAllProperties());
    const [properties, setProperties] = useState<string[]>(user.currentProperty ? [user.currentProperty!.code] : []);    
    const [selectedPropertyId, setSelectedPropertyId] = useState("");
    const [openTicketDialogOpen, setOpenTicketDialogOpen] = useState(false);
    const [notesDialogOpen, setNotesDialogOpen] = useState(false);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [tickets, setTickets] = useState<ServiceTicket[]>([]);    
    const [selectedTicket, setSelectedTicket] = useState<ServiceTicket | undefined>(undefined);
    const [selectedFilterStatus, setSelectedFilterStatus] = useState<TicketSearchStatus>(TicketSearchStatus.Open);
    const [selectedFilterType, setSelectedFilterType] = useState<TicketSearchFilter>(TicketSearchFilter.None);
    const [selectedFilterValue, setSelectedFilterValue] = useState<string>("");
    const [ticketForCreation, setTicketForCreation] = useState<ServiceTicketForCreation>(initialServiceTicketForCreationState);
    const [errors, setErrors] = useState <ValidationErrors>(initialErrorState);
    const [notify, setNotify] = useState<NotificationOptions>(initialNotficationState);
    const [startDate, setStartDate] = useState<Date>(getDefaultSearchStartDate());
    const [endDate, setEndDate] = useState<Date>(getDefaultEndDate);    
    const theme = useTheme();
    const classes = useStyles(theme);
    const strings = useLocalizedStrings();
    const axiosInstance = useCreateAxios();    
    const [newTicketMenuAnchor, setNewTicketMenuAnchor] = useState<null | HTMLElement>(null);
    const newTicketMenuOpen = Boolean(newTicketMenuAnchor);

    async function apiListTickets(currentProperty?: string) {
        try {
            let ids: string[];
            if (currentProperty) {
                ids = [currentProperty];
            }
            else {
                ids = properties;
            }

            if (ids.length === 0) {
                return;
            }

            let response = await listTickets(axiosInstance, ids, startDate, endDate, selectedFilterStatus, selectedFilterType, selectedFilterValue);                                        

            if (response) {
                setTickets(response);
            }
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };            
            setNotify({
                isOpen: true,
                message: strings.errorRetrievingTickets.replace("{{error}}", error.message),
                msgType: "error",
            });
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {        
        apiListTickets(user.currentProperty?.code);        
    }, [user.currentProperty?.code, strings.errorRetrievingTickets]);

    //useEffect(() => {        
    //    apiListTickets();        
    //}, [properties, strings.errorRetrievingTickets]);

    useEffect(() => {        
        apiListTickets();        
    }, [gridRefresh, strings.errorRetrievingTickets]);   

    function getAllProperties() {
        var array: UserProperty[] = [];
        user.brands.forEach(b => {
            array = array.concat(b.properties);
        });
        array.sort((a, b) => a.name && b.name ? a.name?.en.localeCompare(b.name?.en) : 0);
        return array;
    }   

    function handlePropertyChange(propertyCodes: string[]) {
        setProperties(propertyCodes);
    }

    const onDateSearchFieldChangeHandler = (startDate: Date, endDate: Date) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    const onSearchClickHandler = () => {
        setIsLoading(true);
        apiListTickets();         
    }

    function handleOpenTicketClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (properties.length > 1) {
            setNewTicketMenuAnchor(event.currentTarget);
        }
        else {
            setSelectedPropertyId(properties[0]);
            setOpenTicketDialogOpen(true);
        }
    }    
    
    function handleNewTicketMenuClose() {
        setNewTicketMenuAnchor(null);
    }

    function handleOpenTicketMenuClick(id: string) {
        setNewTicketMenuAnchor(null);
        setSelectedPropertyId(id);
        setOpenTicketDialogOpen(true);
    }

    function handleFilterStatusChange(e: SelectChangeEvent) {
        var status: TicketSearchStatus = parseInt(e.target.value);
        setSelectedFilterStatus(status);
    }

    function handleFilterTypeChange(e: SelectChangeEvent) {
        var filter: TicketSearchFilter = parseInt(e.target.value);
        setSelectedFilterValue("");
        setSelectedFilterType(filter);
    }

    function handleSearchKeywordChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSelectedFilterValue(event.target.value);        
    }

    function handleNotesDialogCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setNotesDialogOpen(false);
    }

    function handleOpenTicketDialogCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
        setOpenTicketDialogOpen(false);
        setTicketForCreation(initialServiceTicketForCreationState);
    }

    async function handleOpenTicketDialogSaveClick(event: React.MouseEvent<HTMLButtonElement>) {        
        try {            
            await createTicket(axiosInstance, selectedPropertyId, ticketForCreation);            
        }
        catch (e: unknown) {
            const error = axios.isAxiosError(e)
                ? { message: e.message }
                : { message: "unable to parse error info" };
            setNotify({
                isOpen: true,
                message: strings.errorSavingTicket.replace("{{error}}", error.message),
                msgType: "error",
            });

            return false;
        }    

        setErrors(initialErrorState);
        setOpenTicketDialogOpen(false);
        setTicketForCreation(initialServiceTicketForCreationState);
        setGridRefresh(!gridRefresh);

        setNotify({
            isOpen: true,
            message: strings.ticketSavedSuccessfully,
            msgType: "success",
        });

        return true;
    }

    function handleValidate() {
        var errors = validate<ServiceTicketForCreation, ValidationErrors>([
            { property: "ticketType", type: ValidationType.Required, message: strings.validationErrorTicketTypeRequired },
            { property: "summary", type: ValidationType.Required, message: strings.validationErrorTicketSummaryRequired },
            { property: "description", type: ValidationType.Required, message: strings.validationErrorTicketSummaryRequired },
            { property: "callbackName", type: ValidationType.Required, message: strings.validationErrorTicketNameRequired },                        
            { property: "callbackPhone", type: ValidationType.Phone, message: strings.validationErrorTicketPhoneNotValid },                       
            { property: "callbackEmail", type: ValidationType.Email, message: strings.validationOfferEmailInvalid }
        ], ticketForCreation);
        if (errors) {
            setErrors(errors);
            return false;
        }
        else {
            return true;
        }
    }

    const handleViewRowClick = async (id: string) => {        
        var ticket: ServiceTicket | undefined = tickets.find(t => t.id === id);

        if (ticket !== undefined) {
            setSelectedTicket(ticket);
            setNotesDialogOpen(true);
        }
    }

    function handleTypeChange(event: SelectChangeEvent) {       
        setTicketForCreation((prevState) => ({
            ...prevState,
            ticketType: parseInt(event.target.value as string)
        }));
    }

    function handleSummaryChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTicketForCreation((prevState) => ({
            ...prevState,
            summary: event.target.value,
        }));
    }

    function handleDescriptionChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTicketForCreation((prevState) => ({
            ...prevState,
            description: event.target.value,
        }));
    }

    function handleCallbackNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTicketForCreation((prevState) => ({
            ...prevState,
            callbackName: event.target.value,
        }));
    }

    function handleCallbackPhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTicketForCreation((prevState) => ({
            ...prevState,
            callbackPhone: event.target.value,
        }));
    }

    function handleCallbackEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
        setTicketForCreation((prevState) => ({
            ...prevState,
            callbackEmail: event.target.value,
        }));
    }

    function getDialogTitle() {
        if (selectedTicket === undefined) {
            return "Open New Ticket";
        }
        else {
            return (strings.ticketDialogTitle).replace("{{ticket}}", selectedTicket.id.toString() + " - " + selectedTicket.summary);
        }
    }

    const gridColumns : GridColumns = [
      { field: "id", hide: true, headerName: strings.ticketGridIdHeader, flex:2 },
      { field: "number", headerName: strings.ticketGridIdHeader, flex:1, valueGetter: (params) => params.row.number},
      { field: "companyName", headerName: strings.ticketGridPropertyNameHeader, flex:2},
      { field: "summary", headerName: strings.ticketGridSummaryHeader, flex:4},
      { field: "openDateTime", headerName: strings.ticketGridOpenDateTimeHeader, valueGetter: (params) => new Date(params.row.openDateTime).toLocaleDateString(), type: "date", flex:1},
      { field: "status", headerName: strings.ticketGridStatusHeader, flex:1},
      {
        field: "actions",
        type: "actions",
        headerName: strings.gridActions,
        flex: 1,
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<SearchIcon titleAccess={strings.gridActionViewTitleAccess} sx={{ color: "primary.dark" }} />}
            onClick={() => handleViewRowClick(params.id.toString())}
            label="View"
          />,
        ],
      },
    ];

    return (
        <Box sx={{ padding: theme.spacing(2) }}>
            <div className={classes.root}>       
                <div className={classes.searchBox}>
                    <UserPropertyDropdown onChange={handlePropertyChange} />
                    <DateSearchField 
                        startDate={getDefaultSearchStartDate()}
                        endDate={null}
                        format={null}
                        onChange={onDateSearchFieldChangeHandler} />
                    <Button
                        sx={{ marginLeft: theme.spacing(2), backgroundColor: theme.palette.primary.light, ':hover': { backgroundColor: theme.palette.primary.main } }}
                        variant="contained"                                    
                        onClick={() => {setFiltersOpen(!filtersOpen)}}
                        startIcon={filtersOpen ? <ArrowCircleUpIcon /> : <FilterListIcon />}
                        aria-label="Filter"
                    >
                        {strings.filter}
                    </Button>
                    <Button
                        sx={{ marginLeft: theme.spacing(2) }}
                        variant="contained"                        
                        disabled={selectedFilterType !== TicketSearchFilter.None ? (selectedFilterValue.trim() === "" ? true : false) : false}
                        onClick={onSearchClickHandler}
                        startIcon={<SearchIcon />}
                        aria-label="Search">
                        {strings.search}
                    </Button>

                    {user.currentProperty?.code && checkAccess("dashboard_support", Access.Create) &&
                        <Button
                            startIcon={<AddIcon />}
                            sx={{ marginLeft: 'auto' }}
                            variant="contained"                            
                            onClick={handleOpenTicketClick}
                            aria-label="Open ticket"                    
                        >
                            {strings.ticketOpenTicketLabel}                    
                        </Button>
                    }
                </div>

                {filtersOpen && 
                    <div className={classes.searchBox}>
                        <div>
                            <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                                <InputLabel>{strings.ticketStatusFilterSelectLabel}</InputLabel>
                                <Select
                                    id="selectFilterStatusTicketList"
                                    value={selectedFilterStatus.toString()}
                                    label={strings.ticketStatusFilterSelectLabel}
                                    onChange={handleFilterStatusChange}>
                                    <MenuItem key="0" value="0">{strings.ticketStatusFilterOpenLabel}</MenuItem>
                                    <MenuItem key="1" value="1">{strings.ticketStatusFilterClosedLabel}</MenuItem>
                                    <MenuItem key="2" value="2">{strings.ticketStatusFilterAllLabel}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className={classes.searchSelect}>
                            <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                                <InputLabel>{strings.ticketFilterTypeSelectLabel}</InputLabel>
                                <Select
                                    id="selectFilterTypeTicketList"
                                    value={selectedFilterType.toString()}
                                    label={strings.ticketFilterTypeSelectLabel}
                                    onChange={handleFilterTypeChange}>
                                    <MenuItem key="0" value="0">{strings.ticketFilterTypeNoneLabel}</MenuItem>
                                    <MenuItem key="1" value="1">{strings.ticketFilterTypeTicketNumberLabel}</MenuItem>
                                    <MenuItem key="2" value="2">{strings.ticketFilterTypeKeywordLabel}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {selectedFilterType !== TicketSearchFilter.None &&
                            <MuiTextField
                                onChange={handleSearchKeywordChange}
                                variant="outlined"
                                label={selectedFilterType === TicketSearchFilter.TicketNumber ? strings.ticketFilterTypeTicketNumberLabel : strings.ticketFilterTypeKeywordLabel}
                                value={selectedFilterValue}
                                onClick={(e) => e.stopPropagation()}
                                sx={{ width: 200, marginLeft: theme.spacing(2) }}
                            />
                        }

                    </div>
                }            
                
                <Menu
                    id="newTicketMenu"
                    MenuListProps={{
                        'aria-labelledby': 'newTicket',
                    }}
                    anchorEl={newTicketMenuAnchor}
                    open={newTicketMenuOpen}
                    onClose={handleNewTicketMenuClose}
                >                    
                    {properties.map((code, index) => (
                        <MenuItem key={code} value={code} onClick={() => handleOpenTicketMenuClick(code)}>{allProperties.find(p => p.code === code)?.name?.en ?? ""}</MenuItem>
                    ))}                      
                </Menu>

                <DataGrid
                    rows={tickets}
                    columns={gridColumns}
                    loading={isLoading}
                    components={{
                        LoadingOverlay : LinearProgress
                    }}
                />

                {selectedTicket !== undefined &&
                    <TicketNoteDialog 
                        notesDialogOpen={notesDialogOpen}
                        serviceTicket={selectedTicket} 
                        closeDialogCallback={function (): void {
                            setSelectedTicket(undefined);
                            setNotesDialogOpen(false);
                        }}                
                    />
                }

                <DetailsDialog
                    permissionKey="dashboard_support"
                    adding={true}
                    hideAudit={true}
                    open={openTicketDialogOpen}
                    title={strings.openTicketDialogTitle}
                    onValidateForm={handleValidate}
                    onCancelClick={handleOpenTicketDialogCancelClick}
                    onSaveClick={handleOpenTicketDialogSaveClick}
                    contentSize={{ width: 600, height: 590.44 }}
                >
                    <Box sx={{ width: "100%", padding: theme.spacing(2) }}>
                        <DialogSelect
                            label={strings.openTicketTypeLabel}                                
                            keyValues={[
                                {"key":"11","value":"Alerts"},
                                //{"key":"7","value":"Audio"},
                                //{"key":"12","value":"BackOfHouse"},
                                {"key":"10","value":"Casting"},
                                {"key":"9","value":"DigiManager"},
                                {"key":"4","value":"Digital Signage"},
                                {"key":"6","value":"ITV"},
                                {"key":"5","value":"Mobile App"},
                                {"key":"1","value":"Network"},
                                //{"key":"0","value":"Other"},
                                {"key":"3","value":"Security"},
                                {"key":"8","value":"Television"},
                                {"key":"2","value":"Voice"}
                            ]}
                            selectedValue={ticketForCreation.ticketType?.toString() ?? ""}
                            onChangeHandler={handleTypeChange}
                            sx={{ minWidth: 300 }}                                
                            error={Boolean(errors.ticketType)}
                            helperText={errors.ticketType}
                        />
                        <Spacer />
                        <TextField
                            id="ticket-summary"
                            label={strings.openTicketSummaryLabel}
                            width="100%"
                            value={ticketForCreation.summary}
                            onChange={handleSummaryChange}
                            error={Boolean(errors.summary)}
                            helperText={errors.summary} />
                        <Spacer />
                        <TextField
                            id="ticket-description"
                            label={strings.openTicketDescriptionLabel}
                            width="100%"
                            multiline
                            rows={4}
                            value={ticketForCreation.description}
                            onChange={handleDescriptionChange}
                            error={Boolean(errors.description)}
                            helperText={errors.description} />
                        <Spacer />
                        <TextField
                            id="ticket-callback-name"
                            label={strings.openTicketCallbackNameLabel}
                            width={400}
                            value={ticketForCreation.callbackName}
                            onChange={handleCallbackNameChange}
                            error={Boolean(errors.callbackName)}
                            helperText={errors.callbackName} />
                        <Spacer />
                        <TextField
                            id="ticket-callback-phone"
                            label={strings.openTicketCallbackPhoneLabel}
                            width={400}
                            value={ticketForCreation.callbackPhone}
                            onChange={handleCallbackPhoneChange}
                            error={Boolean(errors.callbackPhone)}
                            helperText={errors.callbackPhone} />
                        <Spacer />
                        <TextField
                            id="ticket-callback-email"
                            label={strings.openTicketCallbackEmailLabel}
                            width={400}
                            value={ticketForCreation.callbackEmail}
                            onChange={handleCallbackEmailChange}
                            error={Boolean(errors.callbackEmail)}
                            helperText={errors.callbackEmail} />
                    </Box>
                </DetailsDialog>
                      
                <NotificationMessage notificationState={[notify, setNotify]} />
            </div>
        </Box>
    );
};

export default TicketList;
